import { Outlet } from "react-router-dom";
import { t } from "i18next";
import { Spinner } from "@livingmap/core-ui-v2";

import Header from "@components/Header/Header";
import { useSetInitialProjectData } from "@hooks";
import {
  useGetMapsQuery,
  useGetMMSStatusQuery,
  usePublishEditsForMapByIdMutation,
} from "@redux/services/mms";
import { Project } from "@redux/services/types";
import { useAppDispatch, useAppSelector } from "@redux/hooks";
import {
  setMapID,
  setDefaultLanguage,
  setFeatureID,
} from "@redux/slices/applicationSlice";
import { getProjectNames } from "@utils";
import styles from "./FeaturesView.module.css";
import PageTitle from "@components/PageTitle/PageTitle";

const FeaturesView = () => {
  const dispatch = useAppDispatch();
  const mapID = useAppSelector((state) => state.application.mapID);

  const { data, isLoading } = useGetMapsQuery();
  const { data: mmsStatusData } = useGetMMSStatusQuery(mapID, { skip: !mapID });
  const [publishEditsForMap] = usePublishEditsForMapByIdMutation();

  const handleProjectSelect = (project: Project) => {
    dispatch(setMapID(project.id));
    dispatch(setDefaultLanguage(project.defaultLanguage));
    dispatch(setFeatureID(null));
  };

  const projectNames = getProjectNames(data);

  useSetInitialProjectData(projectNames);

  const activeProject = projectNames?.find((project) => project.id === mapID);

  return isLoading || !mapID || !mmsStatusData ? (
    <div className={styles.loaderContainer} data-qa="features-view-loading">
      <Spinner dataQA="loading-spinner" type="BeatLoader" />
    </div>
  ) : (
    <div className={styles.container} data-qa="features-view">
      <Header
        dataQA="features-view-header"
        changes={{
          features: mmsStatusData.unpublished_features,
          deletedFeatures: mmsStatusData.deleted_features,
        }}
        onPublish={() => publishEditsForMap(mapID)}
        projects={projectNames}
        activeProject={activeProject}
        onProjectSelect={handleProjectSelect}
        menuItems={[
          {
            dataQA: "list-view-link",
            type: "secondary-transparent",
            path: "/features/list-view",
            as: "NavLink",
            text: t("header.menu_items.list_view"),
          },
          {
            dataQA: "map-view-link",
            type: "secondary-transparent",
            path: "/features/map-view",
            as: "NavLink",
            text: t("header.menu_items.map_view"),
          },
        ]}
      />
      <PageTitle
        dataQA="features-view-page-title"
        heading={t("views.features.title")}
        subheading={t("views.features.description")}
      />
      <Outlet />
    </div>
  );
};

export default FeaturesView;
