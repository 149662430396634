import React from "react";

import styles from "./Sidebar.module.css";

interface BaseProps {
  dataQA: string;
  isOpen: boolean;
  displayShadow?: boolean;
  children?: React.ReactNode;
}

interface AbsoluteInterface extends BaseProps {
  position: "absolute";
  /**
   * @param string
   * @desc only applicable if position "absolute" is applied
   */
  layout: "left" | "right";
}

interface RelativeInterface extends BaseProps {
  position: "relative";
}

type Props = AbsoluteInterface | RelativeInterface;

const Sidebar: React.FC<Props> = ({
  dataQA,
  isOpen,
  displayShadow,
  children,
  ...props
}) => {
  return (
    <>
      {isOpen && (
        <div
          data-qa={dataQA}
          className={`${styles.container} ${styles[props.position]} ${
            props.position === "absolute" ? styles[props.layout] : ""
          } ${displayShadow ? styles.shadow : ""}`}
        >
          {children}
        </div>
      )}
    </>
  );
};

export default Sidebar;
