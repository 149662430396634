import { useEffect, useRef, useState } from "react";
import { t } from "i18next";
import TimeAgo from "timeago-react";
import { Button, Dialog, Spinner, Tooltip } from "@livingmap/core-ui-v2";

import AlertComponent, { AlertTypes } from "@components/Alert/Alert";
import { millisToMinutesAndSeconds } from "@utils";
import { JobStatus } from "@redux/services/types";
import RunWorkflow from "./Modals/RunWorkflow";
import styles from "./Workflow.module.css";

interface Props {
  dataQA: string;
  inProgress: boolean;
  title: string;
  description: string;
  lastRan: string | null;
  executionTime: number | null;
  onClick: () => void;
  confirmation: {
    title: string;
    summary: string;
    accept_label: string;
  };
  jobStatus?: JobStatus;
}

const Workflow: React.FC<Props> = ({
  dataQA,
  inProgress,
  title,
  description,
  lastRan,
  executionTime,
  onClick,
  jobStatus,
  confirmation,
}) => {
  const intervalID = useRef<ReturnType<typeof setInterval> | null>(null);

  const [localExecutionTime, setLocalExecutionTime] = useState(0);
  const [workflowInitiated, setWorkflowInitiated] = useState(false);
  // Local copy of job status to override brief completed status when first running a workflow
  const [localJobStatus, setLocalJobStatus] = useState<JobStatus | undefined>(
    jobStatus,
  );
  const [isModalOpen, setIsModalOpen] = useState(false);

  useEffect(() => {
    setLocalExecutionTime(executionTime || 0);

    if (intervalID.current) {
      clearInterval(intervalID.current);
      setLocalExecutionTime(executionTime || 0);
    }

    if (jobStatus && jobStatus !== "COMPLETED" && jobStatus !== "FAILED") {
      intervalID.current = setInterval(() => {
        setLocalExecutionTime((oldTime) => oldTime + 1000);
      }, 1000);
    }
  }, [jobStatus, executionTime]);

  const handleRunWorkflow = () => {
    onClick();
    setWorkflowInitiated(true);
    setLocalJobStatus("PENDING");
  };

  useEffect(() => {
    if (jobStatus) setLocalJobStatus(jobStatus);
  }, [jobStatus]);

  const getAlertType = (jobStatus: JobStatus) => {
    switch (jobStatus) {
      case "COMPLETED":
        return AlertTypes.SUCCESS;
      case "FAILED":
        return AlertTypes.ERROR;
      default:
        return AlertTypes.INFO;
    }
  };

  const handleAlertClose = () => {
    setWorkflowInitiated(false);
  };

  return (
    <div data-qa={dataQA} className={styles.container}>
      <div className={styles.topContainer}>
        <div className={styles.textContainer}>
          <h2>{title}</h2>
          <p>{description}</p>
        </div>
        <div className={styles.controlsContainer}>
          {inProgress ? (
            <>
              <div className={styles.timeContainer}>
                <div className={styles.iconWrapper}>
                  <Tooltip
                    dataQA="running-tooltip"
                    icon={{ type: "RefreshIcon", className: styles.icon }}
                  >
                    <p>{t("views.workflows.status.running")}</p>
                  </Tooltip>
                  <span>
                    {localExecutionTime && localExecutionTime > 0
                      ? millisToMinutesAndSeconds(localExecutionTime)
                      : t("views.workflows.status.never_ran")}
                  </span>
                </div>
              </div>
              <Spinner dataQA="workflow-running-spinner" type="BarLoader" />
            </>
          ) : (
            <>
              <div className={styles.timeContainer}>
                <div className={styles.iconWrapper}>
                  <Tooltip
                    dataQA="last-ran-tooltip"
                    icon={{
                      type: "CalendarTodayIcon",
                      className: styles.icon,
                    }}
                  >
                    <p>{t("views.workflows.status.last_ran")}</p>
                  </Tooltip>
                  {lastRan ? (
                    <Tooltip
                      dataQA="never-ran-tooltip"
                      triggerComponent={<TimeAgo datetime={lastRan} />}
                    >
                      <p>{lastRan}</p>
                    </Tooltip>
                  ) : (
                    <span>{t("views.workflows.status.never_ran")}</span>
                  )}
                </div>
                <div className={styles.iconWrapper}>
                  <Tooltip
                    dataQA="ran-duration-tooltip"
                    icon={{
                      type: "TimerIcon",
                      className: styles.icon,
                    }}
                  >
                    <p>{t("views.workflows.status.run_duration")}</p>
                  </Tooltip>
                  <span>
                    {localExecutionTime && localExecutionTime > 0
                      ? millisToMinutesAndSeconds(localExecutionTime)
                      : t("views.workflows.status.never_ran")}
                  </span>
                </div>
              </div>
              <Button
                dataQA="run-now-button"
                color="blue"
                icon="RefreshIcon"
                onClick={() => setIsModalOpen(true)}
                outlined
              >
                {t("views.workflows.start_workflow")}
              </Button>
            </>
          )}
        </div>
      </div>
      {(localJobStatus === "COMPLETED" || localJobStatus === "FAILED") &&
        workflowInitiated && (
          <div className={styles.alertWrapper}>
            <AlertComponent
              dataQA="alert-component"
              type={getAlertType(localJobStatus)}
              description={
                localJobStatus === "COMPLETED"
                  ? t("views.workflows.alerts.success")
                  : t("views.workflows.alerts.failure")
              }
              closable={true}
              onClose={handleAlertClose}
            />
          </div>
        )}
      <Dialog
        isOpen={isModalOpen}
        dataQA="run-workflow-dialog"
        onClose={() => setIsModalOpen(false)}
        maxWidth={360}
      >
        <RunWorkflow
          acceptLabel={confirmation.accept_label}
          summary={confirmation.summary}
          closeModal={() => setIsModalOpen(false)}
          onRunWorkflow={handleRunWorkflow}
        />
      </Dialog>
    </div>
  );
};

export default Workflow;
