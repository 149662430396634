import React from "react";
import { Icon } from "@livingmap/core-ui-v2";

import { CheckAndRadioInterface } from "../FormControl";
import styles from "./Radio.module.css";

type Props = Omit<CheckAndRadioInterface, "type">;

const Radio: React.FC<Props> = ({
  displayCheckboxBubble,
  dataQA,
  ...props
}) => {
  return (
    <div data-qa={dataQA} className={styles.container}>
      <input
        type="radio"
        className={styles.input}
        {...props}
        value={props.value || ""}
      />
      <label htmlFor={props.id} className={styles.label}>
        <span className={styles.iconWrapper}>
          <Icon
            dataQA="radio-button-unchecked-icon"
            type="RadioButtonUncheckedIcon"
            width={20}
            height={20}
            className={styles.unchecked}
          />
          <Icon
            dataQA="radio-button-checked-icon"
            type="RadioButtonCheckedIcon"
            width={20}
            height={20}
            className={styles.checked}
          />
        </span>
        {props.label && <span className={styles.labelText}>{props.label}</span>}
      </label>
    </div>
  );
};

export default Radio;
