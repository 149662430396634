import { useEffect } from "react";

import { Project } from "@redux/services/types";
import { useAppDispatch } from "@redux/hooks";
import { setDefaultLanguage, setMapID } from "@redux/slices/applicationSlice";
import { useTranslation } from "react-i18next";

export const useSetInitialProjectData = (
  projectNames: Project[] | undefined,
) => {
  const { i18n } = useTranslation();

  const dispatch = useAppDispatch();

  const currentMapId = localStorage.getItem("currentMapId");
  const currentMapLanguageObject = projectNames?.find(
    (project) => project.id === currentMapId,
  );

  const activeProject = currentMapLanguageObject
    ? currentMapId
    : projectNames?.[0].id;
  const activeDefaultLanguage = currentMapLanguageObject
    ? currentMapLanguageObject.defaultLanguage
    : projectNames?.[0].defaultLanguage;

  // Set the initial active project on render
  useEffect(() => {
    if (activeProject && activeDefaultLanguage) {
      dispatch(setMapID(activeProject));
      dispatch(setDefaultLanguage(activeDefaultLanguage));
      i18n.changeLanguage(activeDefaultLanguage);
    }
  }, [dispatch, i18n, activeProject, activeDefaultLanguage]);
};
