import { Button, Icon } from "@livingmap/core-ui-v2";

import styles from "./Modal.module.css";

interface Props {
  onCancel: () => void;
  onConfirm: () => void;
}

const UnsavedChanges: React.FC<Props> = ({ onCancel, onConfirm }) => {
  return (
    <div className={styles.container}>
      <div className={styles.heading}>
        <h5 className={styles.title}>Exit without saving?</h5>
        <button className={styles.closeButton} onClick={onCancel}>
          <Icon dataQA="close-icon" type="CloseIcon" width={18} height={18} />
        </button>
      </div>
      <div className={styles.contentContainer}>
        <p className={styles.description}>
          Changes you made will not be saved.
        </p>
        <div className={styles.buttonWrapper}>
          <Button dataQA="keep-editing-button" color="black" onClick={onCancel}>
            Keep editing
          </Button>
          <Button dataQA="exit-button" color="blue" onClick={onConfirm}>
            Exit
          </Button>
        </div>
      </div>
    </div>
  );
};

export default UnsavedChanges;
