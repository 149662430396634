import React, { useState, useRef } from "react";
import Tippy from "@tippyjs/react";

import "tippy.js/dist/tippy.css";
import styles from "./Popover.module.css";

type AutoPlacement = "auto" | "auto-start" | "auto-end";
type BasePlacement = "top" | "bottom" | "right" | "left";
type VariationPlacement =
  | "top-start"
  | "top-end"
  | "bottom-start"
  | "bottom-end"
  | "right-start"
  | "right-end"
  | "left-start"
  | "left-end";

type Placement = AutoPlacement | BasePlacement | VariationPlacement;

interface Props {
  /**
   * @desc can either provide a component OR a render prop which contains an "isOpen" parameter, which confirms if the popover is open (in case the component being rendered has styling specific to when it's open)
   */
  toggleComponent: React.ReactNode | ((isOpen: boolean) => React.ReactNode);
  placement?: Placement;
  children?: React.ReactNode | ((hidePopover: () => void) => React.ReactNode); // Allow children to optionally have a "hidePopover" function passed down to them as a render prop
}

const PopoverComponent: React.FC<Props> = ({
  toggleComponent,
  placement = "auto",
  children,
}) => {
  const tippyRef = useRef<Element | null>(null);
  const [visible, setVisible] = useState(false);
  const show = () => setVisible(true);
  const hide = () => setVisible(false);

  return (
    <Tippy
      ref={tippyRef}
      content={typeof children === "function" ? children(hide) : children}
      visible={visible}
      onClickOutside={hide}
      allowHTML={true}
      arrow={false}
      appendTo={document.body}
      interactive={true}
      placement={placement}
      className={styles.panel}
    >
      <div onClick={visible ? hide : show}>
        {typeof toggleComponent === "function"
          ? toggleComponent(visible)
          : toggleComponent}
      </div>
    </Tippy>
  );
};

export default PopoverComponent;
