import { Icon } from "@livingmap/core-ui-v2";
import styles from "./SearchInput.module.css";

interface Props {
  dataQA: string;
  onClearClick: () => void;
  onKeyDown: (e: React.KeyboardEvent<HTMLInputElement>) => void;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  searchIsActive: boolean;
  placeholder: string;
  searchValue: string;
}

const SearchInput: React.FC<Props> = ({
  dataQA,
  searchIsActive,
  onClearClick,
  searchValue,
  placeholder,
  onKeyDown,
  onChange,
}) => {
  return (
    <div
      data-qa={dataQA}
      className={`${styles.container} ${searchIsActive ? styles.active : ""}`}
    >
      <div className={styles.backdrop} />
      <label className={styles.label}>
        <Icon
          dataQA="search-icon"
          type="SearchIcon"
          className={styles.searchIcon}
        />
        <input
          type="text"
          className={styles.input}
          value={searchValue}
          placeholder={placeholder}
          onKeyDown={onKeyDown}
          onChange={onChange}
        />
        <span className={styles.clearIcon} onClick={onClearClick}>
          <Icon dataQA="clear-icon" type="ClearOutlinedIcon" />
        </span>
      </label>
    </div>
  );
};

export default SearchInput;
