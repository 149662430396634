import { t } from "i18next";
import { Button, Icon } from "@livingmap/core-ui-v2";

import styles from "./Modal.module.css";

interface Props {
  closeModal: () => void;
  onCloseSidebar: () => void;
}

const CloseSidebar: React.FC<Props> = ({ closeModal, onCloseSidebar }) => {
  return (
    <div className={styles.container}>
      <div className={styles.heading}>
        <h5 className={styles.title}>{t("modals.exit_without_save.title")}</h5>
        <button className={styles.closeButton} onClick={closeModal}>
          <Icon dataQA="close-icon" type="CloseIcon" width={18} height={18} />
        </button>
      </div>
      <div className={styles.contentContainer}>
        <p className={styles.description}>
          {t("modals.exit_without_save.description")}
        </p>
        <div className={styles.buttonWrapper}>
          <Button
            dataQA="keep-editing-button"
            color="black"
            onClick={closeModal}
          >
            {t("btn_labels.keep_editing")}
          </Button>
          <Button dataQA="exit-button" color="blue" onClick={onCloseSidebar}>
            {t("btn_labels.exit")}
          </Button>
        </div>
      </div>
    </div>
  );
};

export default CloseSidebar;
