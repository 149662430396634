import React from "react";
import { t } from "i18next";
import { Listbox } from "@headlessui/react";

import { Icon } from "@livingmap/core-ui-v2";

import { Icon as IconType } from "@redux/services/types";
import styles from "./IconDropdown.module.css";
import classNames from "classnames";

interface Props {
  dataQA: string;
  icons: IconType[];
  selectedIcon: IconType | null;
  onChange: (icon: IconType) => void;
  onClearClick: () => void;
  label?: string;
  disabled?: boolean;
}

const IconDropdown: React.FC<Props> = ({
  dataQA,
  icons,
  selectedIcon,
  onChange,
  onClearClick,
  label,
  disabled = false,
}) => {
  return (
    <div
      data-qa={dataQA}
      className={classNames(styles.container, {
        [styles.disabled]: disabled,
      })}
    >
      <Listbox value={selectedIcon} onChange={onChange}>
        {label && (
          <Listbox.Label className={styles.label}>{label}</Listbox.Label>
        )}
        <div className={styles.buttonWrapper}>
          <Listbox.Button className={styles.button}>
            {selectedIcon ? (
              <>
                <img
                  src={`data:image/svg+xml;base64,${selectedIcon.svg_base64}`}
                  alt={selectedIcon.id}
                  className={styles.selectedIcon}
                />
                <span className={styles.selectedSpan}>{selectedIcon.id}</span>
              </>
            ) : (
              <>
                <Icon
                  dataQA="add-icon"
                  type="AddCircleOutlineIcon"
                  width={18}
                  height={18}
                  className={styles.addIcon}
                />
                <span className={styles.addSpan}>
                  {t(
                    "edit_views.features.sections.label.fields.icon.placeholder",
                  )}
                </span>
              </>
            )}
          </Listbox.Button>
          {selectedIcon && (
            <button className={styles.clearButton} onClick={onClearClick}>
              <Icon
                dataQA="clear-icon"
                type="ClearOutlinedIcon"
                width={18}
                height={18}
                className={styles.clearIcon}
              />
            </button>
          )}
        </div>
        <div className={styles.panel}>
          <div className={styles.dropdownWrapper}>
            <Listbox.Options className={styles.dropdown}>
              {icons.map((icon) => (
                <Listbox.Option
                  key={`${icon.id}:${icon.svg_base64}`}
                  value={icon}
                  as={React.Fragment}
                >
                  <li className={styles.dropdownItem}>
                    <img
                      src={`data:image/svg+xml;base64,${icon.svg_base64}`}
                      alt={`${icon.id} icon`}
                      className={styles.iconOption}
                    />
                  </li>
                </Listbox.Option>
              ))}
            </Listbox.Options>
          </div>
          <div className={styles.spacer} />
        </div>
      </Listbox>
    </div>
  );
};

export default IconDropdown;
