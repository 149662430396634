import { t } from "i18next";
import { Tooltip, Icon } from "@livingmap/core-ui-v2";

import FormControl from "@components/FormControl/FormControl";
import IconDropdown from "@components/IconDropdown/IconDropdown";
import NumericalSlider from "@components/NumericalSlider/NumericalSlider";
import RichCTA from "@components/RichCTA/RichCTA";
import {
  Days,
  FeatureData,
  CategoryObject,
  SubcategoryObject,
  Icon as IconType,
  STATUS,
} from "@redux/services/types";
import { useAppSelector } from "@redux/hooks";
import styles from "./Info.module.css";
import { useGetMapByIdQuery } from "@redux/services/mms";
import { ChangeEvent } from "react";

interface Props
  extends Omit<
    FeatureData,
    | "id"
    | "uid"
    | "object"
    | "type"
    | "is_temporarily_closed"
    | "last_modified"
    | "media"
    | "tags"
    | "rank"
  > {
  dataQA: string;
  featureID: string;
  allCategories: CategoryObject[];
  isTemporarilyClosed: boolean;
  icons: IconType[];
  rank: {
    minimum: number;
    maximum: number;
    value: number;
    onChange: (value: number) => void;
    onResetClick: () => void;
  };
  onIconChange: (icon: IconType) => void;
  onIconClearClick: () => void;
  onChange: (
    e: React.ChangeEvent<any>,
    path: string,
    defaultObject?: {
      [key: string]: any;
    },
  ) => void;
  onOperatingHoursClick: () => void;
  onTemporarilyCloseClick: () => void;
  onReopenClick: () => void;
  onDeleteClick: () => void;
  onUnarchiveClick: () => void;
  onCategorySelect: (value: CategoryObject) => void;
  onSubcategorySelect: (value: SubcategoryObject) => void;
}

const Info: React.FC<Props> = (props) => {
  const mapID = useAppSelector((state) => state.application.mapID);
  const mapData = useGetMapByIdQuery(mapID, {
    skip: !mapID,
  });

  const languages = mapData.data?.languages.available;

  const defaultLanguage = useAppSelector(
    (state) => state.application.defaultLanguage,
  );

  const selectedIcon =
    props.icons.find((icon) => icon.id === props.label.icon) || null;

  const selectedCategory =
    props.allCategories.find(
      (category) => category.id === props.categories.category.id,
    ) || null;

  const selectedSubcategory =
    selectedCategory &&
    selectedCategory.subcategories.find(
      (subcategory) => subcategory.id === props.categories.subcategory.id,
    );

  const isEditable =
    props.status === STATUS.PUBLISHED || props.status === STATUS.DRAFT;
  const isArchivable = props.status === STATUS.PUBLISHED;

  const labelTooltip = t("edit_views.features.sections.label.tooltip", {
    returnObjects: true,
  });

  const rankTooltip = t("edit_views.features.sections.rank.tooltip", {
    returnObjects: true,
  });

  const aboutTooltip = t("edit_views.features.sections.about.tooltip", {
    returnObjects: true,
  });

  const updateFeatureField = (
    e: ChangeEvent,
    path: string,
    language: string,
  ) => {
    const index = props.label.name?.findIndex((lang) => lang.lang === language);
    props.onChange(e, `${path}[${index}].text`, {
      lang: language,
    });
  };

  return (
    <div data-qa={props.dataQA}>
      <div className={styles.section}>
        <div className={styles.sectionHeading}>
          <h5 className={styles.sectionTitle}>
            {t("edit_views.features.sections.label.title")}
          </h5>
          <Tooltip
            dataQA="label-tooltip"
            icon={{
              type: "InfoOutlinedIcon",
              className: styles.tooltipIcon,
              width: 17,
              height: 17,
            }}
          >
            {Array.isArray(labelTooltip)
              ? labelTooltip.map((item, key) => <p key={key}>{item}</p>)
              : String(labelTooltip)}
          </Tooltip>
        </div>
        {languages?.map((language) => (
          <div className={`${styles.formFieldSection} ${styles.split}`}>
            <FormControl
              dataQA="feature-name-input"
              type="text"
              id="name"
              name="name"
              label={`${t("edit_views.features.sections.label.fields.name")} (${language})`}
              value={
                props.label.name?.find((name) => name.lang === language)?.text
              }
              onChange={(e) => updateFeatureField(e, "label.name", language)}
              disabled={!isEditable}
            />
            <FormControl
              dataQA="feature-reference-input"
              type="text"
              id="reference"
              name="reference"
              label={t("edit_views.features.sections.label.fields.reference")}
              value={
                props.label.reference?.find(
                  (reference) => reference.lang === language,
                )?.text
              }
              onChange={(e) =>
                updateFeatureField(e, "label.reference", language)
              }
              disabled={!isEditable}
            />
          </div>
        ))}
        <div className={styles.formFieldSection}>
          <FormControl
            dataQA="feature-label-visibility-checkbox"
            type="checkbox"
            id="is_visible"
            name="is_visible"
            value="is_visible"
            label={t("edit_views.features.sections.label.fields.label_visible")}
            checked={props.label.is_visible}
            onChange={(e) => props.onChange(e, "label.is_visible")}
            disabled={!isEditable}
          />
        </div>
        <div className={styles.formFieldSection}>
          <IconDropdown
            dataQA="icon-dropdown"
            label={t("edit_views.features.sections.label.fields.icon.title")}
            icons={props.icons}
            onChange={props.onIconChange}
            selectedIcon={selectedIcon}
            onClearClick={props.onIconClearClick}
            disabled={!isEditable}
          />
        </div>
      </div>
      <div className={styles.section}>
        <div className={styles.sectionHeading}>
          <h5 className={styles.sectionTitle}>
            {t("edit_views.features.sections.category.title")}
          </h5>
          <Tooltip
            dataQA="category-tooltip"
            icon={{
              type: "InfoOutlinedIcon",
              className: styles.tooltipIcon,
              width: 17,
              height: 17,
            }}
          >
            <p>{t("edit_views.features.sections.category.tooltip")}</p>
          </Tooltip>
        </div>
        <div className={styles.formFieldSection}>
          <FormControl
            dataQA="feature-category-dropdown"
            type="dropdown"
            label={t("edit_views.features.sections.category.fields.category")}
            options={props.allCategories}
            value={selectedCategory}
            onChange={props.onCategorySelect}
            displayPath="name[0].text"
            disabled={!isEditable}
          />
        </div>
        {selectedCategory && (
          <div className={styles.formFieldSection}>
            <FormControl
              dataQA="feature-subcategory-dropdown"
              type="dropdown"
              label={t(
                "edit_views.features.sections.category.fields.subcategory",
              )}
              options={selectedCategory.subcategories}
              value={selectedSubcategory}
              onChange={props.onSubcategorySelect}
              displayPath="name[0].text"
              disabled={!isEditable}
            />
          </div>
        )}
      </div>
      <div className={styles.section}>
        <div className={styles.sectionHeading}>
          <h5 className={styles.sectionTitle}>
            {t("edit_views.features.sections.rank.title")}
          </h5>
          <Tooltip
            dataQA="rank-tooltip"
            icon={{
              type: "InfoOutlinedIcon",
              className: styles.tooltipIcon,
              width: 17,
              height: 17,
            }}
          >
            {Array.isArray(rankTooltip)
              ? rankTooltip.map((item, key) => <p key={key}>{item}</p>)
              : String(rankTooltip)}
          </Tooltip>
        </div>
        <div className={styles.formFieldSection}>
          <NumericalSlider
            dataQA="feature-rank-slider"
            {...props.rank}
            disabled={!isEditable}
          />
        </div>
      </div>
      <div className={styles.section}>
        <div className={styles.sectionHeading}>
          <h5 className={styles.sectionTitle}>
            {t("edit_views.features.sections.street_address.title")}
          </h5>
        </div>
        <div className={styles.fieldsWithIcon}>
          <div className={styles.iconWrapper}>
            <Icon dataQA="location-icon" type="LocationIcon" />
          </div>
          <div className={styles.fieldWrapper}>
            <div className={styles.formFieldSection}>
              <FormControl
                dataQA="feature-address-textarea"
                type="textarea"
                id="address"
                name="address"
                value={props.street_address}
                label={t(
                  "edit_views.features.sections.street_address.fields.address",
                )}
                onChange={(e) =>
                  props.onChange(e, "street_address", {
                    lang: defaultLanguage,
                    text: "",
                  })
                }
                rows={5}
                disabled={!isEditable}
              />
            </div>
          </div>
        </div>
      </div>
      <div className={styles.section}>
        <div className={styles.sectionHeading}>
          <h5 className={styles.sectionTitle}>
            {t("edit_views.features.sections.indoor_location.title")}
          </h5>
        </div>
        <div className={styles.fieldsWithIcon}>
          <div className={styles.iconWrapper}>
            <Icon dataQA="building-icon" type="BuildingIcon" />
          </div>
          <div className={styles.fieldWrapper}>
            <div className={styles.formFieldSection}>
              <FormControl
                dataQA="feature-id-input"
                type="text"
                id="feature-id"
                name="feature-id"
                value={props.featureID}
                label={t(
                  "edit_views.features.sections.indoor_location.fields.feature_id",
                )}
                disabled={true}
              />
            </div>
          </div>
        </div>
      </div>
      <div className={styles.section}>
        <div className={styles.sectionHeading}>
          <h5 className={styles.sectionTitle}>
            {t("edit_views.features.sections.about.title")}
          </h5>
          <Tooltip
            dataQA="about-toolip"
            icon={{
              type: "InfoOutlinedIcon",
              className: styles.tooltipIcon,
              width: 17,
              height: 17,
            }}
          >
            {Array.isArray(aboutTooltip)
              ? aboutTooltip.map((item, key) => <p key={key}>{item}</p>)
              : String(aboutTooltip)}
          </Tooltip>
        </div>
        {languages?.map((language) => (
          <div className={styles.formFieldSection}>
            <FormControl
              dataQA="feature-long-name-input"
              type="text"
              id="longName"
              name="longName"
              value={
                props.information.long_name?.find(
                  (lang) => lang.lang === language,
                )?.text
              }
              label={`${t("edit_views.features.sections.about.fields.long_name")} (${language})`}
              onChange={(e) =>
                updateFeatureField(e, "information.long_name", language)
              }
              disabled={!isEditable}
            />
          </div>
        ))}
        {languages?.map((language) => (
          <div className={styles.formFieldSection}>
            <FormControl
              dataQA="feature-summary-input"
              type="text"
              id="summary"
              name="summary"
              value={
                props.information.summary?.find(
                  (lang) => lang.lang === language,
                )?.text
              }
              label={`${t("edit_views.features.sections.about.fields.summary")} (${language})`}
              onChange={(e) =>
                updateFeatureField(e, "information.summary", language)
              }
              disabled={!isEditable}
            />
          </div>
        ))}
        {languages?.map((language) => (
          <div className={styles.formFieldSection}>
            <FormControl
              dataQA="feature-description-input"
              type="textarea"
              id="description"
              name="description"
              value={
                props.information.description?.find(
                  (lang) => lang.lang === language,
                )?.text
              }
              label={`${t("edit_views.features.sections.about.fields.description")} (${language})`}
              onChange={(e) =>
                updateFeatureField(e, "information.description", language)
              }
              rows={5}
              disabled={!isEditable}
            />
          </div>
        ))}
        <div className={styles.formFieldSection}>
          <FormControl
            dataQA="feature-website-input"
            type="text"
            id="website"
            name="website"
            value={props.information.links?.[0].url}
            label={t("edit_views.features.sections.about.fields.website")}
            onChange={(e) =>
              props.onChange(e, "information.links[0].url", {
                type: "general",
                url: "",
              })
            }
            icon={{
              type: "WebsiteIcon",
            }}
            disabled={!isEditable}
          />
        </div>
        <div className={styles.formFieldSection}>
          <FormControl
            dataQA="feature-phone-number-input"
            type="text"
            id="phoneNumber"
            name="phoneNumber"
            value={props.information.phone_number}
            label={t("edit_views.features.sections.about.fields.phone_number")}
            onChange={(e) => props.onChange(e, "information.phone_number")}
            icon={{
              type: "PhoneIcon",
            }}
            disabled={!isEditable}
          />
        </div>
        <div className={styles.formFieldSection}>
          <RichCTA
            dataQA="operating-hours-cta"
            label={t(
              "edit_views.features.sections.about.fields.operating_hours",
            )}
            icon={{
              type: "HoursIcon",
            }}
            onClick={props.onOperatingHoursClick}
            disabled={!isEditable}
          >
            <div className={styles.operatingHoursContainer}>
              {Object.keys(props.information.operating_hours).map((key) => {
                const dayTimes = props.information.operating_hours[key as Days];
                const times = !dayTimes
                  ? t("operating_hours.status.closed")
                  : dayTimes.opening_time === "00:00" &&
                      dayTimes.closing_time === "24:00"
                    ? t("operating_hours.status.all_day")
                    : `${dayTimes.opening_time} - ${dayTimes.closing_time}`;
                return (
                  <div key={key} className={styles.operatingHoursRow}>
                    <span>{t(`operating_hours.days.${key}`)}</span>
                    <span>{times}</span>
                  </div>
                );
              })}
            </div>
          </RichCTA>
        </div>
      </div>
      <div className={styles.section}>
        <div className={styles.sectionHeading}>
          <h5 className={styles.sectionTitle}>
            {t("edit_views.features.sections.temporarily_close.title")}
          </h5>
        </div>
        <div className={styles.formFieldSection}>
          {props.isTemporarilyClosed ? (
            <RichCTA
              dataQA="reopen-feature-cta"
              label={t(
                "edit_views.features.sections.temporarily_close.fields.reopen.description",
              )}
              icon={{
                type: "LocationIcon",
                width: 20,
                height: 20,
              }}
              onClick={props.onReopenClick}
              disabled={!isEditable}
            >
              <p className={styles.richCtaText}>
                {t(
                  "edit_views.features.sections.temporarily_close.fields.reopen.title",
                )}
              </p>
            </RichCTA>
          ) : (
            <RichCTA
              dataQA="temporarily-close-feature-cta"
              label={t(
                "edit_views.features.sections.temporarily_close.fields.temporarily_close.description",
              )}
              icon={{
                type: "LocationOffIcon",
                width: 20,
                height: 20,
              }}
              onClick={props.onTemporarilyCloseClick}
              disabled={!isEditable}
            >
              <p className={styles.richCtaText}>
                {t(
                  "edit_views.features.sections.temporarily_close.fields.temporarily_close.title",
                )}
              </p>
            </RichCTA>
          )}
        </div>
        <div className={styles.spacer}></div>
        <div className={styles.sectionHeading}>
          <h5 className={styles.sectionTitle}>
            {t("edit_views.features.sections.archive.title")}
          </h5>
        </div>
        <div className={styles.formFieldSection}>
          {props.status !== STATUS.ARCHIVED ? (
            <RichCTA
              dataQA="delete-feature-cta"
              label={t(
                "edit_views.features.sections.archive.fields.archive.description",
              )}
              icon={{
                type: "CloseSmIcon",
                width: 14,
                height: 14,
                className: styles.closeIcon,
              }}
              onClick={props.onDeleteClick}
              disabled={!isArchivable}
            >
              <p className={styles.richCtaText}>
                {t("edit_views.features.sections.archive.fields.archive.title")}
              </p>
            </RichCTA>
          ) : (
            <RichCTA
              dataQA="delete-feature-cta"
              label={t(
                "edit_views.features.sections.unarchive.fields.unarchive.description",
              )}
              icon={{
                type: "CheckIcon",
                width: 14,
                height: 14,
                className: styles.closeIcon,
              }}
              onClick={props.onUnarchiveClick}
            >
              <p className={styles.richCtaText}>
                {t(
                  "edit_views.features.sections.unarchive.fields.unarchive.title",
                )}
              </p>
            </RichCTA>
          )}
        </div>
      </div>
    </div>
  );
};

export default Info;
