import classNames from "classnames";
import { Icon, IconType } from "@livingmap/core-ui-v2";

import styles from "./RichCTA.module.css";

interface Props {
  dataQA: string;
  label?: string;
  icon?: {
    type: IconType;
    width?: number;
    height?: number;
    className?: string;
  };
  onClick?: (...args: any[]) => any;
  children?: React.ReactNode;
  disabled?: boolean;
}

const RichCTA: React.FC<Props> = ({
  dataQA,
  label,
  icon,
  onClick,
  children,
  disabled = false,
}) => {
  return (
    <div data-qa={dataQA}>
      {label && <span className={styles.label}>{label}</span>}
      <div
        className={classNames(styles.container, {
          [styles.disabled]: disabled,
        })}
        onClick={onClick}
      >
        {icon && (
          <Icon
            dataQA="cta-icon"
            {...icon}
            className={classNames(styles.icon, icon.className)}
          />
        )}
        <div className={styles.contentWrapper}>{children}</div>
        <div className={styles.chevron}>
          <Icon dataQA="chevron-icon" type="ChevronRightIcon" />
        </div>
      </div>
    </div>
  );
};

export default RichCTA;
