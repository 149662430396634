import { useState, useEffect } from "react";
import { produce } from "immer";
import { t } from "i18next";
import { Icon, Button, Spinner, Dialog } from "@livingmap/core-ui-v2";

import Sidebar from "@components/Sidebar/Sidebar";
import Pill from "@components/Pill/Pill";
import Popover from "@components/Popover/Popover";
import RevertChanges from "./Modals/RevertChanges";
import TemporarilyClose from "./Modals/TemporarilyClose";
import Reopen from "./Modals/Reopen";
import DeleteFeature from "./Modals/DeleteFeature";
import DeleteImage from "./Modals/DeleteImage";
import OperatingHours from "./Modals/OperatingHours";
import { Tabs } from "./Tabs";
import { useDebounce } from "@hooks";
import { createTimeArray, setValueByPath } from "@utils";
import {
  FeatureData,
  OperatingHoursObject,
  Icon as IconType,
  CategoryObject,
  SubcategoryObject,
  STATUS,
} from "@redux/services/types";
import styles from "./EditFeatureSidebar.module.css";
import CloseSidebar from "./Modals/CloseSidebar";
import { useUploadFeatureMediaForMapByIdMutation } from "@redux/services/mms";
import { useAppSelector } from "@redux/hooks";
import Unarchive from "./Modals/Unarchive";
import classNames from "classnames";

type EditFeatureTabs = "info" | "media" | "tags" | "history";

type DeleteImageModalState = {
  isActive: boolean;
};

const tabs: EditFeatureTabs[] = ["info", "media", "tags", "history"];
const times = createTimeArray(5); // For the dropdown options in the operating hours modal

interface Props extends FeatureData {
  isLoading: boolean;
  icons: IconType[];
  allCategories: CategoryObject[];
  onSidebarCloseClick: () => void;
  onEditFeatureSave: (data: FeatureData) => void;
  onFeatureDelete: (data: FeatureData) => void;
  onUnarchiveFeature: (data: FeatureData) => void;
  onFeatureSidebarReady?: () => void;
  onRevertAllChanges: () => void;
  onDataChanged: (hasDataChanged: boolean) => void;
  children?: React.ReactNode;
}

const EditFeatureSidebar: React.FC<Props> = ({
  isLoading,
  icons,
  allCategories,
  onSidebarCloseClick,
  onEditFeatureSave,
  onFeatureDelete,
  onUnarchiveFeature,
  onFeatureSidebarReady,
  onRevertAllChanges,
  onDataChanged,
  children,
  ...props
}) => {
  const { mapID, featureID } = useAppSelector((state) => state.application);
  const [activeTab, setActiveTab] = useState<EditFeatureTabs>("info");
  const [isRevertModalActive, setIsRevertModalActive] = useState(false);
  const [isTemporarilyCloseModalActive, setIsTemporarilyCloseModalActive] =
    useState(false);
  const [isReopenModalActive, setIsReopenModalActive] = useState(false);
  const [isDeleteFeatureModalActive, setIsDeleteFeatureModalActive] =
    useState(false);
  const [isUnarchiveModalActive, setIsUnarchiveModalActive] = useState(false);
  const [isOperatingHoursModalActive, setIsOperatingHoursModalActive] =
    useState(false);
  const [deleteImageModalState, setDeleteImageModalState] =
    useState<DeleteImageModalState>({
      isActive: false,
    });
  const [isCloseSidebarModalActive, setCloseSidebarModalActive] =
    useState(false);
  const [dataHasChanged, setDataHasChanged] = useState(false);

  const [data, setData] = useState<FeatureData>(props);
  const [uploadMedia] = useUploadFeatureMediaForMapByIdMutation();

  useEffect(() => {
    onFeatureSidebarReady && onFeatureSidebarReady();
  }, [onFeatureSidebarReady]);

  const jsonProps = JSON.stringify(props);
  const jsonData = JSON.stringify(data);

  const debouncedDataHasChanged = useDebounce(jsonProps !== jsonData, 100);

  // Ensures local state is updated if any prop values change i.e. once "save" has been clicked & we receive updated feature data from the API which gets passed in from the parent
  useEffect(() => {
    setData(JSON.parse(jsonProps));
  }, [jsonProps]); // JSON.stringify ensures deep comparison of the props object & that it only runs when a nested value changes

  // Set the local "dataHasChanged" state & call the "onDataChanged" callback, so that parent components can update the "hasUnsavedChanges" state in the redux store as required
  useEffect(() => {
    setDataHasChanged(debouncedDataHasChanged);
    onDataChanged(debouncedDataHasChanged);
  }, [debouncedDataHasChanged, onDataChanged]);

  if (isLoading || !data.id || !allCategories || !icons) {
    return (
      <div className={styles.loadingContainer}>
        <Spinner dataQA="loading-spinner" type="BeatLoader" />
      </div>
    );
  }

  const changeHandler = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    path: string,
    defaultObject?: {
      [key: string]: any;
    },
  ) => {
    if (e.target.type === "checkbox") {
      const newData = produce(data, (draftState) => {
        if (!("checked" in e.target)) return;
        setValueByPath(draftState, path, e.target.checked);
      });
      return setData(newData);
    }

    const newData = produce(data, (draftState) => {
      setValueByPath(draftState, path, e.target.value || null, defaultObject);
    });
    setData(newData);
  };

  const handleIconChange = (icon: IconType) => {
    setData({
      ...data,
      label: {
        ...data.label,
        icon: icon.id,
      },
    });
  };

  const handleIconClear = () => {
    setData({
      ...data,
      label: {
        ...data.label,
        icon: null,
      },
    });
  };

  const handleCategorySelect = (category: CategoryObject) => {
    setData({
      ...data,
      categories: {
        category: {
          id: category.id,
          name: category.name,
        },
        subcategory: {
          id: category.subcategories[0].id,
          name: category.subcategories[0].name,
        },
      },
    });
  };

  const handleSubcategorySelect = (subcategory: SubcategoryObject) => {
    setData({
      ...data,
      categories: {
        ...data.categories,
        subcategory: {
          id: subcategory.id,
          name: subcategory.name,
        },
      },
    });
  };

  const handleRankChange = (value: number) => {
    setData({
      ...data,
      rank: {
        ...data.rank,
        value,
      },
    });
  };

  const handleRankReset = () => {
    setData({
      ...data,
      rank: {
        ...data.rank,
        value: data.rank.default,
      },
    });
  };

  const handleFileAdd = (file: File) => {
    const formData = new FormData();
    formData.append("image", file);
    uploadMedia({ mapID, featureID, data: formData }).then((response) => {
      if ("error" in response) throw new Error("Error uploading media file");
      setData({
        ...data,
        media: [response.data],
      });
    });
  };

  const handleFileDelete = () => {
    if (!deleteImageModalState.isActive) return;

    if (data.media) {
      setData({
        ...data,
        media: null,
      });
    }

    setDeleteImageModalState({
      isActive: false,
    });
  };

  const handleTagAdd = (tag: string, language: string) => {
    setData({
      ...data,
      tags: data.tags.map((languageObject) => {
        if (languageObject.lang === language) {
          return {
            ...languageObject,
            text: languageObject.text ? [...languageObject.text, tag] : [tag],
          };
        }
        return languageObject;
      }),
    });
  };

  const handleTagDelete = (tag: string, language: string) => {
    setData({
      ...data,
      tags: data.tags.map((languageObject) => {
        if (languageObject.lang === language) {
          return {
            ...languageObject,
            text: languageObject.text
              ? languageObject.text.filter((t) => t !== tag)
              : [],
          };
        }
        return languageObject;
      }),
    });
  };

  const handleOpenAndClose = (type: "closed" | "open") => {
    setIsTemporarilyCloseModalActive(false);
    setIsReopenModalActive(false);
    setData({
      ...data,
      is_temporarily_closed: type === "closed",
    });
  };

  const handleOperatingHoursUpdate = (operatingHours: OperatingHoursObject) => {
    setData({
      ...data,
      information: {
        ...data.information,
        operating_hours: operatingHours,
      },
    });
    setIsOperatingHoursModalActive(false);
  };

  const handleRevertAllChanges = () => {
    setData(props);
    setIsRevertModalActive(false);
    onRevertAllChanges();
  };

  const handleFeatureDelete = () => {
    setIsDeleteFeatureModalActive(false);
    onFeatureDelete(data);
  };

  const handleUnarchiveFeature = () => {
    setIsUnarchiveModalActive(false);
    onUnarchiveFeature(data);
  };

  let tabContent;

  switch (activeTab) {
    case "info":
      tabContent = (
        <Tabs.Info
          dataQA="info-tab"
          featureID={data.id}
          isTemporarilyClosed={data.is_temporarily_closed}
          onChange={changeHandler}
          onOperatingHoursClick={() => setIsOperatingHoursModalActive(true)}
          onTemporarilyCloseClick={() => setIsTemporarilyCloseModalActive(true)}
          onReopenClick={() => setIsReopenModalActive(true)}
          onDeleteClick={() => setIsDeleteFeatureModalActive(true)}
          onUnarchiveClick={() => setIsUnarchiveModalActive(true)}
          icons={icons}
          onIconChange={handleIconChange}
          onIconClearClick={handleIconClear}
          allCategories={allCategories}
          categories={data.categories}
          onCategorySelect={handleCategorySelect}
          onSubcategorySelect={handleSubcategorySelect}
          label={{ ...data.label }}
          rank={{
            minimum: data?.rank?.minimum,
            maximum: data?.rank?.maximum,
            value: data?.rank?.value,
            onChange: handleRankChange,
            onResetClick: handleRankReset,
          }}
          street_address={data.street_address}
          location={{ ...data.location }}
          information={{ ...data.information }}
          status={data?.status}
        />
      );
      break;
    case "media":
      tabContent = (
        <Tabs.Media
          dataQA="media-tab"
          media={data.media}
          onFileAdd={handleFileAdd}
          onDeleteClick={() =>
            setDeleteImageModalState({
              isActive: true,
            })
          }
          status={data?.status}
        />
      );
      break;
    case "tags":
      tabContent = (
        <Tabs.Tags
          dataQA="tags-tab"
          tags={data.tags}
          onTagAdd={handleTagAdd}
          onTagDelete={handleTagDelete}
          status={data?.status}
        />
      );
      break;
    case "history":
      tabContent = (
        <Tabs.History dataQA="history-tab" lastModified={data.last_modified} />
      );
      break;
    default:
  }

  const closeSidebarModal = () => (
    <Dialog
      dataQA="close-sidebar-dialog"
      isOpen={isCloseSidebarModalActive}
      onClose={() => setCloseSidebarModalActive(false)}
      maxWidth={448}
    >
      <CloseSidebar
        closeModal={() => setCloseSidebarModalActive(false)}
        onCloseSidebar={onSidebarCloseClick}
      />
    </Dialog>
  );

  const revertChangesModal = () => (
    <Dialog
      dataQA="revert-changes-dialog"
      isOpen={isRevertModalActive}
      onClose={() => setIsRevertModalActive(false)}
      maxWidth={448}
    >
      <RevertChanges
        closeModal={() => setIsRevertModalActive(false)}
        onRevertChanges={handleRevertAllChanges}
      />
    </Dialog>
  );

  const temporarilyCloseModal = () => (
    <Dialog
      dataQA="temporarily-close-dialog"
      isOpen={isTemporarilyCloseModalActive}
      onClose={() => setIsTemporarilyCloseModalActive(false)}
      maxWidth={448}
    >
      <TemporarilyClose
        featureName={data.label?.name?.[0].text}
        closeModal={() => setIsTemporarilyCloseModalActive(false)}
        onTemporarilyClose={() => handleOpenAndClose("closed")}
      />
    </Dialog>
  );

  const reOpenModal = () => (
    <Dialog
      dataQA="reopen-dialog"
      isOpen={isReopenModalActive}
      onClose={() => setIsReopenModalActive(false)}
      maxWidth={448}
    >
      <Reopen
        featureName={data.label?.name?.[0].text}
        closeModal={() => setIsReopenModalActive(false)}
        onReopen={() => handleOpenAndClose("open")}
      />
    </Dialog>
  );

  const featureDeleteModal = () => (
    <Dialog
      dataQA="delete-feature-dialog"
      isOpen={isDeleteFeatureModalActive}
      onClose={() => setIsDeleteFeatureModalActive(false)}
      maxWidth={448}
    >
      <DeleteFeature
        featureName={data.label?.name?.[0].text}
        closeModal={() => setIsDeleteFeatureModalActive(false)}
        onDelete={handleFeatureDelete}
      />
    </Dialog>
  );

  const unarchiveModal = () => (
    <Dialog
      dataQA="unarchive-dialog"
      isOpen={isUnarchiveModalActive}
      onClose={() => setIsUnarchiveModalActive(false)}
      maxWidth={448}
    >
      <Unarchive
        closeModal={() => setIsUnarchiveModalActive(false)}
        onUnarchive={handleUnarchiveFeature}
      />
    </Dialog>
  );

  const operatingHoursModal = () => (
    <Dialog
      dataQA="operating-hours-dialog"
      isOpen={isOperatingHoursModalActive}
      onClose={() => setIsOperatingHoursModalActive(false)}
      maxWidth={360}
    >
      <OperatingHours
        times={times}
        operatingHours={data.information?.operating_hours}
        closeModal={() => setIsOperatingHoursModalActive(false)}
        onOperatingHoursUpdate={handleOperatingHoursUpdate}
      />
    </Dialog>
  );

  const deleteImageModal = () => (
    <Dialog
      dataQA="delete-image-dialog"
      isOpen={deleteImageModalState.isActive}
      onClose={() =>
        setDeleteImageModalState({
          isActive: false,
        })
      }
      maxWidth={448}
    >
      <DeleteImage
        closeModal={() =>
          setDeleteImageModalState({
            isActive: false,
          })
        }
        onDelete={handleFileDelete}
      />
    </Dialog>
  );

  const isEditable =
    props.status === STATUS.PUBLISHED || props.status === STATUS.DRAFT;
  const isArchivable = props.status === STATUS.PUBLISHED;
  const isRevertable =
    props.status === STATUS.DRAFT_ARCHIVED ||
    props.status === STATUS.DRAFT_RESTORED ||
    props.status === STATUS.DRAFT;

  return (
    <Sidebar dataQA="edit-feature-sidebar" isOpen={true} position="relative">
      <div className={styles.container}>
        <div className={styles.sidebarTop}>
          <div className={styles.sidebarHeading}>
            <h5 className={styles.sidebarTitle}>
              {t("edit_views.features.title")}
            </h5>
            <div className={styles.topButtonWrapper}>
              <Popover
                toggleComponent={
                  <button className={styles.topButton}>
                    <Icon
                      dataQA="more-icon"
                      type="MoreVertIcon"
                      className={styles.topButtonIcon}
                    />
                  </button>
                }
              >
                <ul className={styles.topPopoverContainer}>
                  <li
                    onClick={() => setIsRevertModalActive(true)}
                    className={classNames({
                      [styles.disabled]: !isRevertable,
                    })}
                  >
                    {t("edit_views.features.dropdown_items.revert_changes")}
                  </li>
                  {data.is_temporarily_closed ? (
                    <li
                      onClick={() => setIsReopenModalActive(true)}
                      className={classNames({
                        [styles.disabled]: !isEditable,
                      })}
                    >
                      {t("edit_views.features.dropdown_items.reopen")}
                    </li>
                  ) : (
                    <li
                      onClick={() => setIsTemporarilyCloseModalActive(true)}
                      className={classNames({
                        [styles.disabled]: !isEditable,
                      })}
                    >
                      {t(
                        "edit_views.features.dropdown_items.temporarily_close",
                      )}
                    </li>
                  )}
                  <li
                    onClick={() => setIsDeleteFeatureModalActive(true)}
                    className={classNames({
                      [styles.disabled]: !isArchivable,
                    })}
                  >
                    {t("edit_views.features.dropdown_items.archive")}
                  </li>
                </ul>
              </Popover>
              <button
                className={styles.topButton}
                onClick={() =>
                  dataHasChanged
                    ? setCloseSidebarModalActive(true)
                    : onSidebarCloseClick()
                }
              >
                <Icon
                  dataQA="close-icon"
                  type="CloseIcon"
                  className={styles.topButtonIcon}
                />
              </button>
            </div>
          </div>
          <div className={styles.pillWrapper}>
            {data.is_temporarily_closed && (
              <Pill dataQA="temporarily-closed-pill" type="error">
                {t("edit_views.features.status.temporarily_closed")}
              </Pill>
            )}
            {data.status !== STATUS.PUBLISHED &&
              data.status !== STATUS.ARCHIVED && (
                <Pill dataQA="unpublished-pill" type="warning">
                  {t("edit_views.features.status.unpublished")}
                </Pill>
              )}
          </div>
          <div className={styles.tabs}>
            {tabs.map((tab) => (
              <button
                key={tab}
                className={`${styles.tab} ${
                  tab === activeTab ? styles.active : ""
                }`}
                onClick={() => setActiveTab(tab)}
              >
                {t(`edit_views.features.tabs.${tab}`)}
              </button>
            ))}
          </div>
        </div>
        <div className={styles.sidebarMiddle}>{tabContent}</div>
        <div className={styles.sidebarBottom}>
          <Button
            dataQA="revert-changes-button"
            color="black"
            onClick={() => setIsRevertModalActive(true)}
            disabled={!isRevertable}
            outlined
          >
            {t("btn_labels.revert_changes")}
          </Button>
          <div className={styles.saveCancelButtonContainer}>
            <Button
              dataQA="cancel-button"
              color="black"
              onClick={onSidebarCloseClick}
              disabled={!dataHasChanged}
            >
              {t("btn_labels.cancel")}
            </Button>
            <Button
              dataQA="save-button"
              color="blue"
              onClick={() => onEditFeatureSave(data)}
              disabled={!dataHasChanged}
            >
              {t("btn_labels.save")}
            </Button>
          </div>
        </div>
      </div>
      {revertChangesModal()}
      {temporarilyCloseModal()}
      {reOpenModal()}
      {featureDeleteModal()}
      {operatingHoursModal()}
      {deleteImageModal()}
      {closeSidebarModal()}
      {unarchiveModal()}
    </Sidebar>
  );
};

export default EditFeatureSidebar;
