import { SearchedFeatureData } from "@redux/services/types";
import styles from "./SearchResults.module.css";
import { useAppSelector } from "@redux/hooks";

interface Props {
  dataQA: string;
  results?: SearchedFeatureData[];
  onResultClick: (result: SearchedFeatureData) => void;
}

const SearchResults: React.FC<Props> = ({ dataQA, results, onResultClick }) => {
  const { defaultLanguage } = useAppSelector((state) => state.application);

  return (
    <div data-qa={dataQA} className={styles.container}>
      {results && results.length > 0 ? (
        results.map((feature) => (
          <div
            key={feature.id}
            className={styles.resultItem}
            onClick={() => onResultClick(feature)}
          >
            <div>
              <h6 className={styles.name}>
                {
                  feature.properties.name.find(
                    (item) => item.lang === defaultLanguage,
                  )?.text
                }
              </h6>
              {feature.properties.category && (
                <p className={styles.category}>{feature.properties.category}</p>
              )}
              {feature.properties.street_address && (
                <p className={styles.address}>
                  {feature.properties.street_address}
                </p>
              )}
            </div>
            {feature.properties.popup_image_url && (
              <img
                src={feature.properties.popup_image_url}
                alt="Map feature"
                className={styles.image}
              />
            )}
          </div>
        ))
      ) : (
        <div className={styles.noResults}>
          <p>No results found.</p>
        </div>
      )}
    </div>
  );
};

export default SearchResults;
