import { Icon } from "@livingmap/core-ui-v2";

import SelectorButton from "./SelectorButton/SelectorButton";
import Popover from "@components/Popover/Popover";
import { Project } from "@redux/services/types";
import styles from "./ProjectSelector.module.css";

export interface Props {
  dataQA: string;
  projects: Project[] | undefined;
  activeProject: Project | undefined;
  onProjectSelect: (project: Project) => void;
}

const ProjectSelector: React.FC<Props> = ({
  dataQA,
  projects,
  activeProject,
  onProjectSelect,
}) => {
  const handleProjectSelect = (project: Project, hidePopover: () => void) => {
    onProjectSelect(project);
    hidePopover();
  };

  if (projects && projects.length < 2) {
    return <SelectorButton activeProject={activeProject?.name} />;
  }

  return (
    <div data-qa={dataQA}>
      <Popover
        placement="bottom"
        toggleComponent={(isOpen) => (
          <SelectorButton activeProject={activeProject?.name} isOpen={isOpen} />
        )}
      >
        {(hidePopover) => (
          <div className={styles.projectsContainer}>
            {projects?.map((project) => (
              <div
                key={`${project.id}:${project.name}`}
                className={`${styles.project} ${
                  project.id === activeProject?.id ? styles.activeProject : ""
                }`}
                onClick={() => handleProjectSelect(project, hidePopover)}
              >
                {project.id === activeProject?.id && (
                  <Icon
                    dataQA="active-project-icon"
                    type="CheckSmIcon"
                    width={13}
                    height={13}
                    className={styles.icon}
                  />
                )}
                <span>{project.name}</span>
              </div>
            ))}
          </div>
        )}
      </Popover>
    </div>
  );
};

export default ProjectSelector;
