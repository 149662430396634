import { t } from "i18next";

import Alert, { AlertTypes } from "@components/Alert/Alert";
import FileUpload from "@components/FileUpload/FileUpload";
import Thumbnail from "@components/Thumbnail/Thumbnail";
import { MediaObject, STATUS } from "@redux/services/types";
import styles from "./Media.module.css";

interface Props {
  dataQA: string;
  media: MediaObject[] | null;
  onFileAdd: (files: File) => void;
  onDeleteClick: () => void;
  status: string;
}

const Media: React.FC<Props> = ({
  dataQA,
  media,
  onFileAdd,
  onDeleteClick,
  status,
}) => {
  const tips = (
    <ul>
      <li>{t("edit_views.features.sections.media.tips.list_items.0")}</li>
      <li>{t("edit_views.features.sections.media.tips.list_items.1")}</li>
      <li>{t("edit_views.features.sections.media.tips.list_items.2")}</li>
      <li>{t("edit_views.features.sections.media.tips.list_items.3")}</li>
    </ul>
  );

  const isEditable = status === STATUS.PUBLISHED || status === STATUS.DRAFT;

  return (
    <div data-qa={dataQA} className={styles.container}>
      <h5 className={styles.heading}>
        {t("edit_views.features.sections.media.title")}
      </h5>
      {media && media.length > 0 ? (
        <>
          <p className={styles.description}>
            {t("edit_views.features.sections.media.description_with_image")}
          </p>
          {media.map((mediaObject) => (
            <Thumbnail
              dataQA="feature-image"
              key={mediaObject.id}
              image={mediaObject.url}
              onDeleteClick={onDeleteClick}
            />
          ))}
        </>
      ) : (
        <>
          <p className={styles.description}>
            {t("edit_views.features.sections.media.description")}
          </p>
          <FileUpload
            dataQA="file-upload"
            onFileUpload={onFileAdd}
            maxFileSizeInBytes={104857600}
            disabled={!isEditable}
          />
          <div className={styles.alertContainer}>
            <Alert
              dataQA="tips-alert"
              title={t("edit_views.features.sections.media.tips.title")}
              description={tips}
              type={AlertTypes.INFO_ALT}
            />
          </div>
        </>
      )}
    </div>
  );
};

export default Media;
