import { Icon } from "@livingmap/core-ui-v2";

import styles from "./ZoomControl.module.css";

interface Props {
  dataQA: string;
  onZoomInClick: () => void;
  onZoomOutClick: () => void;
}

const ZoomControl: React.FC<Props> = ({
  dataQA,
  onZoomInClick,
  onZoomOutClick,
}) => {
  return (
    <div data-qa={dataQA} className={styles.container}>
      <div className={styles.button} onClick={onZoomInClick}>
        <Icon dataQA="plus-icon" type="PlusIcon" />
      </div>
      <div className={styles.button} onClick={onZoomOutClick}>
        <Icon dataQA="minus-icon" type="MinusIcon" />
      </div>
    </div>
  );
};

export default ZoomControl;
