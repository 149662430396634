import React from "react";

import Link, { Props as LinkProps } from "@components/Link/Link";
import styles from "./Menu.module.css";

export interface Props {
  dataQA: string;
  menuItems?: LinkProps[];
}

const Menu: React.FC<Props> = ({ dataQA, menuItems }) => {
  return (
    <div data-qa={dataQA} className={styles.container}>
      {menuItems?.map((item) => (
        <Link key={`${item.type}:${item.path}:${item.text}`} {...item} />
      ))}
    </div>
  );
};

export default Menu;
