import { Icon, IconType } from "@livingmap/core-ui-v2";
import classNames from "classnames";
import { ReactNode } from "react";
import styles from "./Alert.module.css";

export enum AlertTypes {
  INFO = "INFO",
  INFO_ALT = "INFO_ALT",
  WARNING = "WARNING",
  ERROR = "ERROR",
  SUCCESS = "SUCCESS",
}

interface Props {
  type: AlertTypes;
  closable?: boolean;
  onClose?: () => void;
  title?: string;
  description: ReactNode;
  dataQA: string;
}

const getIcon = (type: AlertTypes): IconType => {
  switch (type) {
    case AlertTypes.INFO:
    case AlertTypes.INFO_ALT:
      return "InfoSolidIcon";
    case AlertTypes.ERROR:
      return "ErrorSolidIcon";
    case AlertTypes.WARNING:
      return "WarningIcon";
    case AlertTypes.SUCCESS:
      return "CheckSolidIcon";
    default:
      return "InfoSolidIcon";
  }
};

const Alert = ({
  title,
  description,
  type = AlertTypes.INFO,
  closable,
  onClose,
  dataQA,
}: Props) => {
  const classnames = classNames(styles.container, styles[type]);

  return (
    <div className={classnames} data-qa={dataQA}>
      <div
        className={classNames(styles.content, {
          [styles.contentWithTitle]: title,
        })}
      >
        <Icon
          dataQA="alert-icon"
          type={getIcon(type)}
          className={styles.icon}
        />
        <div className={styles.textWrapper}>
          {title && (
            <h1
              className={classNames(styles.title, {
                [styles.withDescription]: description,
              })}
              data-qa="title"
            >
              {title}
            </h1>
          )}
          <div className={styles.description} data-qa={description}>
            {description}
          </div>
        </div>
      </div>
      {closable && (
        <div
          onClick={onClose}
          className={styles.iconWrapper}
          data-qa="close-button"
        >
          <Icon
            dataQA="close-icon"
            type="CloseIcon"
            className={styles.closeIcon}
          />
        </div>
      )}
    </div>
  );
};

export default Alert;
