import React from "react";
import { Icon } from "@livingmap/core-ui-v2";

import { TextAndNumberInterface } from "../FormControl";
import styles from "./TextNumberInput.module.css";
import classNames from "classnames";

const TextNumberInput: React.FC<TextAndNumberInterface> = ({
  dataQA,
  iconPosition = "left",
  onIconClick,
  ...props
}) => {
  return (
    <div data-qa={dataQA} className={styles.container}>
      {props.label && (
        <label htmlFor={props.id} className={styles.label}>
          {props.label}
        </label>
      )}
      {props.icon ? (
        <label
          className={classNames(styles.iconLabel, {
            [styles.iconRight]: iconPosition === "right",
            [styles.iconLeft]: iconPosition === "left",
          })}
        >
          <Icon dataQA="input-icon" onClick={onIconClick} {...props.icon} />
          <input
            {...props}
            className={styles.iconInput}
            value={props.value || ""}
          />
        </label>
      ) : (
        <input {...props} className={styles.input} value={props.value || ""} />
      )}
    </div>
  );
};

export default TextNumberInput;
