import { useState } from "react";
import { t } from "i18next";
import { Icon, Button, Dialog } from "@livingmap/core-ui-v2";

import Menu from "@components/Menu/Menu";
import ProjectSelector from "@components/ProjectSelector/ProjectSelector";
import { Props as MenuProps } from "@components/Menu/Menu";
import { Props as ProjectSelectorProps } from "@components/ProjectSelector/ProjectSelector";
import PublishModalContent from "./PublishModalContent/PublishModalContent";
import styles from "./Header.module.css";

interface HeaderProps {
  dataQA: string;
  changes?: {
    features: number;
    deletedFeatures: number;
  };
  onPublish?: () => void;
}

type Props = HeaderProps & MenuProps & ProjectSelectorProps;

const Header: React.FC<Props> = ({
  dataQA,
  menuItems,
  activeProject,
  onProjectSelect,
  projects,
  changes,
  onPublish,
}) => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  let totalChanges = 0;

  if (changes) {
    // Map through all "changes" keys & sum up total changes
    totalChanges = Object.keys(changes)
      .map((key) => changes[key as keyof typeof changes])
      .reduce((previous, current) => previous + current);
  }

  const handlePublish = () => {
    setIsModalOpen(false);
    onPublish && onPublish();
  };

  return (
    <div data-qa={dataQA} className={styles.container}>
      <div className={styles.menuContainer}>
        <ProjectSelector
          dataQA="project-selector"
          projects={projects}
          activeProject={activeProject}
          onProjectSelect={onProjectSelect}
        />
        <Menu dataQA="header-menu" menuItems={menuItems} />
        {onPublish && (
          <div className={styles.buttonWrapper}>
            <Button
              dataQA="publish-button"
              color="red"
              disabled={totalChanges === 0}
              size="small"
              onClick={() => setIsModalOpen(true)}
            >
              {t("header.changes", { count: totalChanges })}
            </Button>
            {changes && (
              <Dialog
                dataQA="publish-dialog"
                isOpen={isModalOpen}
                onClose={() => setIsModalOpen(false)}
                maxWidth={560}
              >
                <PublishModalContent
                  changes={changes}
                  onPublish={handlePublish}
                  closeModal={() => setIsModalOpen(false)}
                />
              </Dialog>
            )}
            {totalChanges > 0 ? (
              <div className={styles.changes}>
                <span>{totalChanges}</span>
              </div>
            ) : (
              <div className={styles.published}>
                <Icon
                  dataQA="check-icon"
                  type="CheckSmIcon"
                  width={14}
                  height={14}
                  className={styles.publishedIcon}
                />
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default Header;
