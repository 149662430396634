import { t } from "i18next";

import FormControl from "@components/FormControl/FormControl";
import { formatDate } from "@utils";
import styles from "./History.module.css";

interface Props {
  dataQA: string;
  lastModified: {
    modified_at: string;
    modified_by: string;
  };
}

const History: React.FC<Props> = ({ dataQA, lastModified }) => {
  const modified_at_string = formatDate(lastModified.modified_at);

  return (
    <div data-qa={dataQA} className={styles.container}>
      <h5 className={styles.heading}>
        {t("edit_views.features.sections.history.title")}
      </h5>
      <div className={styles.formFieldSection}>
        <FormControl
          dataQA="last-modified-date-input"
          type="text"
          label={t(
            "edit_views.features.sections.history.fields.last_modified_date",
          )}
          id="modifiedAt"
          name="modifiedAt"
          value={modified_at_string}
          disabled={true}
        />
      </div>
      <div className={styles.formFieldSection}>
        <FormControl
          dataQA="last-modified-by-input"
          type="text"
          label={t(
            "edit_views.features.sections.history.fields.last_modified_by",
          )}
          id="modifiedBy"
          name="modifiedBy"
          value={lastModified.modified_by}
          disabled={true}
        />
      </div>
    </div>
  );
};

export default History;
