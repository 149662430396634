import React from "react";
import { Link as LinkComponent, NavLink } from "react-router-dom";

import styles from "./Link.module.css";

export interface Props {
  dataQA: string;
  type:
    | "primary"
    | "primary-ghost"
    | "primary-transparent"
    | "secondary"
    | "secondary-ghost"
    | "secondary-transparent"
    | "tertiary";
  as: "Link" | "NavLink";
  path: string;
  text: string;
}

const Link: React.FC<Props> = ({
  dataQA,
  type = "primary",
  as = "NavLink",
  path,
  text,
}) => {
  return as === "NavLink" ? (
    <NavLink
      data-qa={dataQA}
      to={path}
      className={({ isActive }) =>
        isActive
          ? `${styles.container} ${styles[type]} ${styles.active}`
          : `${styles.container} ${styles[type]}`
      }
    >
      {text}
    </NavLink>
  ) : (
    <LinkComponent
      data-qa={dataQA}
      to={path}
      className={`${styles.container} ${styles[type]}`}
    >
      {text}
    </LinkComponent>
  );
};

export default Link;
