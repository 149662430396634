import LivingMap, { LMFeature, StateType } from "@livingmap/core-mapping";

/**
 * Class representing a visualisation style of Geometry Map features.
 * This class controls the logic around hovering features.
 */
class MapGeometryHover {
  private LMMap: LivingMap;
  constructor(LMMap: LivingMap) {
    this.LMMap = LMMap;
  }
  /**
   * Sets a spefic LMFeature as being hovered.
   * Note: a second call to `set` does not alter anything. the fature will keep the same hovered state.
   * @param  {LMFeature} feature
   * @returns boolean
   */
  public hover(feature: LMFeature): boolean {
    const currentlyHoveredFeature =
      this.LMMap.getFeatureStateDelegate().getFeatureForState(StateType.HOVER);
    if (currentlyHoveredFeature) {
      const isTheSameGeometry =
        currentlyHoveredFeature.getId() === feature.getId();
      if (isTheSameGeometry) return false;
      this.unhover();
    }
    this.LMMap.getFeatureStateDelegate().setFeatureState(feature, {
      hover: true,
    });
    return true;
  }

  /**
   * unhovers all hovered features.
   * NOTE: only 1 feature can ever be hovered. so this will unhover that feature.
   * @returns boolean
   */
  public unhover(_ = false): boolean {
    const currentlyHoveredFeature =
      this.LMMap.getFeatureStateDelegate().getFeatureForState(StateType.HOVER);
    if (!currentlyHoveredFeature) return false;

    this.LMMap.getFeatureStateDelegate().setFeatureState(
      currentlyHoveredFeature,
      { hover: false }
    );
    return true;
  }
}

export default MapGeometryHover;
