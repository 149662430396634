import { t } from "i18next";

import styles from "./ProfileMenu.module.css";

interface Props {
  dataQA: string;
  handleSignout: () => void;
}

const ProfileMenu: React.FC<Props> = ({ dataQA, handleSignout }) => {
  return (
    <div data-qa={dataQA}>
      <ul className={styles.container}>
        <li>
          <span className={styles.menuItem} onClick={handleSignout}>
            {t("sidebar.sign_out")}
          </span>
        </li>
      </ul>
    </div>
  );
};

export default ProfileMenu;
