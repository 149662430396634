import { FloorConfig } from "@components/Map/plugins/floor-control";
import { Floors } from "@redux/services/mms";

export const getFloorByID = (
  floors: Floors,
  floorID: number
): FloorConfig | {} => {
  return Object.keys(floors).reduce((acc, curr) => {
    if (floors[curr].id !== floorID) return acc;

    return floors[curr];
  }, {});
};
