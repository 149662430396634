import LivingMap, {
  LivingMapPlugin,
  LMFeature,
  GlobalFilters,
} from "@livingmap/core-mapping";
import ClusteredPinControl from "./clustered-pin-control";
import MapGeometryHighlighter from "./geometry-visualisation/geometry-highlight";
import MapGeometryHover from "./geometry-visualisation/geometry-hover";
import MapGeometrySelector, {
  SelectFeatureOptions,
} from "./geometry-visualisation/geometry-select";
import UserClickAction from "./user-interactions/user-click-actioner";
import UserMouseMoveAction from "./user-interactions/user-mousemove-actioner";
import { SourceIds, FeatureLayers } from "./types/index";

class InteractionPlugin extends LivingMapPlugin {
  private shouldSelectLocationUI: boolean | null = null;

  private clusteredPinControl: ClusteredPinControl;
  private selectedFeatureSource: mapboxgl.GeoJSONSource | null = null;

  private userClickActioner: UserClickAction;
  private mouseMoveActioner: UserMouseMoveAction;

  private geometrySelector: MapGeometrySelector;
  private geometryHighlighter: MapGeometryHighlighter;
  private geometryHoverer: MapGeometryHover;

  private handleFeatureSelect: ((feature: LMFeature) => void) | null;

  private cancelSelectFeature: boolean;

  constructor(
    id: string,
    LMMap: LivingMap,
    clusteredPinPlugin: ClusteredPinControl
  ) {
    super(id, LMMap);

    this.clusteredPinControl = clusteredPinPlugin;

    this.userClickActioner = new UserClickAction(LMMap);
    this.mouseMoveActioner = new UserMouseMoveAction(LMMap);

    this.geometrySelector = new MapGeometrySelector(LMMap);
    this.geometryHighlighter = new MapGeometryHighlighter(LMMap);
    this.geometryHoverer = new MapGeometryHover(LMMap);
    this.handleFeatureSelect = null;
    this.cancelSelectFeature = false;
  }

  /**
   * public activate enables functionality of map control
   * @public
   */
  activate(): void {
    const mapInstance = this.LMMap.getMapboxMap();
    mapInstance.on("click", this.userClickActioner.handle);
    mapInstance.on("mousemove", this.mouseMoveActioner.handle);

    if (mapInstance.getSource(SourceIds.SELECTED_FEATURE_SOURCE_ID) !== null) {
      this.selectedFeatureSource = mapInstance.getSource(
        SourceIds.SELECTED_FEATURE_SOURCE_ID
      ) as mapboxgl.GeoJSONSource;
    }

    const filterInstance = this.LMMap.getFilterKing();
    filterInstance.updateLocalFilter(FeatureLayers.SELECTED_FEATURE_LAYER, {
      globalExclusions: [GlobalFilters.LAYERS],
    });
  }

  /**
   * public deactivate disables functionality of map control
   * @public
   */
  deactivate(): void {
    const mapInstance = this.LMMap.getMapboxMap();
    mapInstance.off("click", this.userClickActioner.handle);
    mapInstance.off("mousemove", this.mouseMoveActioner.handle);
  }

  public subscribeToFeatureSelect(callback: (feature: LMFeature) => void) {
    this.handleFeatureSelect = callback;
  }

  public setCancelSelectFeature(shouldCancel: boolean) {
    this.cancelSelectFeature = shouldCancel;
  }

  public selectFeature(
    feature: LMFeature,
    options?: SelectFeatureOptions
  ): void {
    const opts: SelectFeatureOptions = {
      silent: false,
      force: false,
      ...options,
    };

    this.handleFeatureSelect && this.handleFeatureSelect(feature);

    if (this.cancelSelectFeature) return;

    // if the feature that is selected belongs to the live location subsystem, the call is passed on and halted here
    if (feature.getSource() === SourceIds.LIVE_LOCATION_SOURCE_ID) {
      this.LMMap.emit("LiveLocationSelected", feature.getId());
      return;
    }

    // when selecting a geometry, we want to ensure no geometry has an active visualisatoin state.
    this.geometryHighlighter.unhighlight(false);
    this.geometryHoverer.unhover(false);

    // Check if we have interactive markers enabled in the redux layer, if not then return early
    // const state = store.getState();
    // if (!state.ui.mapControls.interactiveMarkers) return;

    const hasSelected = this.geometrySelector.select(feature, opts);

    if (hasSelected && this.clusteredPinControl) {
      this.clusteredPinControl.reloadClusteredPins();
    }

    this.LMMap.easeTo(feature);
  }

  public deselectFeatures(silent = false): void {
    const hasDeselected = this.geometrySelector.unselect(silent);
    if (hasDeselected && this.clusteredPinControl) {
      this.clusteredPinControl.reloadClusteredPins();
    }
    if (!silent) {
      this.LMMap.emit("LiveLocationSelected", null);
    }
  }

  public highlightFeature(feature: LMFeature): boolean {
    return this.geometryHighlighter.highlight(feature);
  }

  public dehighlightFeatures(): boolean {
    return this.geometryHighlighter.unhighlight();
  }

  public clearSelectedFeatureSource(): void {
    if (!this.selectedFeatureSource) return;

    this.selectedFeatureSource.setData({
      type: "FeatureCollection",
      features: [],
    });
  }

  public updateSelectedFeatureSource(feature: LMFeature): void {
    if (!this.selectedFeatureSource) return;

    // Note: we need to mutate the feature properties for some reason
    // unknown as to why.
    // TODO: perhaps add a LMFeature.copy() And an LMFeature.Builder.withGeometry().withProperties().build() ???
    const mapboxFeature = Object.assign({}, feature.getMapboxFeature());

    const centroid = feature.getCentroid();
    if (!centroid)
      throw new Error(
        `Centroid does not exist on LMFeature for: ${feature.getId()}`
      );

    mapboxFeature.geometry = {
      type: "Point",
      coordinates: centroid,
    };

    mapboxFeature.properties = {
      ...mapboxFeature.properties,
      selected: "active",
      preventClickPropagation: true,
      pin_type: feature.getPinType(),
    };

    this.selectedFeatureSource!.setData({
      type: "FeatureCollection",
      features: [mapboxFeature],
    });
  }

  public getSelectLocationUI(): boolean | null {
    return this.shouldSelectLocationUI;
  }
}

export default InteractionPlugin;
