import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

import keycloak from "../../keycloak";
import { ProjectJobsResponse, RunProjectJobResponse } from "./types";

type MapId = string;
type RunProjectJobParams = {
  mapID: string;
  jobName: string;
};

export const workflowsApi = createApi({
  reducerPath: "workflowsApi",
  tagTypes: ["Jobs"],
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_JOB_CONTROLLER_API_URL,
    prepareHeaders: (headers) => {
      const token = keycloak.token;

      if (token) {
        headers.set("authorization", `Bearer ${token}`);
      }

      return headers;
    },
  }),
  endpoints: (builder) => ({
    getProjectJobs: builder.query<ProjectJobsResponse, MapId | null>({
      query: (mapID) => `/clients/mms/projects/${mapID}/jobs`,
      providesTags: ["Jobs"],
    }),
    runProjectJob: builder.mutation<RunProjectJobResponse, RunProjectJobParams>(
      {
        query: ({ mapID, jobName }) => ({
          url: `/clients/mms/projects/${mapID}/jobs/${jobName}`,
          method: "POST",
        }),
        invalidatesTags: ["Jobs"],
      },
    ),
  }),
});

export const { useGetProjectJobsQuery, useRunProjectJobMutation } =
  workflowsApi;
