import { Button } from "@livingmap/core-ui-v2";

import styles from "./PageTitle.module.css";

interface Props {
  dataQA: string;
  heading: string;
  subheading?: string;
  customButton?: {
    text: string;
    onClick: () => void;
  };
}

const PageTitle: React.FC<Props> = ({
  dataQA,
  heading,
  subheading,
  customButton,
}) => {
  return (
    <div data-qa={dataQA} className={styles.container}>
      <div>
        <h2 className={styles.heading}>{heading}</h2>
        {subheading && <h4 className={styles.subheading}>{subheading}</h4>}
      </div>
      {customButton && (
        <Button
          dataQA="heading-button"
          color="blue"
          size="small"
          className={styles.button}
          onClick={customButton.onClick}
        >
          {customButton.text}
        </Button>
      )}
    </div>
  );
};

export default PageTitle;
