import LivingMap, {
  FeatureStateDelegate,
  LMFeature,
  StateType,
} from "@livingmap/core-mapping";

/**
 * Class representing a visualisation style of Geometry Map features.
 * This class controls the logic around highlighting features.
 */
class MapGeometryHighlighter {
  private featureStateDelegate: FeatureStateDelegate;
  constructor(LMMap: LivingMap) {
    this.featureStateDelegate = LMMap.getFeatureStateDelegate();
  }

  /**
   * Sets a spefic LMFeature as being selected.
   * NOTE: only 1 feature can ever be selected. That means the logic flow is as follows:
   * 1. Its a different feature than the one currently highlighted
   *   ---> Clear the old feature and highlight the new one as expected
   * 2. Its the same feature as the currently highlighted one
   *   ---> Clear the feature and return.
   * @param  {LMFeature} feature
   * @returns boolean
   */
  public highlight(feature: LMFeature): boolean {
    const currentlyHighlightedFeature =
      this.featureStateDelegate.getFeatureForState(StateType.HIGHLIGHT);
    if (currentlyHighlightedFeature) {
      this.unhighlight();

      const isTheSameGeometry =
        currentlyHighlightedFeature.getId() === feature.getId();
      if (isTheSameGeometry) return false;
    }

    this.featureStateDelegate.setFeatureState(feature, { highlight: true });
    return true;
  }

  /**
   * Unselects all selected features.
   * NOTE: only 1 feature can ever be selected. so this will unselect that feature.
   * @param  {boolean} silent whether an event should be emitted as a result.
   * @returns boolean
   */
  public unhighlight(_ = false): boolean {
    const highlightedFeature = this.featureStateDelegate.getFeatureForState(
      StateType.HIGHLIGHT
    );
    if (!highlightedFeature) return false;

    this.featureStateDelegate.setFeatureState(highlightedFeature, {
      highlight: false,
    });
    return true;
  }
}

export default MapGeometryHighlighter;
