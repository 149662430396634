type GenericObject = {
  [key: string]: any;
};

/**
 * Updates a nested value of an object/array by the path defined and returns the object/array. Returns the unedited object/array if the property doesn't exist.
 *
 * @param object object/array to apply the update to
 * @param path the path to search by. Array indexes can also be defined i.e. "foo.bar[3].baz"
 * @param value the value to update the property with
 * @param defaultObject the default object shape to create, if the value to be updated belongs to a nested object that doesn't currently exist i.e. a "description" field that consists of an array of language objects, but is currently set to null
 * @returns object/array
 */
export const setValueByPath = (
  object: GenericObject,
  path: string,
  value: any,
  defaultObject?: GenericObject
) => {
  const pathArray = path
    .replace(/\[(\w+)\]/g, ".$1") // converts indexes to properties i.e. [3] to .3
    .replace(/^\./, "") // remove leading dot
    .split(".");

  pathArray.reduce((acc, curr) => {
    // if the value is for a nested array of objects which is currently a null field i.e. a "description" field which would hold an array of objects but is currently null, update the field with the provided defaultObject argument & set it's passed in value
    if (acc[curr] === null && defaultObject) {
      defaultObject[pathArray[pathArray.length - 1]] = value;

      acc[curr] = [defaultObject];
    }

    if (!(curr in acc)) {
      return acc;
    }

    if (curr === pathArray[pathArray.length - 1]) {
      acc[curr] = value;
    }

    return acc[curr];
  }, object);

  return object;
};
