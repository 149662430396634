import classNames from "classnames";
import { FC } from "react";

import styles from "./ShortlinkPanel.module.scss";
import { Icon, IconButton } from "@livingmap/core-ui-v2";
import { CopyToClipboard } from "react-copy-to-clipboard";

interface Props {
  dataQA: string;
  className?: string;
  qrCode: {
    svg_base64: string;
    png_base64: string;
  };
  shortlinkUrl: string | null;
  name: string;
  createdAt: string;
  modifiedAt?: string;
  onEdit: () => void;
}

const ShortlinkPanel: FC<Props> = ({
  dataQA,
  className,
  qrCode,
  createdAt,
  name,
  onEdit,
  shortlinkUrl,
  modifiedAt,
}) => {
  const formatter = new Intl.DateTimeFormat("en-US", {
    month: "short", // abbreviated month name
    day: "numeric", // numeric day
    year: "numeric", // numeric year
  });

  return (
    <div data-qa={dataQA} className={classNames(styles.container, className)}>
      <div className={styles.qrCode}>
        <img
          src={`data:image/svg+xml;base64,${qrCode.svg_base64}`}
          alt="QR Code"
          className={styles.qrCodeImage}
        />
      </div>
      <div className={styles.details}>
        <span className={styles.title}>{name}</span>
        {shortlinkUrl && (
          <div className={styles.link}>
            <Icon
              className={styles.icon}
              dataQA="link-icon"
              type="LinkIcon"
              width={14}
              height={14}
            />
            <span>{shortlinkUrl}</span>
            <CopyToClipboard text={shortlinkUrl}>
              <Icon
                className={styles.icon}
                dataQA="copy-link-icon"
                type="ContentCopyIcon"
                width={14}
                height={14}
              />
            </CopyToClipboard>
          </div>
        )}
        <div className={styles.history}>
          <div>
            <Icon
              className={styles.icon}
              dataQA="created-at-icon"
              type="TimerIcon"
              width={14}
              height={14}
            />
            <span>{formatter.format(new Date(createdAt))}</span>
          </div>
          {modifiedAt && (
            <div>
              <Icon
                className={styles.icon}
                dataQA="created-at-icon"
                type="EditIcon"
                width={14}
                height={14}
              />
              <span>{formatter.format(new Date(modifiedAt))}</span>
            </div>
          )}
        </div>
      </div>
      <div className={styles.controls}>
        <IconButton
          dataQA="edit-shortlink-button"
          icon="EditIcon"
          onClick={onEdit}
          className={styles.editButton}
        />
      </div>
    </div>
  );
};

export default ShortlinkPanel;
