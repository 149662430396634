import React from "react";
import styles from "./Pill.module.css";

type PillStyles = "success" | "info" | "warning" | "error";

interface Props {
  dataQA: string;
  type: PillStyles;
  children?: React.ReactNode;
}

const Pill: React.FC<Props> = ({ dataQA, type, children }) => {
  return (
    <div data-qa={dataQA} className={`${styles[type]} ${styles.container}`}>
      {children}
    </div>
  );
};

export default Pill;
