import { t } from "i18next";

import TagsBox from "@components/TagsBox/TagsBox";
import { LanguageObject, STATUS } from "@redux/services/types";
import styles from "./Tags.module.css";

interface Props {
  dataQA: string;
  status: string;
  tags: LanguageObject<string[]>[] | null;
  onTagAdd: (tag: string, lang: string) => void;
  onTagDelete: (tag: string, lang: string) => void;
  limit?: number;
}

const Tags: React.FC<Props> = ({
  dataQA,
  status,
  tags,
  onTagAdd,
  onTagDelete,
  limit,
}) => {
  const isEditable = status === STATUS.PUBLISHED || status === STATUS.DRAFT;
  return (
    <div data-qa={dataQA} className={styles.container}>
      <h5 className={styles.heading}>
        {t("edit_views.features.sections.tags.title")}
      </h5>
      <p className={styles.description}>
        {t("edit_views.features.sections.tags.description")}
      </p>
      {tags?.map((language) => (
        <TagsBox
          dataQA="tags-box"
          disabled={!isEditable}
          tags={language.text}
          language={language.lang}
          onTagAdd={onTagAdd}
          onTagDelete={onTagDelete}
          limit={limit}
        />
      ))}
    </div>
  );
};

export default Tags;
