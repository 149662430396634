import { t } from "i18next";
import { IconButton } from "@livingmap/core-ui-v2";

import PopoverComponent from "@components/Popover/Popover";
import { useAppSelector } from "@redux/hooks";
import {
  useSaveFeatureForMapByIdMutation,
  useDeleteFeatureForMapByIdMutation,
  useRevertFeatureChangesForMapByIdMutation,
} from "@redux/services/mms";
import { ICellRendererParams } from "ag-grid-community";

import styles from "./AssetOptions.module.css";
import { STATUS } from "@redux/services/types";
import classNames from "classnames";

interface Props extends ICellRendererParams {}

const AssetOptions = ({ data: { id: featureID, status } }: Props) => {
  const [updateFeature] = useSaveFeatureForMapByIdMutation();
  const [deleteFeature] = useDeleteFeatureForMapByIdMutation();
  const [revertFeatureChanges] = useRevertFeatureChangesForMapByIdMutation();
  const { mapID } = useAppSelector((state) => state.application);

  const handleRevertFeatureChanges = () => {
    revertFeatureChanges({ mapID, featureID });
  };

  const handleTemporarilyCloseFeature = () => {
    updateFeature({
      mapID,
      featureID,
      featureData: { is_temporarily_closed: true },
    });
  };

  const handleDeleteFeature = () => {
    deleteFeature({ mapID, featureID });
  };

  const isEditable = status === STATUS.PUBLISHED || status === STATUS.DRAFT;
  const isArchivable = status === STATUS.PUBLISHED;
  const isRevertable =
    status === STATUS.DRAFT_ARCHIVED ||
    status === STATUS.DRAFT_RESTORED ||
    status === STATUS.DRAFT;

  return (
    <div onClick={(event) => event.stopPropagation()}>
      <PopoverComponent
        toggleComponent={
          <IconButton
            dataQA="more-button"
            icon="MoreVertIcon"
            onClick={() => {}}
            className={styles.container}
          />
        }
        placement={"bottom-end"}
      >
        <ul className={styles.menu}>
          <li
            onClick={handleRevertFeatureChanges}
            className={classNames({
              [styles.disabled]: !isRevertable,
            })}
          >
            {t("edit_views.features.dropdown_items.revert_changes")}
          </li>
          <li
            onClick={handleTemporarilyCloseFeature}
            className={classNames({
              [styles.disabled]: !isEditable,
            })}
          >
            {t("edit_views.features.dropdown_items.temporarily_close")}
          </li>
          <li
            onClick={handleDeleteFeature}
            className={classNames(styles.deleteButton, {
              [styles.disabled]: !isArchivable,
            })}
          >
            {t("edit_views.features.dropdown_items.archive")}
          </li>
        </ul>
      </PopoverComponent>
    </div>
  );
};

export default AssetOptions;
