type GenericObject = {
  [key: string]: any;
};

/**
 * Returns the nested value from the object by it's defined path. Returns a default value if the property doesn't exist.
 *
 * @param object the object/array to retrieve the value from
 * @param path the path to search by. Array indexes can also be defined i.e. "foo.bar[3].baz"
 * @param defaultValue the default value to return. Defaults to an empty string
 * @returns the nested value or default value
 */
export const getValueByPath = (
  object: GenericObject,
  path: string,
  defaultValue: any = ""
) => {
  if (!object) return object;

  const pathArray = path
    .replace(/\[(\w+)\]/g, ".$1") // converts indexes to properties i.e. [3] to .3
    .replace(/^\./, "") // remove leading dot
    .split(".");

  for (const key of pathArray) {
    if (key in object) {
      object = object[key];
    } else {
      return defaultValue;
    }
  }

  return object;
};
