import { toast, ToastContent, ToastOptions } from "react-toastify";

import styles from "./displayToast.module.css";

type ToastType = "default" | "success" | "info" | "warning" | "error";

export const displayToast = (
  type: ToastType,
  content: ToastContent,
  options?: ToastOptions
) => {
  if (type === "default") {
    toast(content, {
      ...options,
      theme: "dark",
      progressClassName: styles.darkThemeProgressBar,
    });
  } else {
    toast[type](content, options);
  }
};
