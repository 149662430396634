export enum PLUGIN_IDS {
  INTERACTION = "INTERACTION",
  ROUTING = "ROUTING",
  SEARCH = "SEARCH",
  GEOFENCE = "GEOFENCE",
  USER_LOCATION = "USER_LOCATION",
  MARKER = "MARKER",
  LIVEREPORTING = "LIVEREPORTING",
  DEBUG = "DEBUG",
  CLUSTERED_PIN = "CLUSTERED_PIN",
  FLOOR = "FLOOR",
  CATEGORIES = "CATEGORIES",
  DYNAMIC_DATA = "DYNAMIC_DATA",
  REGION = "REGION",
}

export enum EventTypes {
  FEATURE_CLICKED = "FEATURE_CLICKED",
  FEATURE_DESELECTED = "FEATURE_DESELECTED",
}

export enum SourceIds {
  LM_SOURCE_ID = "lm",
  LM_SOURCE_LAYER_INDOOR_ID = "indoor",
  LM_SOURCE_LAYER_OUTDOOR_ID = "outdoor",
  CLUSTER_SOURCE_ID = "pin",
  MMS_PIN_SOURCE_ID = "pin_mms",
  MMS_PIN_SOURCE_LAYER_ID = "pin_mms",
  SELECTED_FEATURE_SOURCE_ID = "selected_feature",
  LIVE_LOCATION_SOURCE_ID = "pin_user",
}

export enum FeatureLayers {
  SELECTED_FEATURE_LAYER = "search-highlight_pin",
}

export enum LayerIds {
  SELECTED_FEATURE_LAYER = "search-highlight_pin",
  SEARCH_PIN_LAYER = "search-search_pin",
  SEARCH_CLUSTER_LAYER = "search-search_badge_cluster",
  LIVE_LOCATION_LAYER_ID = "search-pin_user",
  UNDERGROUND_MASK_LAYER = "venue-underground_mask",
}

export enum StateType {
  SELECTED = "selected",
  HOVER = "hover",
  HIGHLIGHT = "highlight",
}
