import { Button } from "@livingmap/core-ui-v2";

import styles from "./Modal.module.css";

interface Props {
  acceptLabel: string;
  summary: string;
  closeModal: () => void;
  onRunWorkflow: () => void;
}

const RunWorkflow: React.FC<Props> = ({
  acceptLabel,
  summary,
  closeModal,
  onRunWorkflow,
}) => {
  const handleRunWorkflow = () => {
    onRunWorkflow();
    closeModal();
  };

  return (
    <div className={styles.container}>
      <p className={styles.description}>{summary}</p>
      <div className={styles.buttonWrapper}>
        <Button dataQA="cancel-button" color="black" onClick={closeModal}>
          Cancel
        </Button>
        <Button dataQA="okay-button" color="blue" onClick={handleRunWorkflow}>
          {acceptLabel}
        </Button>
      </div>
    </div>
  );
};

export default RunWorkflow;
