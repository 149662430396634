import { useEffect } from "react";
import { useKeycloak } from "@react-keycloak/web";

interface Props {
  children?: React.ReactNode;
}

const ProtectedRoute: React.FC<Props> = ({ children }) => {
  const { keycloak, initialized } = useKeycloak();

  useEffect(() => {
    if (!initialized) return;

    if (!keycloak.authenticated) {
      keycloak.login({
        redirectUri: process.env.REACT_APP_BASE_URL,
      });
    }
  }, [initialized, keycloak]);

  return keycloak.authenticated ? <div>{children}</div> : null;
};

export default ProtectedRoute;
