import { Icon } from "@livingmap/core-ui-v2";

import styles from "./ProfileButton.module.css";

interface Props {
  dataQA: string;
  isExpanded: boolean;
  username: string;
  company?: string;
}

const ProfileButton: React.FC<Props> = ({
  dataQA,
  isExpanded,
  username,
  company,
}) => {
  return (
    <div
      data-qa={dataQA}
      className={`${styles.container} ${isExpanded ? styles.expanded : ""}`}
    >
      <div className={styles.details}>
        <div className={styles.initialWrapper}>
          <span>{username.charAt(0).toUpperCase()}</span>
        </div>
        {isExpanded && (
          <div className={styles.nameWrapper}>
            <span className={styles.username}>{username}</span>
            {company && <span className={styles.company}>{company}</span>}
          </div>
        )}
      </div>
      {isExpanded && (
        <Icon
          dataQA="more-icon"
          type="MoreVertIcon"
          className={styles.moreIcon}
        />
      )}
    </div>
  );
};

export default ProfileButton;
