import { useState } from "react";
import { t } from "i18next";
import { Dialog, IconButton } from "@livingmap/core-ui-v2";
import type { ICellRendererParams } from "ag-grid-community";
import classNames from "classnames";

import {
  useLazyGetSingleLinkQuery,
  useDeleteSingleLinkMutation,
} from "@redux/services/links";
import PopoverComponent from "@components/Popover/Popover";
import DeleteLink from "@components/EditFeatureSidebar/Modals/DeleteLink";
import { isLivingMapUser } from "@utils";
import styles from "./LinkOptions.module.css";

enum QRType {
  SVG = "svg",
  PNG = "png",
}

interface Props extends Partial<ICellRendererParams> {
  className?: string;
}

const LinkOptions = ({ data: { full_url, scan_code }, className }: Props) => {
  const [getSingleLinkData, { data: singleLink }] = useLazyGetSingleLinkQuery();
  const [deleteSingleLink] = useDeleteSingleLinkMutation();

  const [isDeleteLinkModalActive, setIsDeleteLinkModalActive] = useState(false);

  const handleDownloadQR = (type: QRType) => {
    if (!singleLink?.qr_code) return;

    const a = document.createElement("a");
    a.download = `qr-code.${type}`;
    a.href = `data:image/${type};base64,${
      singleLink.qr_code[`${type}_base64`]
    }`;
    a.click();
  };

  const handleOnClick = () => {
    getSingleLinkData(scan_code);
  };

  const handleDeleteLink = () => {
    deleteSingleLink(scan_code);
  };

  const isLMUser = isLivingMapUser();

  return (
    <>
      <div
        className={classNames(className)}
        onClick={(event) => event.stopPropagation()}
      >
        <PopoverComponent
          toggleComponent={
            <IconButton
              dataQA="more-button"
              icon="MoreVertIcon"
              className={styles.container}
              onClick={handleOnClick}
            />
          }
          placement={"bottom-end"}
        >
          <ul className={styles.menu}>
            <li>
              <a href={full_url} target="_blank" rel="noopener noreferrer">
                {t("edit_views.links.dropdown_items.visit_link")}
              </a>
            </li>
            <li onClick={() => handleDownloadQR(QRType.SVG)}>
              {t("edit_views.links.dropdown_items.download_svg_qr")}
            </li>
            <li onClick={() => handleDownloadQR(QRType.PNG)}>
              {t("edit_views.links.dropdown_items.download_png_qr")}
            </li>
            {isLMUser && (
              <li
                onClick={() => setIsDeleteLinkModalActive(true)}
                className={styles.deleteButton}
              >
                {t("edit_views.links.dropdown_items.delete_link")}
              </li>
            )}
          </ul>
        </PopoverComponent>
      </div>
      {isLMUser && (
        <Dialog
          dataQA="delete-feature-dialog"
          isOpen={isDeleteLinkModalActive}
          onClose={() => setIsDeleteLinkModalActive(false)}
          maxWidth={448}
        >
          <DeleteLink
            closeModal={() => setIsDeleteLinkModalActive(false)}
            onDelete={handleDeleteLink}
          />
        </Dialog>
      )}
    </>
  );
};

export default LinkOptions;
