import { SerializedError } from "@reduxjs/toolkit";
import { FetchBaseQueryError } from "@reduxjs/toolkit/dist/query";

type APIError = {
  path: string[];
  messages: string[];
};

interface ErrorData {
  code: number;
  type: string;
  message: string;
  errors: APIError[];
}

export interface FormattedErrors {
  [key: string]: string[];
}

/**
 *
 * @param error {@link FetchBaseQueryError} | {@link SerializedError} | undefined
 * @returns an object with keys representing the input field the error belongs to, with the value being an array of it's corresponding errors
 */
export const formatErrors = (
  error: FetchBaseQueryError | SerializedError | undefined
) => {
  if (!error || !("data" in error)) return null;

  const errorData = error.data as ErrorData;

  const formattedErrors = errorData.errors.reduce((result, current) => {
    const field = current.path[current.path.length - 1]; // not using .pop so we don't mutate the original array

    if (!result[field]) {
      result[field] = current.messages;
    } else {
      result[field].concat(current.messages);
    }

    return result;
  }, {} as FormattedErrors);

  return formattedErrors;
};
