import { t } from "i18next";
import { Button, Icon } from "@livingmap/core-ui-v2";

import styles from "./Modal.module.css";

interface Props {
  closeModal: () => void;
  onRevertChanges: () => void;
}

const RevertChanges: React.FC<Props> = ({ closeModal, onRevertChanges }) => {
  return (
    <div className={styles.container}>
      <div className={styles.heading}>
        <h5 className={styles.title}>{t("modals.revert_changes.title")}</h5>
        <button className={styles.closeButton} onClick={closeModal}>
          <Icon dataQA="close-icon" type="CloseIcon" width={18} height={18} />
        </button>
      </div>
      <div className={styles.contentContainer}>
        <p className={styles.description}>
          {t("modals.revert_changes.description")}
        </p>
        <div className={styles.buttonWrapper}>
          <Button dataQA="cancel-button" color="black" onClick={closeModal}>
            {t("btn_labels.cancel")}
          </Button>
          <Button
            dataQA="revert-changes-button"
            color="red"
            onClick={onRevertChanges}
          >
            {t("modals.revert_changes.btn_labels.revert_changes")}
          </Button>
        </div>
      </div>
    </div>
  );
};

export default RevertChanges;
