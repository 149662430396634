import React, { useState } from "react";
import { t } from "i18next";
import classNames from "classnames";

import Tag from "./Tag/Tag";
import styles from "./TagsBox.module.css";

export interface Props {
  dataQA: string;
  tags: string[] | null;
  language: string;
  onTagAdd: (tag: string, lang: string) => void;
  onTagDelete: (tag: string, lang: string) => void;
  limit?: number;
  disabled?: boolean;
}

const TagsBox: React.FC<Props> = ({
  dataQA,
  tags,
  language,
  onTagAdd,
  onTagDelete,
  limit = 100,
  disabled = false,
}) => {
  const [inputValue, setInputValue] = useState("");
  const [error, setError] = useState<string | null>(null);

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter") {
      // Replace any special characters (apart from hyphens and spaces), consecutive hyphens/spaces, leading/trailing hyphens/spaces & replace it with a single space
      const value = inputValue
        .replace(
          /^[-\s]+|[-\s]+$|[^a-z\d\s-]|[^a-z\d\s-]+(\s|-)|[^a-z\d]+(\s|-)|(\s|-)+[^a-z\d]+/gi,
          " ",
        )
        .trim();

      if (!value) return;

      if (tags && tags.length >= limit) {
        return setError(
          t("edit_views.features.sections.tags.errors.max", { limit }),
        );
      }

      if (tags && tags.includes(value)) {
        return setError(
          t("edit_views.features.sections.tags.errors.already_exists", {
            value,
          }),
        );
      }

      onTagAdd(value, language);
      setError(null);
      setInputValue("");
    }
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setInputValue(e.target.value);
  };

  const handleDelete = (tag: string) => {
    onTagDelete(tag, language);
    setError(null);
  };

  return (
    <div data-qa={dataQA}>
      <span className={styles.label}>{language}</span>
      <div
        className={classNames(styles.container, {
          [styles.disabled]: disabled,
        })}
      >
        {tags?.map((tag, index) => (
          <Tag
            key={tag}
            dataQA={`${tag}-tag`}
            text={tag}
            onClick={() => handleDelete(tag)}
          />
        ))}
        <input
          name="tagInput"
          type="text"
          placeholder={t("edit_views.features.sections.tags.placeholder")}
          value={inputValue}
          className={styles.input}
          onChange={handleChange}
          onKeyDown={handleKeyDown}
        />
      </div>
      {error ? (
        <span className={styles.limitError}>{error}</span>
      ) : (
        <span
          className={classNames(styles.limitText, {
            [styles.disabled]: disabled,
          })}
        >
          {t("edit_views.features.sections.tags.max", { limit })}
        </span>
      )}
    </div>
  );
};

export default TagsBox;
