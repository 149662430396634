import { t } from "i18next";
import { Button, Icon } from "@livingmap/core-ui-v2";

import styles from "@components/EditFeatureSidebar/Modals/Modal.module.css";

interface Props {
  resetModal: () => void;
  onResetSidebar: () => void;
}

const ResetSidebar: React.FC<Props> = ({ resetModal, onResetSidebar }) => {
  return (
    <div className={styles.container}>
      <div className={styles.heading}>
        <h5 className={styles.title}>
          {t("modals.exit_and_create_new_link.title")}
        </h5>
        <button className={styles.closeButton} onClick={resetModal}>
          <Icon dataQA="reset-icon" type="CloseIcon" width={18} height={18} />
        </button>
      </div>
      <div className={styles.contentContainer}>
        <p className={styles.description}>
          {t("modals.exit_and_create_new_link.description")}
        </p>
        <div className={styles.buttonWrapper}>
          <Button
            dataQA="keep-editing-button"
            color="black"
            onClick={resetModal}
          >
            {t("btn_labels.keep_editing")}
          </Button>
          <Button dataQA="exit-button" color="blue" onClick={onResetSidebar}>
            {t("btn_labels.create_new_link")}
          </Button>
        </div>
      </div>
    </div>
  );
};

export default ResetSidebar;
