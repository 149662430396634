import LivingMap, {
  LivingMapPlugin,
  LMFeature,
  StateType,
} from "@livingmap/core-mapping";
import { Feature } from "geojson";
import FloorControl from "./floor-control";
import { SourceIds } from "./types/index";
import mapboxgl from "mapbox-gl";

class ClusteredPinPlugin extends LivingMapPlugin {
  private features: LMFeature[] = [];
  private floorPlugin: FloorControl;
  private mapInstance?: mapboxgl.Map;

  public constructor(id: string, LMMap: LivingMap, floorPlugin: FloorControl) {
    super(id, LMMap);
    this.LMMap = LMMap;
    this.floorPlugin = floorPlugin;
  }

  activate(): void {
    this.mapInstance = this.LMMap.getMapboxMap() as mapboxgl.Map;
    return;
  }

  public updateClusteredPins(features: LMFeature[]): void {
    if (!this.mapInstance?.getSource(SourceIds.CLUSTER_SOURCE_ID)) {
      throw new Error(
        `Cluster source missing from style, expecting source with name: ${SourceIds.CLUSTER_SOURCE_ID}`,
      );
    }

    const currentFloor = this.floorPlugin?.getActiveFloor();
    this.features = features;

    const hasFloorData = Boolean(currentFloor);
    // The source features need to be pre-filtered as they will still cluster, even when the feature is filtered out
    const featuresOnActiveOrGroundFloor = hasFloorData
      ? this.features.filter((feature: LMFeature) => {
          const featureFloorId = feature.getFloorId();
          const featureIndoor = feature.isIndoor();
          return (
            featureFloorId === currentFloor?.id ||
            (featureFloorId === this.floorPlugin.getGroundFloor()?.id &&
              !featureIndoor)
          );
        })
      : this.features;

    const currentlySelectedFeature =
      this.LMMap.getFeatureStateDelegate().getFeatureForState(
        StateType.SELECTED,
      );

    const mapboxFeatures: Feature[] = featuresOnActiveOrGroundFloor.map(
      (lmFeature: LMFeature) => {
        const feature = Object.assign(
          { properties: {} },
          lmFeature.getMapboxFeature(),
        );

        if (
          currentlySelectedFeature !== null &&
          currentlySelectedFeature.getId() === lmFeature.getId()
        ) {
          feature.properties.selected = "active";
        } else {
          feature.properties.selected = "inactive";
        }

        const centroid = lmFeature.getCentroid();
        if (!centroid)
          throw new Error(
            `Centroid does not exist on LMFeature for: ${lmFeature.getId()}`,
          );

        feature.geometry = {
          type: "Point",
          coordinates: centroid,
        };

        feature.properties = {
          ...feature.properties,
          preventClickPropagation: true,
        };

        // Mapbox doesn't handle null values correctly, so remove any property that is null to allow
        // pins to be displayed correctly
        for (const k in feature.properties) {
          if (feature.properties[k] === null) {
            delete feature.properties[k];
          }
        }

        return feature;
      },
    );

    const source = this.mapInstance?.getSource(
      SourceIds.CLUSTER_SOURCE_ID,
    ) as mapboxgl.GeoJSONSource;

    source.setData({
      type: "FeatureCollection",
      features: mapboxFeatures,
    });
  }

  public clearClusteredPinSource(): void {
    const source = this.mapInstance?.getSource(
      SourceIds.CLUSTER_SOURCE_ID,
    ) as mapboxgl.GeoJSONSource;
    if (source) {
      this.features = [];
      source.setData({
        type: "FeatureCollection",
        features: [],
      });
      this.reloadClusteredPins();
    }
  }

  public reloadClusteredPins(): void {
    this.updateClusteredPins(this.features);
  }
}

export default ClusteredPinPlugin;
