import { useState, useEffect } from "react";
import { t } from "i18next";
import Slider from "rc-slider";
import { Icon, Tooltip } from "@livingmap/core-ui-v2";
import "rc-slider/assets/index.css";

import styles from "./NumericalSlider.module.css";
import classNames from "classnames";

interface MarkObject {
  [key: number | string]: {
    label: React.ReactNode;
  };
}

interface Props {
  dataQA: string;
  minimum: number;
  maximum: number;
  value: number;
  onChange: (value: number) => void;
  onResetClick: () => void;
  disabled?: boolean;
}

const NumericalSlider: React.FC<Props> = ({
  dataQA,
  minimum,
  maximum,
  value,
  onChange,
  onResetClick,
  disabled = false,
}) => {
  const [marks, setMarks] = useState<MarkObject | null>(null);

  useEffect(() => {
    if ((minimum || minimum === 0) && maximum) {
      // Creates an array of numbers from the given minimum/maximum range, incremented in steps of 1
      const numArray = Array.from(
        { length: maximum - minimum + 1 },
        (_, i) => minimum + i,
      );

      // Set each number within the given range as a key in an object so it can be passed down into the "marks" prop for the slider
      const data: MarkObject = numArray.reduce((final, value) => {
        final[value] = {
          label: <div className={styles.sliderMark}>{value}</div>,
        };

        return final;
      }, {} as MarkObject);

      setMarks(data);
    }
  }, [minimum, maximum]);

  return (
    <div data-qa={dataQA}>
      {marks && (
        <div
          className={classNames(styles.container, {
            [styles.disabled]: disabled,
          })}
        >
          <div className={styles.sliderWrapper}>
            <Tooltip
              dataQA="high-priority-tooltip"
              icon={{
                type: "HighPriorityIcon",
                className: styles.highPriority,
              }}
            >
              <p>{t("edit_views.features.sections.rank.more_important")}</p>
            </Tooltip>
            <Slider
              min={minimum}
              max={maximum}
              marks={marks}
              included={false}
              value={value}
              onChange={(value) => onChange(value as number)}
              className={styles.slider}
            />
            <Tooltip
              dataQA="low-priority-tooltip"
              icon={{
                type: "LowPriorityIcon",
                className: styles.lowPriority,
              }}
            >
              <p>{t("edit_views.features.sections.rank.less_important")}</p>
            </Tooltip>
          </div>
          <button className={styles.resetButton} onClick={onResetClick}>
            <Icon
              dataQA="refresh-icon"
              type="RefreshIcon"
              className={styles.refreshIcon}
            />
            <span>{t("edit_views.features.sections.rank.reset")}</span>
          </button>
        </div>
      )}
    </div>
  );
};

export default NumericalSlider;
