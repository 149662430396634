export const formatDate = (
  value: string | number | Date,
  options: Intl.DateTimeFormatOptions = {
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
    hour: "2-digit",
    minute: "2-digit",
    second: "2-digit",
  }
) => {
  const date = new Date(value);
  return date.toLocaleDateString("en-GB", options);
};
