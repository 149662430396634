import React from "react";
import { createRoot } from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";

import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";

// KeyCloak Imports
import keycloak from "./keycloak";

// Redux imports
import { store } from "./store";
import { Provider } from "react-redux";
import { ReactKeycloakProvider } from "@react-keycloak/web";

// Translations
import "./i18n";

const container = document.getElementById("root");
const root = createRoot(container!);

const eventHandler = (event: string) => {
  if (["onAuthError", "onAuthRefreshError"].includes(event)) {
    keycloak.login(); // Redirects to the Keycloak login page for a new login
  }
};

root.render(
  <ReactKeycloakProvider
    authClient={keycloak}
    initOptions={{
      checkLoginIframe: false,
      responseMode: "query",
    }}
    onEvent={eventHandler}
  >
    <React.StrictMode>
      <BrowserRouter>
        <Provider store={store}>
          <App />
        </Provider>
      </BrowserRouter>
    </React.StrictMode>
  </ReactKeycloakProvider>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
