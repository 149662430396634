import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

import keycloak from "../../keycloak";
import {
  ProjectLinksResponse,
  ProjectLinkData,
  LinkCreatedResponse,
  CreateLinkRequest,
  LinkUpdateRequest,
  LinkUpdateResponse,
} from "./types";

type MapId = string;
type ScanCode = string;

export const linksApi = createApi({
  reducerPath: "linksApi",
  tagTypes: ["Links", "Link"],
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_API_URL,
    prepareHeaders: (headers) => {
      const token = keycloak.token;

      if (token) {
        headers.set("authorization", `Bearer ${token}`);
      }

      return headers;
    },
  }),
  endpoints: (builder) => ({
    getProjectLinks: builder.query<ProjectLinksResponse, MapId | null>({
      query: (mapID) => `/links/project/${mapID}`,
      providesTags: ["Links"],
    }),
    getSingleLink: builder.query<ProjectLinkData, ScanCode | null>({
      query: (scanCode) => `links/${scanCode}`,
      providesTags: ["Link"],
    }),
    createSingleLink: builder.mutation<LinkCreatedResponse, CreateLinkRequest>({
      query: (createLinkRequest) => ({
        url: "links",
        method: "POST",
        body: createLinkRequest,
      }),
      invalidatesTags: ["Links", "Link"],
    }),
    updateSingleLink: builder.mutation<LinkUpdateResponse, LinkUpdateRequest>({
      query: (linkUpdateRequest) => ({
        url: `links/${linkUpdateRequest.scan_code}`,
        method: "PUT",
        body: linkUpdateRequest,
      }),
      invalidatesTags: ["Links", "Link"],
    }),
    deleteSingleLink: builder.mutation<null, ScanCode>({
      query: (scanCode) => ({
        url: `links/${scanCode}`,
        method: "DELETE",
      }),
      invalidatesTags: ["Links", "Link"],
    }),
  }),
});

export const {
  useGetProjectLinksQuery,
  useGetSingleLinkQuery,
  useLazyGetSingleLinkQuery,
  useCreateSingleLinkMutation,
  useUpdateSingleLinkMutation,
  useDeleteSingleLinkMutation,
} = linksApi;
