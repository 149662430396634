import React from "react";

import styles from "./StatBox.module.css";

interface Props {
  dataQA: string;
  stat: string | number;
  description: string;
}

const StatBox: React.FC<Props> = ({ dataQA, stat, description }) => {
  return (
    <div data-qa={dataQA} className={styles.container}>
      <p className={styles.description}>{description}</p>
      <h4 className={styles.stat}>{stat}</h4>
    </div>
  );
};

export default StatBox;
