import { t } from "i18next";
import { Trans } from "react-i18next";
import { Button, Icon } from "@livingmap/core-ui-v2";

import styles from "./Modal.module.css";

interface Props {
  featureName: string | undefined;
  closeModal: () => void;
  onTemporarilyClose: () => void;
}

const TemporarilyClose: React.FC<Props> = ({
  featureName,
  closeModal,
  onTemporarilyClose,
}) => {
  return (
    <div className={styles.container}>
      <div className={styles.heading}>
        <h5 className={styles.title}>{t("modals.temporarily_close.title")}</h5>
        <button className={styles.closeButton} onClick={closeModal}>
          <Icon dataQA="close-icon" type="CloseIcon" width={18} height={18} />
        </button>
      </div>
      <div className={styles.contentContainer}>
        <p className={styles.description}>
          <Trans
            i18nKey="modals.temporarily_close.description"
            values={{ featureName }}
          >
            <span />
          </Trans>
        </p>
        <div className={styles.buttonWrapper}>
          <Button dataQA="cancel-button" color="black" onClick={closeModal}>
            {t("btn_labels.cancel")}
          </Button>
          <Button
            dataQA="temporarily-close-button"
            color="red"
            onClick={onTemporarilyClose}
          >
            {t("modals.temporarily_close.btn_labels.temporarily_close")}
          </Button>
        </div>
      </div>
    </div>
  );
};

export default TemporarilyClose;
