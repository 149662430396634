import React, { useState } from "react";
import { t } from "i18next";
import classNames from "classnames";
import { Icon, Button } from "@livingmap/core-ui-v2";

import FormControl from "@components/FormControl/FormControl";
import { Days, OperatingHoursObject } from "@redux/services/types";
import styles from "./Modal.module.css";

interface Props {
  times: string[];
  operatingHours: OperatingHoursObject;
  closeModal: () => void;
  onOperatingHoursUpdate: (operatingHours: OperatingHoursObject) => void;
}

const dayArray = [
  "monday",
  "tuesday",
  "wednesday",
  "thursday",
  "friday",
  "saturday",
  "sunday",
];

const OperatingHours: React.FC<Props> = ({
  operatingHours,
  times,
  closeModal,
  onOperatingHoursUpdate,
}) => {
  const [activeDay, setActiveDay] = useState(0);
  const [draftOperatingHours, setDraftOperatingHours] =
    useState(operatingHours);

  const handlePrevious = () => {
    if (activeDay === 0) {
      setActiveDay(dayArray.length - 1);
    } else {
      setActiveDay(activeDay - 1);
    }
  };

  const handleNext = () => {
    if (activeDay === dayArray.length - 1) {
      setActiveDay(0);
    } else {
      setActiveDay(activeDay + 1);
    }
  };

  const handleOperatingHoursChange = (
    type: "opening_time" | "closing_time",
    day: Days,
    time: string,
  ) => {
    if (!draftOperatingHours) return;

    setDraftOperatingHours({
      ...draftOperatingHours,
      [day]: {
        ...draftOperatingHours[day],
        [type]: time,
      },
    });
  };

  const handleOperatingHoursCheckboxChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    day: Days,
  ) => {
    if (
      !draftOperatingHours ||
      e.target.type !== "checkbox" ||
      !("checked" in e.target)
    ) {
      return;
    }

    if (e.target.name === "openAllDay") {
      return setDraftOperatingHours({
        ...draftOperatingHours,
        [day]: {
          ...draftOperatingHours[day],
          ...(e.target.checked
            ? {
                opening_time: "00:00",
                closing_time: "24:00",
              }
            : {
                opening_time: "00:00",
                closing_time: "23:55",
              }),
        },
      });
    }

    if (e.target.name === "closedAllDay") {
      return setDraftOperatingHours({
        ...draftOperatingHours,
        [day]: e.target.checked
          ? null
          : {
              opening_time: "00:00",
              closing_time: "23:55",
            },
      });
    }
  };

  return (
    <div className={styles.container}>
      <div className={styles.heading}>
        <h5 className={styles.title}>{t("operating_hours.title")}</h5>
        <button className={styles.closeButton} onClick={closeModal}>
          <Icon dataQA="close-icon" type="CloseIcon" width={18} height={18} />
        </button>
      </div>
      <div className={styles.contentContainer}>
        {Object.keys(operatingHours).map((day) => {
          if (day !== dayArray[activeDay]) return null;

          const operatingHours = draftOperatingHours[day as Days];
          const openAllDay =
            operatingHours?.opening_time === "00:00" &&
            operatingHours?.closing_time === "24:00";
          const closedAllDay = !operatingHours;

          return (
            <React.Fragment key={day}>
              <div className={styles.daySelector}>
                <span className={styles.dayHeading}>
                  {t(`operating_hours.days.${day}`)}
                </span>
                <div className={styles.selectorWrapper}>
                  <button
                    className={styles.selectorButton}
                    onClick={handlePrevious}
                  >
                    <Icon dataQA="previous-icon" type="ChevronLeftIcon" />
                  </button>
                  <button
                    className={styles.selectorButton}
                    onClick={handleNext}
                  >
                    <Icon dataQA="next-icon" type="ChevronRightIcon" />
                  </button>
                </div>
              </div>
              <div className={styles.checkboxContainer}>
                <FormControl
                  dataQA="open-all-day-checkbox"
                  type="checkbox"
                  label={t("operating_hours.status.open_24_hours")}
                  id="openAllDay"
                  name="openAllDay"
                  value="openAllDay"
                  checked={openAllDay}
                  onChange={(e) =>
                    handleOperatingHoursCheckboxChange(e, day as Days)
                  }
                />
                <FormControl
                  dataQA="closed-all-day-checkbox"
                  type="checkbox"
                  label={t("operating_hours.status.closed")}
                  id="closedAllDay"
                  name="closedAllDay"
                  value="closedAllDay"
                  checked={closedAllDay}
                  onChange={(e) =>
                    handleOperatingHoursCheckboxChange(e, day as Days)
                  }
                />
              </div>
              <div
                className={classNames(styles.dropdownContainer, {
                  [styles.hidden]: openAllDay || closedAllDay,
                })}
              >
                <FormControl
                  dataQA="opening-time-dropdown"
                  type="dropdown"
                  label={t("operating_hours.labels.open_time")}
                  options={times}
                  value={
                    draftOperatingHours[day as Days]?.opening_time || "00:00"
                  }
                  onChange={(time) =>
                    handleOperatingHoursChange(
                      "opening_time",
                      day as Days,
                      time,
                    )
                  }
                />
                <FormControl
                  dataQA="closing-time-dropdown"
                  type="dropdown"
                  label={t("operating_hours.labels.close_time")}
                  options={times}
                  value={
                    draftOperatingHours[day as Days]?.closing_time || "23:55"
                  }
                  onChange={(time) =>
                    handleOperatingHoursChange(
                      "closing_time",
                      day as Days,
                      time,
                    )
                  }
                />
              </div>
            </React.Fragment>
          );
        })}
        <div className={styles.buttonWrapper}>
          <Button dataQA="cancel-button" color="black" onClick={closeModal}>
            {t("btn_labels.cancel")}
          </Button>
          <Button
            dataQA="done-button"
            color="blue"
            onClick={() =>
              draftOperatingHours && onOperatingHoursUpdate(draftOperatingHours)
            }
          >
            {t("btn_labels.done")}
          </Button>
        </div>
      </div>
    </div>
  );
};

export default OperatingHours;
