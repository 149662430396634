import { MapDataResponse } from "@redux/services/types";

export const getProjectNames = (data: MapDataResponse | undefined) => {
  return data?.data.map((item) => {
    const {
      languages: { default: defaultLanguage },
      name: names,
    } = item;

    const projectName = names.find((name) => name.lang === defaultLanguage);

    if (!projectName) {
      throw new Error(
        `Default language of "${defaultLanguage}" does not exist for this project`
      );
    }

    return {
      id: item.id,
      name: projectName.text,
      defaultLanguage: item.languages.default,
    };
  });
};
