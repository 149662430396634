import React from "react";

import { Icon } from "@livingmap/core-ui-v2";
import styles from "./Thumbnail.module.css";

interface Props {
  dataQA: string;
  image: string;
  onDeleteClick: (event: React.MouseEvent<HTMLButtonElement>) => void;
}

const Thumbnail: React.FC<Props> = ({ dataQA, image, onDeleteClick }) => {
  return (
    <div data-qa={dataQA} className={styles.wrapper}>
      <img src={image} alt="" className={styles.image} />
      <button className={styles.closeButton} onClick={onDeleteClick}>
        <Icon dataQA="delete-icon" type="DeleteIcon" width={18} height={18} />
      </button>
    </div>
  );
};

export default Thumbnail;
