import React from "react";

import { TextAreaInterface } from "../FormControl";
import styles from "./Textarea.module.css";

type Props = Omit<TextAreaInterface, "type">;

const Textarea: React.FC<Props> = ({
  cols = 30,
  rows = 10,
  dataQA,
  ...props
}) => {
  return (
    <div data-qa={dataQA} className={styles.container}>
      {props.label && (
        <label htmlFor={props.id} className={styles.label}>
          {props.label}
        </label>
      )}
      <textarea
        cols={cols}
        rows={rows}
        className={styles.textarea}
        {...props}
        value={props.value || ""}
      />
    </div>
  );
};

export default Textarea;
