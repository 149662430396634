export const createTimeArray = (steps: number = 5) => {
  const times = [];
  let startTime = 0;

  for (let i = 0; startTime < 24 * 60; i++) {
    const hh = Math.floor(startTime / 60); // get hours of the day in 0-24 format
    const mm = startTime % 60; // get minutes of the hour between 0-60 incremented in given steps
    times.push(`${("0" + hh).slice(-2)}:${("0" + mm).slice(-2)}`); // push data to array in 24h format
    startTime += steps;
  }

  return times;
};
