import React, { useState, useRef } from "react";
import { t } from "i18next";
import { Trans } from "react-i18next";
import { Icon, Button } from "@livingmap/core-ui-v2";

import { formatBytes } from "./util/formatBytes";
import styles from "./FileUpload.module.css";
import classNames from "classnames";

interface Props {
  onFileUpload: (files: File) => void;
  maxFileSizeInBytes?: number;
  /**
   * @param boolean
   * @desc whether to accept multiple files
   * @default false
   */
  multiple?: boolean;
  /**
   * @param array
   * @desc what file types to accept
   * @default "['image/jpeg', 'image/png']"
   */
  accept?: string[];
  dataQA: string;
  disabled?: boolean;
}

const FileUpload: React.FC<Props> = ({
  onFileUpload,
  maxFileSizeInBytes = 512000,
  multiple = false,
  accept = ["image/jpeg", "image/png"],
  dataQA,
  disabled = false,
}) => {
  const fileInput = useRef<HTMLInputElement>(null);
  const [files, setFiles] = useState<File[]>([]);
  const [error, setError] = useState<string | null>(null);

  const handleBrowseClick = () => {
    fileInput.current?.click();
  };

  const handleNewFiles = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { files } = e.target;

    if (files) {
      const filesArray = Array.from(files);
      const filesToAdd = [];

      for (const file of filesArray) {
        if (!accept.includes(file.type)) {
          return setError(
            t("edit_views.features.sections.media.errors.file_type", {
              types: accept.join(", "),
            }),
          );
        }

        if (maxFileSizeInBytes && file.size > maxFileSizeInBytes) {
          return setError(
            t("edit_views.features.sections.media.errors.file_size", {
              filename: file.name,
              size: formatBytes(maxFileSizeInBytes),
            }),
          );
        }

        filesToAdd.push(file);

        if (!multiple) {
          break;
        }
      }

      setFiles(filesToAdd);
      setError(null);
    }
  };

  return (
    <>
      <div
        className={classNames(styles.inputContainer, {
          [styles.disabled]: disabled,
        })}
        data-qa={dataQA}
      >
        {files.length > 0 ? (
          <>
            <div
              className={styles.previewContainer}
              data-qa="preview-container"
            >
              {files.map((file) => (
                <div
                  key={`${file.name}:${file.size}:${file.lastModified}`}
                  className={styles.filePreview}
                  data-qa="file-preview"
                >
                  <img
                    src={URL.createObjectURL(file)}
                    alt={`Preview for ${file.name}`}
                    data-qa="preview-image"
                  />
                  <span data-qa="file-preview-name">{file.name}</span>
                </div>
              ))}
            </div>
            <div
              className={styles.confirmationButtonWrapper}
              data-qa="confirmation-button-wrapper"
            >
              <Button
                dataQA="cancel-button"
                color="black"
                onClick={() => setFiles([])}
                outlined
              >
                {t("btn_labels.cancel")}
              </Button>
              <Button
                dataQA="upload-button"
                color="blue"
                onClick={() => onFileUpload(files[0])}
              >
                {t("btn_labels.upload")}
              </Button>
            </div>
          </>
        ) : (
          <>
            <Icon
              dataQA="cloud-upload-icon"
              type="CloudUploadIcon"
              width={24}
              height={16}
              className={styles.icon}
            />
            <p
              className={styles.description}
              data-qa="cloud-upload-description"
            >
              <Trans i18nKey="edit_views.features.sections.media.input.description">
                <br />
              </Trans>
            </p>
            <div
              className={styles.buttonWrapper}
              data-qa="cloud-upload-button-wrapper"
            >
              <Button
                dataQA="browse-button"
                color="blue"
                onClick={handleBrowseClick}
                outlined
                className={classNames({ [styles.disabledButton]: disabled })}
              >
                {t("btn_labels.browse")}
              </Button>
            </div>
            <input
              type="file"
              ref={fileInput}
              value=""
              className={styles.fileInput}
              onChange={handleNewFiles}
              multiple={multiple}
              accept={accept.join(", ")}
              data-qa="cloud-upload-file-input"
            />
          </>
        )}
      </div>
      {error && (
        <span className={styles.errorText} data-qa="error-text">
          {error}
        </span>
      )}
    </>
  );
};

export default FileUpload;
