import classNames from "classnames";

import { Icon } from "@livingmap/core-ui-v2";
import styles from "./SelectorButton.module.css";

interface Props {
  isOpen?: boolean | undefined;
  activeProject: string | undefined;
}

const SelectorButton: React.FC<Props> = ({ isOpen, activeProject }) => {
  return (
    <button data-qa="project-selector-button" className={styles.button}>
      <span className={styles.buttonText}>{activeProject}</span>
      {isOpen !== undefined && (
        <Icon
          dataQA="open-icon"
          type="ChevronDownIcon"
          className={classNames({
            [styles.open]: isOpen,
          })}
        />
      )}
    </button>
  );
};

export default SelectorButton;
