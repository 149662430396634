import React from "react";

import TextNumberInput from "./TextNumberInput/TextNumberInput";
import Dropdown from "./Dropdown/Dropdown";
import Textarea from "./Textarea/Textarea";
import Checkbox from "./Checkbox/Checkbox";
import Radio from "./Radio/Radio";
import { IconType } from "@livingmap/core-ui-v2";

interface InputInterface {
  dataQA: string;
  id: string;
  name: string;
  value: string | number | undefined | null;
  title?: string;
  label?: string;
  required?: boolean;
  disabled?: boolean;
  onChange?: (e: React.ChangeEvent<any>) => void;
}

export interface TextAndNumberInterface extends InputInterface {
  type: "text" | "email" | "password" | "url" | "number";
  readonly?: boolean;
  placeholder?: string;
  icon?: {
    type: IconType;
    width?: number;
    height?: number;
  };
  iconPosition?: "left" | "right";
  onIconClick?: () => void;
}

export interface TextAreaInterface extends InputInterface {
  type: "textarea";
  cols?: number;
  rows?: number;
}

export interface CheckAndRadioInterface extends InputInterface {
  type: "checkbox" | "radio";
  checked?: boolean;
  displayCheckboxBubble?: boolean;
}

export interface DropdownInterface {
  dataQA: string;
  type: "dropdown";
  options: any[];
  value: any;
  onChange: (value: any) => void;
  label?: string;
  /**
   * @desc if the options consist of an array of objects/arrays, define the key you'd like to have the value displayed from (can be nested like "foo.bar[3].baz")
   */
  displayPath?: string;
  disabled?: boolean;
}

type Props =
  | TextAndNumberInterface
  | TextAreaInterface
  | CheckAndRadioInterface
  | DropdownInterface;

const FormControl: React.FC<Props> = (props) => {
  switch (props.type) {
    case "dropdown":
      return <Dropdown {...props} />;
    case "checkbox":
      return <Checkbox {...props} />;
    case "radio":
      return <Radio {...props} />;
    case "textarea":
      return <Textarea {...props} />;
    default:
      return <TextNumberInput {...props} />;
  }
};

export default FormControl;
