import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

import keycloak from "../../keycloak";
import {
  FeatureDataResponse,
  MapDataResponse,
  CategoryDataResponse,
  FeatureData,
  MMSStatusResponse,
  SingleMapDataResponse,
  SearchedFeatureDataResponse,
} from "./types";

export interface Floors {
  [key: string]: {
    id: number;
    floor: string;
    name: string;
    short_name: string;
  };
}

type MapId = string;
type MapFeatureParams = { mapID: string | null; featureID: string | null };
type PostMapFeatureParams = MapFeatureParams & {
  featureData: Partial<FeatureData>;
};
type UploadMediaParams = MapFeatureParams & {
  data: FormData;
};
type FeatureSearchParams = {
  mapID: string | null;
  search: string;
  lang?: string;
};

export const mmsApi = createApi({
  reducerPath: "mmsApi",
  tagTypes: ["Map", "MapData", "Feature", "FeatureDetails", "MMSStatus"],
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_API_URL,
    prepareHeaders: (headers) => {
      const token = keycloak.token;

      if (token) {
        headers.set("authorization", `Bearer ${token}`);
      }

      return headers;
    },
  }),
  endpoints: (builder) => ({
    exampleEndpoint: builder.query({ query: (id) => `/${id}` }),
    getMaps: builder.query<MapDataResponse, void>({
      query: () => `/maps`,
      providesTags: ["Map"],
    }),
    getMapById: builder.query<SingleMapDataResponse, MapId | null>({
      query: (id) => `/maps/${id}`,
      providesTags: ["MapData"],
    }),
    publishEditsForMapById: builder.mutation({
      query: (id: MapId) => ({
        url: `/maps/${id}/publish`,
        method: "POST",
      }),
      invalidatesTags: ["MMSStatus", "Feature", "FeatureDetails"],
    }),
    getFeaturesForMap: builder.query<FeatureDataResponse, MapId>({
      query: (id) => ({
        url: `/maps/${id}/features`,
        params: {
          limit: 10000,
        },
      }),
      providesTags: ["Feature"],
    }),
    getFeatureForMapById: builder.query({
      query: ({ mapID, featureID }: MapFeatureParams) =>
        `/maps/${mapID}/features/${featureID}`,
      providesTags: ["FeatureDetails"],
    }),
    saveFeatureForMapById: builder.mutation({
      query: ({ mapID, featureID, featureData }: PostMapFeatureParams) => ({
        url: `/maps/${mapID}/features/${featureID}`,
        method: "PUT",
        body: featureData,
      }),
      invalidatesTags: ["Feature", "FeatureDetails", "MMSStatus", "MapData"],
    }),
    deleteFeatureForMapById: builder.mutation({
      query: ({ mapID, featureID }: MapFeatureParams) => ({
        url: `/maps/${mapID}/features/${featureID}`,
        method: "DELETE",
      }),
      invalidatesTags: ["Feature", "FeatureDetails", "MMSStatus", "MapData"],
    }),
    unarchiveFeatureForMapById: builder.mutation({
      query: ({ mapID, featureID }: MapFeatureParams) => ({
        url: `/maps/${mapID}/features/${featureID}/unarchive`,
        method: "PUT",
      }),
      invalidatesTags: ["Feature", "FeatureDetails", "MMSStatus", "MapData"],
    }),
    revertFeatureChangesForMapById: builder.mutation({
      query: ({ mapID, featureID }: MapFeatureParams) => ({
        url: `/maps/${mapID}/features/${featureID}/revert`,
        method: "POST",
      }),
      invalidatesTags: ["Feature", "FeatureDetails", "MMSStatus", "MapData"],
    }),
    getAvailableCategoriesForFeature: builder.query<
      CategoryDataResponse,
      MapFeatureParams
    >({
      query: ({ mapID, featureID }) =>
        `/maps/${mapID}/features/${featureID}/categories-available`,
    }),
    getAvailableIconsForFeature: builder.query({
      query: ({ mapID, featureID }: MapFeatureParams) =>
        `/maps/${mapID}/features/${featureID}/icons-available`,
    }),
    uploadFeatureMediaForMapById: builder.mutation({
      query: ({ mapID, featureID, data }: UploadMediaParams) => ({
        url: `/maps/${mapID}/features/${featureID}/media`,
        method: "POST",
        body: data,
      }),
    }),
    getMMSStatus: builder.query<MMSStatusResponse, MapId | null>({
      query: (id) => `/maps/${id}/status`,
      providesTags: ["MMSStatus"],
    }),
    getFeaturesBySearchString: builder.query<
      SearchedFeatureDataResponse,
      FeatureSearchParams
    >({
      query: ({ mapID, search, lang }) => ({
        url: `/maps/${mapID}/features/search/${search}`,
        params: { lang },
      }),
    }),
  }),
});

export const {
  useExampleEndpointQuery,
  useGetMapsQuery,
  useGetMapByIdQuery,
  usePublishEditsForMapByIdMutation,
  useGetFeaturesForMapQuery,
  useGetFeatureForMapByIdQuery,
  useGetAvailableCategoriesForFeatureQuery,
  useGetAvailableIconsForFeatureQuery,
  useSaveFeatureForMapByIdMutation,
  useDeleteFeatureForMapByIdMutation,
  useUnarchiveFeatureForMapByIdMutation,
  useRevertFeatureChangesForMapByIdMutation,
  useUploadFeatureMediaForMapByIdMutation,
  useGetMMSStatusQuery,
  useLazyGetFeaturesBySearchStringQuery,
} = mmsApi;
