import React from "react";
import { Listbox } from "@headlessui/react";
import { Icon } from "@livingmap/core-ui-v2";

import { DropdownInterface } from "../FormControl";
import { getValueByPath } from "@utils";
import styles from "./Dropdown.module.css";
import classNames from "classnames";

type Props = Omit<DropdownInterface, "type">;

const Dropdown: React.FC<Props> = ({
  dataQA,
  options,
  value,
  onChange,
  label,
  displayPath,
  disabled = false,
}) => {
  const isObject = typeof options[0] === "object";

  if (isObject && !displayPath) {
    return (
      <p>
        Dropdown component needs a "displayPath" prop if the options consist of
        an array of objects/arrays
      </p>
    );
  }

  return (
    <div
      data-qa={dataQA}
      className={classNames(styles.container, {
        [styles.disabled]: disabled,
      })}
    >
      <Listbox value={value} onChange={onChange}>
        {label && (
          <Listbox.Label className={styles.label}>{label}</Listbox.Label>
        )}
        <Listbox.Button className={styles.button}>
          {isObject && displayPath
            ? getValueByPath(value, displayPath, "")
            : value}
          <Icon dataQA="chevron-down-icon" type="ChevronDownIcon" />
        </Listbox.Button>
        <div className={styles.panel}>
          <Listbox.Options className={styles.dropdown}>
            {options.map((item) => (
              <Listbox.Option
                key={
                  isObject && displayPath
                    ? getValueByPath(item, displayPath, "")
                    : item
                }
                value={item}
                as={React.Fragment}
              >
                {({ selected }) => (
                  <li
                    className={`${styles.dropdownItem} ${
                      selected ? styles.selected : ""
                    }`}
                  >
                    {isObject && displayPath
                      ? getValueByPath(item, displayPath, "")
                      : item}
                  </li>
                )}
              </Listbox.Option>
            ))}
          </Listbox.Options>
          <div className={styles.spacer} />
        </div>
      </Listbox>
    </div>
  );
};

export default Dropdown;
