import { useState, useEffect } from "react";
import { t } from "i18next";
import { Button, Spinner, Dialog, IconButton } from "@livingmap/core-ui-v2";

import Sidebar from "@components/Sidebar/Sidebar";
import CloseSidebar from "@components/EditFeatureSidebar/Modals/CloseSidebar";
import { ProjectLinkData } from "@redux/services/types";
import styles from "./EditLinkSidebar.module.scss";
import { FormattedErrors, isLivingMapUser } from "@utils";
import FormControl from "@components/FormControl/FormControl";
import { useDeleteSingleLinkMutation } from "@redux/services/links";
import DeleteLink from "@components/EditFeatureSidebar/Modals/DeleteLink";
import CopyToClipboard from "react-copy-to-clipboard";

enum QRType {
  SVG = "svg",
  PNG = "png",
}

interface Props {
  errors: FormattedErrors | null;
  isLoading: boolean;
  linkData: Partial<ProjectLinkData> | null;
  onSidebarCloseClick: () => void;
  onEditLinkSave: (data: Partial<ProjectLinkData>) => void;
  onLinkSidebarReady?: () => void;
  onDataChanged: (hasDataChanged: boolean) => void;
  children?: React.ReactNode;
}

const EditLinkSidebar: React.FC<Props> = ({
  errors,
  isLoading,
  linkData,
  onDataChanged,
  onEditLinkSave,
  onSidebarCloseClick,
  onLinkSidebarReady,
}) => {
  const [isCloseSidebarModalActive, setCloseSidebarModalActive] =
    useState(false);
  const [deleteSingleLink] = useDeleteSingleLinkMutation();
  const isLMUser = isLivingMapUser();

  const [dataHasChanged, setDataHasChanged] = useState(false);
  const [data, setData] = useState<Partial<ProjectLinkData> | null>(linkData);
  const [isDeleteLinkModalActive, setIsDeleteLinkModalActive] = useState(false);

  useEffect(() => {
    onLinkSidebarReady && onLinkSidebarReady();
  }, [onLinkSidebarReady]);

  const jsonProps = JSON.stringify(linkData);
  const jsonData = JSON.stringify(data);

  // Ensures local state is updated if any prop values change i.e. once "save" has been clicked & we receive updated feature data from the API which gets passed in from the parent
  useEffect(() => {
    setData(JSON.parse(jsonProps));
  }, [jsonProps]); // JSON.stringify ensures deep comparison of the props object & that it only runs when a nested value changes

  // Set the local "dataHasChanged" state & call the "onDataChanged" callback, so that parent components can update the "hasUnsavedChanges" state in the redux store as required
  useEffect(() => {
    setDataHasChanged(jsonProps !== jsonData);
    onDataChanged(jsonProps !== jsonData);
  }, [jsonProps, jsonData, onDataChanged, dataHasChanged]);

  if (isLoading || !data) {
    return (
      <div className={styles.loadingContainer}>
        <Spinner dataQA="loading-spinner" type="BeatLoader" />
      </div>
    );
  }

  const closeSidebarModal = () => (
    <Dialog
      dataQA="close-sidebar-dialog"
      isOpen={isCloseSidebarModalActive}
      onClose={() => setCloseSidebarModalActive(false)}
      maxWidth={448}
    >
      <CloseSidebar
        closeModal={() => setCloseSidebarModalActive(false)}
        onCloseSidebar={onSidebarCloseClick}
      />
    </Dialog>
  );

  const deleteLinkModal = () =>
    isLMUser && (
      <Dialog
        dataQA="delete-feature-dialog"
        isOpen={isDeleteLinkModalActive}
        onClose={() => setIsDeleteLinkModalActive(false)}
        maxWidth={448}
      >
        <DeleteLink
          closeModal={() => setIsDeleteLinkModalActive(false)}
          onDelete={handleDeleteLink}
        />
      </Dialog>
    );

  const handleDownloadQR = (type: QRType) => {
    if (!data.qr_code) return;

    const a = document.createElement("a");
    a.download = `qr-code.${type}`;
    a.href = `data:image/${type};base64,${data.qr_code[`${type}_base64`]}`;
    a.click();
  };

  const changeHandler = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    setData({
      ...data,
      [e.target.name]: e.target.value,
    });
  };

  const handleDeleteLink = () => {
    if (!data.scan_code)
      throw new Error("Unable to delete single link: scan_code is undefined");
    deleteSingleLink(data.scan_code);
    onSidebarCloseClick();
  };

  return (
    <Sidebar dataQA="edit-link-sidebar" isOpen={true} position="relative">
      <div className={styles.container}>
        <div className={styles.sidebarTop}>
          <div className={styles.sidebarHeading}>
            <span className={styles.sidebarTitle}>
              {t("edit_views.links.edit_title")}
            </span>
            <div className={styles.topButtonWrapper}>
              <IconButton
                dataQA="close-icon"
                icon="CloseIcon"
                className={styles.topButtonIcon}
                onClick={() =>
                  dataHasChanged
                    ? setCloseSidebarModalActive(true)
                    : onSidebarCloseClick()
                }
              />
            </div>
          </div>
        </div>
        <div className={styles.sidebarMiddle}>
          <div className={styles.sidebarSection}>
            <div className={styles.header}>
              <span>{t("edit_views.links.link_qr_code")}</span>
            </div>
            <div className={styles.body}>
              <div className={styles.codeAndInput}>
                <img
                  src={`data:image/svg+xml;base64,${data.qr_code?.svg_base64}`}
                  alt="QR Code"
                  className={styles.image}
                />
                <div className={styles.input}>
                  <CopyToClipboard
                    text={`${data.shortlink_url}/${data.scan_code}`}
                  >
                    <FormControl
                      dataQA="shortlink-input"
                      type="text"
                      id="shortlink_url"
                      name="shortlink_url"
                      value={`${data.shortlink_url}/${data.scan_code}`}
                      onChange={() => {}}
                      icon={{
                        type: "ContentCopyIcon",
                        width: 18,
                        height: 18,
                      }}
                      iconPosition="right"
                    />
                  </CopyToClipboard>
                </div>
              </div>
              <div className={styles.qrCodeControls}>
                <Button
                  dataQA="svg-download-button"
                  color="black"
                  onClick={() => handleDownloadQR(QRType.SVG)}
                  rounded
                  icon="FileDownloadIcon"
                >
                  {t("edit_views.links.btn_labels.download_svg_qr")}
                </Button>
                <Button
                  dataQA="png-download-button"
                  color="black"
                  onClick={() => handleDownloadQR(QRType.PNG)}
                  rounded
                  icon="FileDownloadIcon"
                >
                  {t("edit_views.links.btn_labels.download_png_qr")}
                </Button>
              </div>
            </div>
          </div>
          <div className={styles.sidebarSection}>
            <div className={styles.header}>
              <span>{t("edit_views.links.link_details")}</span>
            </div>
            <div className={styles.body}>
              <div className={styles.details}>
                <div className={styles.detailField}>
                  <FormControl
                    dataQA="shortlink-details-long-url"
                    type="text"
                    id="real_url"
                    name="real_url"
                    label={t("edit_views.links.labels.long_url")}
                    value={data.real_url}
                    onChange={changeHandler}
                  />
                </div>
                <div className={styles.detailField}>
                  <FormControl
                    dataQA="shortlink-details-name"
                    type="text"
                    id="name"
                    name="name"
                    label={t("edit_views.links.labels.name")}
                    value={data.name}
                    onChange={changeHandler}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className={styles.sidebarSection}>
            <div className={styles.header}>
              <span>{t("edit_views.links.delete-link-button-header")}</span>
            </div>
            <div className={styles.body}>
              <Button
                dataQA="shortlink-delete-button"
                color="red"
                rounded
                icon="CloseIcon"
                className={styles.deleteButton}
                onClick={() => setIsDeleteLinkModalActive(true)}
              >
                {t("edit_views.links.delete-link-button")}
              </Button>
            </div>
          </div>
        </div>
        <div className={styles.sidebarBottom}>
          <div className={styles.saveCancelButtonContainer}>
            {dataHasChanged && (
              <Button
                dataQA="cancel-button"
                color="black"
                onClick={() =>
                  dataHasChanged
                    ? setCloseSidebarModalActive(true)
                    : onSidebarCloseClick()
                }
                rounded
              >
                {t("btn_labels.cancel")}
              </Button>
            )}

            <Button
              dataQA="save-button"
              color="blue"
              onClick={() => onEditLinkSave(data)}
              disabled={!dataHasChanged || !data.name || !data.real_url}
              rounded
            >
              {t("btn_labels.save")}
            </Button>
          </div>
        </div>
      </div>
      {closeSidebarModal()}
      {deleteLinkModal()}
    </Sidebar>
  );
};

export default EditLinkSidebar;
