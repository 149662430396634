import { useCallback, useEffect, useState } from "react";
import { t } from "i18next";
import { Button, Dialog, Spinner, TextButton } from "@livingmap/core-ui-v2";
import cryptoRandomString from "crypto-random-string";

import Header from "@components/Header/Header";
import PageTitle from "@components/PageTitle/PageTitle";
import ResetSidebar from "@components/EditLinkSidebar/Modals/ResetSidebar";
import EditLinkSidebar from "@components/EditLinkSidebar/EditLinkSidebar";
import { useAppDispatch, useAppSelector } from "@redux/hooks";
import { useGetMapsQuery } from "@redux/services/mms";
import {
  useGetProjectLinksQuery,
  useCreateSingleLinkMutation,
  useLazyGetSingleLinkQuery,
  useUpdateSingleLinkMutation,
  linksApi,
} from "@redux/services/links";
import {
  Project,
  LinkUpdateRequest,
  ProjectLinkData,
} from "@redux/services/types";
import {
  setDefaultLanguage,
  setHasUnsavedChanges,
  setLinkScanCode,
  setMapID,
} from "@redux/slices/applicationSlice";
import {
  getProjectNames,
  formatErrors,
  displayToast,
  FormattedErrors,
} from "@utils";
import { useSetInitialProjectData } from "@hooks";
import UnsavedChanges from "../FeaturesView/Modal/UnsavedChanges";
import styles from "./ShortLinksView.module.scss";
import ShortlinkPanel from "@components/ShortlinkPanel/ShortlinkPanel";
import FormControl from "@components/FormControl/FormControl";

const ShortLinksView = () => {
  const dispatch = useAppDispatch();

  const [createSingleLink, { error: createSingleLinkError }] =
    useCreateSingleLinkMutation();
  const [updateSingleLink, { error: updateSingleLinkError }] =
    useUpdateSingleLinkMutation();

  const [
    getSingleLink,
    {
      data: singleLink,
      isFetching: isFetchingSingleLink,
      isSuccess: isGetSingleLinkSuccess,
    },
  ] = useLazyGetSingleLinkQuery();

  const [isUnsavedChangesModalActive, setUnsavedChangesModalActive] =
    useState(false);
  const [localLinkScanCode, setLocalLinkScanCode] = useState<string | null>(
    null,
  );
  const [newLinkData, setNewLinkData] =
    useState<Partial<ProjectLinkData> | null>(null);

  const [formattedErrors, setFormattedErrors] =
    useState<FormattedErrors | null>(null);

  const { mapID, linkScanCode, hasUnsavedChanges } = useAppSelector(
    (state) => state.application,
  );

  const { data, isLoading } = useGetMapsQuery();
  const { data: projectLinks, isFetching: isFetchingLinks } =
    useGetProjectLinksQuery(mapID, {
      skip: !mapID,
    });

  useEffect(() => {
    if (!isFetchingLinks) {
      dispatch(setHasUnsavedChanges(false));
    }
  }, [isFetchingLinks, dispatch]);

  const projectNames = getProjectNames(data);
  const activeProject = projectNames?.find((project) => project.id === mapID);

  const handleProjectSelect = (project: Project) => {
    dispatch(setMapID(project.id));
    dispatch(setDefaultLanguage(project.defaultLanguage));
    dispatch(setLinkScanCode(null));
  };

  useSetInitialProjectData(projectNames);

  const handleShortlinkEditClick = useCallback(
    (link: ProjectLinkData) => {
      if (hasUnsavedChanges) {
        setUnsavedChangesModalActive(true);
        setLocalLinkScanCode(link.scan_code);
        return;
      }

      setLocalLinkScanCode(null);
      setNewLinkData(null);
      getSingleLink(link.scan_code);
      dispatch(setLinkScanCode(link.scan_code));
    },
    [dispatch, hasUnsavedChanges, getSingleLink],
  );

  const mappedDataToShortlinkPanels = projectLinks?.data.map((link) => {
    const hasBeenModified = link.created_at !== link.last_modified;
    return (
      <ShortlinkPanel
        dataQA={`shorlink-${link.scan_code}`}
        createdAt={link.created_at}
        modifiedAt={hasBeenModified ? link.last_modified : undefined}
        name={link.name}
        onEdit={() => handleShortlinkEditClick(link)}
        qrCode={link.qr_code}
        shortlinkUrl={`${link.shortlink_url}/${link.scan_code}`}
        key={link.scan_code}
      />
    );
  });

  const handleCreateNewLink = () => {
    if (!mapID) return;

    dispatch(linksApi.util.resetApiState());

    const scanCode = cryptoRandomString({
      length: 6,
      type: "alphanumeric",
    }).toLowerCase();

    const shortlinkUrl =
      process.env.REACT_APP_ENVIRONMENT === "prod"
        ? "https://livingmap.link"
        : process.env.REACT_APP_SHORTLINK_API_URL;

    const newLink: Partial<ProjectLinkData> = {
      project: mapID,
      name: "",
      real_url: "",
      shortlink_url: shortlinkUrl,
      scan_code: scanCode,
    };

    setNewLinkData(newLink);
  };

  const handleDataChanged = (dataChanged: boolean) => {
    dispatch(setHasUnsavedChanges(dataChanged));
  };

  const handleEditLinkSave = async (data: Partial<ProjectLinkData>) => {
    if (newLinkData) {
      // New shortlink path
      await createSingleLink(data as ProjectLinkData);
      setFormattedErrors(null);
      getSingleLink(newLinkData.scan_code!);
    } else {
      // Edit shortlink path
      const updateRequestBody: LinkUpdateRequest = {
        name: data.name,
        shortlink_url: data.shortlink_url,
        metadata: data.metadata,
        scan_code: data.scan_code,
        real_url: data.real_url,
      };
      updateSingleLink(updateRequestBody);
      dispatch(setHasUnsavedChanges(false));
      setFormattedErrors(null);
    }
  };

  useEffect(() => {
    if (isGetSingleLinkSuccess) {
      setNewLinkData(null);
      dispatch(setHasUnsavedChanges(false));
    }
  }, [dispatch, isGetSingleLinkSuccess]);

  useEffect(() => {
    if (!createSingleLinkError && !updateSingleLinkError) return;

    if (createSingleLinkError) {
      displayToast("error", t("views.links.errors.create"));
    } else {
      displayToast("error", t("views.links.errors.update"));
    }

    setFormattedErrors(
      formatErrors(createSingleLinkError || updateSingleLinkError),
    );
  }, [createSingleLinkError, updateSingleLinkError]);

  const handleSidebarClose = () => {
    setNewLinkData(null);
    setFormattedErrors(null);
    dispatch(setLinkScanCode(null));
    dispatch(setHasUnsavedChanges(false));
    dispatch(linksApi.util.resetApiState());
  };

  const handleConfirmUnsavedChanges = () => {
    if (!localLinkScanCode) return;

    dispatch(setLinkScanCode(localLinkScanCode));
    setUnsavedChangesModalActive(false);
    setNewLinkData(null);
    dispatch(linksApi.util.resetApiState());
    getSingleLink(localLinkScanCode);
  };

  useEffect(() => {
    if (singleLink) {
      dispatch(setLinkScanCode(singleLink.scan_code));
    }
  }, [singleLink, dispatch]);

  const [isResetSidebarModalActive, setResetSidebarModalActive] =
    useState(false);

  const resetSidebarModal = () => (
    <Dialog
      dataQA="reset-sidebar-dialog"
      isOpen={isResetSidebarModalActive}
      onClose={() => setResetSidebarModalActive(false)}
      maxWidth={448}
    >
      <ResetSidebar
        resetModal={() => setResetSidebarModalActive(false)}
        onResetSidebar={() => {
          handleCreateNewLink();
          setResetSidebarModalActive(false);
        }}
      />
    </Dialog>
  );

  return isLoading || !mapID ? (
    <div className={styles.loaderContainer} data-qa="shortlinks-view-loading">
      <Spinner dataQA="loading-spinner" type="BeatLoader" />
    </div>
  ) : (
    <div className={styles.container} data-qa="shortlinks-view">
      <Header
        dataQA="link-management-header"
        projects={projectNames}
        activeProject={activeProject}
        onProjectSelect={handleProjectSelect}
      />
      <PageTitle
        dataQA="link-management-page-title"
        heading={t("views.links.title")}
        subheading={t("views.links.description")}
        customButton={{
          text: t("views.links.create_link"),
          onClick: () =>
            hasUnsavedChanges
              ? setResetSidebarModalActive(true)
              : handleCreateNewLink(),
        }}
      />
      <div className={styles.contentContainer}>
        {projectLinks?.data.length === 0 ? (
          <div className={styles.noShortlinksContainer}>
            <span>{t("views.links.create_first_link_header")}</span>
            <Button
              dataQA="create-first-shortlink-button"
              onClick={handleCreateNewLink}
              icon="PlusIcon"
              color="black"
              rounded
            >
              {t("views.links.create_first_link_button")}
            </Button>
          </div>
        ) : (
          <div className={styles.shortlinkPanelsContainer}>
            {mappedDataToShortlinkPanels}
          </div>
        )}
        {linkScanCode ? (
          <EditLinkSidebar
            errors={formattedErrors}
            isLoading={isFetchingSingleLink}
            linkData={singleLink || null}
            onDataChanged={handleDataChanged}
            onEditLinkSave={handleEditLinkSave}
            onSidebarCloseClick={handleSidebarClose}
          />
        ) : null}
        <Dialog
          dataQA="unsaved-changes-dialog"
          isOpen={isUnsavedChangesModalActive}
          onClose={() => setUnsavedChangesModalActive(false)}
          maxWidth={448}
        >
          <UnsavedChanges
            onCancel={() => setUnsavedChangesModalActive(false)}
            onConfirm={handleConfirmUnsavedChanges}
          />
        </Dialog>
        <Dialog
          dataQA="create-shortlink-dialog"
          isOpen={newLinkData !== null}
          onClose={() => setNewLinkData(null)}
          maxWidth={520}
          className={styles.createShortlinkDialog}
        >
          <div className={styles.createShortlinkHeader}>
            <span>Create short link</span>
          </div>
          <div className={styles.createShortlinkBody}>
            <div className={styles.formField}>
              <span className={styles.fieldTitle}>Long URL</span>
              <FormControl
                dataQA="real-link-textarea"
                type="text"
                id="real_url"
                name="real_url"
                value={newLinkData?.real_url}
                onChange={(e) =>
                  setNewLinkData({ ...newLinkData, real_url: e.target.value })
                }
              />
              {formattedErrors && formattedErrors["real_url"] && (
                <ul>
                  {formattedErrors["real_url"].map((error) => (
                    <li key={error}>{error}</li>
                  ))}
                </ul>
              )}
            </div>
            <div className={styles.formField}>
              <span className={styles.fieldTitle}>Short URL</span>
              <div className={styles.fieldInlineControl}>
                <span>{newLinkData?.shortlink_url}/</span>
                <FormControl
                  dataQA="real-link-textarea"
                  type="text"
                  id="real_url"
                  name="real_url"
                  value={newLinkData?.scan_code}
                  disabled
                />
              </div>
            </div>
            <div className={styles.formField}>
              <span className={styles.fieldTitle}>Name this link</span>
              <FormControl
                dataQA="real-link-textarea"
                type="text"
                id="name"
                name="name"
                value={newLinkData?.name}
                onChange={(e) =>
                  setNewLinkData({ ...newLinkData, name: e.target.value })
                }
              />
              {formattedErrors && formattedErrors["name"] && (
                <ul>
                  {formattedErrors["name"].map((error) => (
                    <li key={error}>{error}</li>
                  ))}
                </ul>
              )}
            </div>
          </div>
          <div className={styles.createShortlinkFooter}>
            <TextButton
              dataQA="create-shortlink-cancel-button"
              onClick={handleSidebarClose}
              color="black"
            >
              Cancel
            </TextButton>
            <Button
              dataQA="create-shortlink-confirm-button"
              onClick={() => {
                handleEditLinkSave(newLinkData!);
              }}
              color="black"
              rounded
            >
              Create short link
            </Button>
          </div>
        </Dialog>
        {resetSidebarModal()}
      </div>
    </div>
  );
};

export default ShortLinksView;
