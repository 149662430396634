import { t } from "i18next";
import { Button } from "@livingmap/core-ui-v2";

import StatBox from "@components/StatBox/StatBox";
import styles from "./PublishModalContent.module.css";

interface Props {
  changes: {
    features: number;
    deletedFeatures: number;
  };
  onPublish: () => void;
  closeModal: () => void;
}

const PublishModalContent: React.FC<Props> = ({
  changes,
  onPublish,
  closeModal,
}) => {
  return (
    <div className={styles.container}>
      <p className={styles.description}>{t("modals.publish.description")}</p>
      <div className={styles.grid}>
        <StatBox
          dataQA="unpublished-features-statbox"
          stat={changes.features}
          description={t("modals.publish.stats.unpublished")}
        />
        <StatBox
          dataQA="deleted-features-statbox"
          stat={changes.deletedFeatures}
          description={t("modals.publish.stats.archived")}
        />
      </div>
      <div className={styles.buttonWrapper}>
        <Button dataQA="keep-editing-button" color="black" onClick={closeModal}>
          {t("btn_labels.keep_editing")}
        </Button>
        <Button dataQA="publish-button" color="blue" onClick={onPublish}>
          {t("btn_labels.publish")}
        </Button>
      </div>
    </div>
  );
};

export default PublishModalContent;
