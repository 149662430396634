import { useEffect, useState, useRef, useCallback } from "react";
import LivingMap from "@livingmap/core-mapping";

import { useLazyGetFeaturesBySearchStringQuery } from "@redux/services/mms";
import { useAppSelector } from "@redux/hooks";
import ClusteredPinPlugin from "@components/Map/plugins/clustered-pin-control";
import { PLUGIN_IDS } from "@components/Map/plugins/types/index";
import { createLMFeature } from "@utils";

export const useSearch = (mapInstance: LivingMap | null) => {
  const clusteredPinControlInstance = useRef<ClusteredPinPlugin | null>(null);

  const { mapID, defaultLanguage } = useAppSelector(
    (state) => state.application,
  );

  const [searchInputValue, setSearchInputValue] = useState("");
  const [searchIsActive, setSearchIsActive] = useState(false);

  const [triggerSearchQuery, { data: response, isError, isFetching }] =
    useLazyGetFeaturesBySearchStringQuery();

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchInputValue(e.target.value);
  };

  const handleClearSearch = useCallback(() => {
    clusteredPinControlInstance.current?.clearClusteredPinSource();
    setSearchInputValue("");
    setSearchIsActive(false);
  }, []);

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (!searchInputValue) return;

    if (e.key === "Enter") {
      setSearchIsActive(true);
      triggerSearchQuery({
        mapID,
        search: searchInputValue,
        lang: defaultLanguage,
      });
    }
  };

  useEffect(() => {
    if (!mapInstance || !response) return;

    if (!clusteredPinControlInstance.current) {
      clusteredPinControlInstance.current =
        mapInstance.getPluginById<ClusteredPinPlugin>(PLUGIN_IDS.CLUSTERED_PIN);
    }

    if (response.data.length > 0 && searchIsActive) {
      clusteredPinControlInstance.current.updateClusteredPins(
        response.data.map((feature) =>
          createLMFeature(feature, defaultLanguage),
        ),
      );
    } else {
      clusteredPinControlInstance.current.clearClusteredPinSource();
    }

    return () => {
      // nullify the current clusterPinControlInstance when the component unmounts, so it can be instantiated for the new map that gets rendered
      clusteredPinControlInstance.current = null;
    };
  }, [response, mapInstance, searchIsActive, defaultLanguage]);

  return {
    searchIsActive,
    handleChange,
    handleKeyDown,
    handleClearSearch,
    searchInputValue,
    searchResults: response?.data,
    isError,
    isFetching,
  };
};
