import { useEffect, useState, useRef, useCallback } from "react";
import { t } from "i18next";
import LivingMap, { LMFeature } from "@livingmap/core-mapping";
import { Dialog } from "@livingmap/core-ui-v2";
import classNames from "classnames";

import EditFeatureSidebar from "@components/EditFeatureSidebar/EditFeatureSidebar";
import Map from "@components/Map/Map";
import Search from "@components/Search/Search";
import { PLUGIN_IDS } from "@components/Map/plugins/types";
import InteractionPlugin from "@components/Map/plugins/interaction-control";
import FloorControl from "@components/Map/plugins/floor-control";
import UnsavedChanges from "../../Modal/UnsavedChanges";

import {
  useGetFeatureForMapByIdQuery,
  useGetAvailableIconsForFeatureQuery,
  useGetAvailableCategoriesForFeatureQuery,
  useSaveFeatureForMapByIdMutation,
  useDeleteFeatureForMapByIdMutation,
  useRevertFeatureChangesForMapByIdMutation,
  useGetMapByIdQuery,
  Floors,
  useUnarchiveFeatureForMapByIdMutation,
} from "@redux/services/mms";
import { useAppSelector, useAppDispatch } from "@redux/hooks";
import {
  setFeatureID,
  setHasUnsavedChanges,
} from "@redux/slices/applicationSlice";
import { displayToast } from "@utils";
import { SearchedFeatureData } from "@redux/services/types";
import { useSearch } from "@hooks";
import { createLMFeature, getFloorByID } from "@utils";

import styles from "./MapView.module.css";

const MapView = () => {
  const dispatch = useAppDispatch();

  const [mapInstance, setMapInstance] = useState<LivingMap | null>(null);
  const interactionControlInstance = useRef<InteractionPlugin | null>(null);
  const floorControlInstance = useRef<FloorControl | null>(null);
  const [isUnsavedChangesModalActive, setUnsavedChangesModalActive] =
    useState(false);
  const [localFeature, setLocalFeature] = useState<LMFeature | null>(null);

  const { mapID, featureID, defaultLanguage, hasUnsavedChanges } =
    useAppSelector((state) => state.application);

  // on initial mount, ensure no feature is selected so the EditFeatureSidebar is closed
  useEffect(() => {
    dispatch(setFeatureID(null));
  }, [dispatch]);

  // Set whether there are any unsaved changes in the redux store, so we can display warning messages throughout the app when navigating away from unsaved changes
  const handleDataChanged = useCallback(
    (dataChanged: boolean) => {
      dispatch(setHasUnsavedChanges(dataChanged));
    },
    [dispatch],
  );

  if (!mapID) throw new Error("mapID could not be found in the redux state");

  const { data: mapData } = useGetMapByIdQuery(mapID);

  // Sidebar Data Hooks
  const {
    data: featureData,
    isFetching: isFetchingSingleFeature,
    error: featureDataError,
  } = useGetFeatureForMapByIdQuery(
    {
      mapID,
      featureID,
    },
    { skip: !featureID },
  );

  const { data: categoryData } = useGetAvailableCategoriesForFeatureQuery(
    {
      mapID,
      featureID,
    },
    { skip: !featureID },
  );

  const { data: iconData } = useGetAvailableIconsForFeatureQuery(
    {
      mapID,
      featureID,
    },
    { skip: !featureID },
  );

  const [updateFeature, updateFeatureResponse] =
    useSaveFeatureForMapByIdMutation();
  const [deleteFeature, deleteFeatureResponse] =
    useDeleteFeatureForMapByIdMutation();
  const [unarchiveFeature, unarchiveFeatureResponse] =
    useUnarchiveFeatureForMapByIdMutation();
  const [revertFeatureChanges, revertFeatureChangesResponse] =
    useRevertFeatureChangesForMapByIdMutation();

  const {
    searchIsActive,
    handleChange,
    handleKeyDown,
    handleClearSearch,
    searchInputValue,
    searchResults,
    isFetching,
  } = useSearch(mapInstance);

  const handleSidebarClose = () => {
    dispatch(setFeatureID(null));
    dispatch(setHasUnsavedChanges(false));
    setTimeout(() => {
      mapInstance?.getMapboxMap().resize();
    });
  };

  const handleMapFeatureSelect = useCallback(
    (feature: LMFeature) => {
      if (hasUnsavedChanges) {
        setUnsavedChangesModalActive(true);
        setLocalFeature(feature);
        interactionControlInstance.current?.setCancelSelectFeature(true);
        dispatch(setHasUnsavedChanges(false));
        return;
      }

      interactionControlInstance.current?.setCancelSelectFeature(false);

      setLocalFeature(null);
      dispatch(setFeatureID(feature.getMapboxFeature().properties!.lm_id));
    },
    [dispatch, hasUnsavedChanges],
  );

  const handleSearchFeatureSelect = (feature: SearchedFeatureData) => {
    if (hasUnsavedChanges) {
      setUnsavedChangesModalActive(true);
      setLocalFeature(createLMFeature(feature, defaultLanguage));
      return;
    }

    setLocalFeature(null);
    dispatch(setFeatureID(feature.properties.lm_id));
    interactionControlInstance.current?.selectFeature(
      createLMFeature(feature, defaultLanguage),
    );

    const featureFloor = getFloorByID(
      mappedFloorData as Floors,
      feature.properties.floor_id,
    );

    if ("id" in featureFloor) {
      floorControlInstance.current?.setActiveFloor(featureFloor);
    }
  };

  const handleConfirmUnsavedChanges = () => {
    interactionControlInstance.current?.setCancelSelectFeature(false);

    if (localFeature) {
      dispatch(setFeatureID(localFeature.getLmId()));
      interactionControlInstance.current?.selectFeature(localFeature);
    }

    setUnsavedChangesModalActive(false);
  };

  const handleCancelUnsavedChanges = () => {
    setUnsavedChangesModalActive(false);
    dispatch(setHasUnsavedChanges(true));
  };

  // Re-subscribe the handleMapFeatureSelect function to the interactionControlInstance if the "hasUnsavedChanges" state changes, since the subscribeToFeatureSelect function creates a closure over the "hasUnsavedChanges" variable
  useEffect(() => {
    interactionControlInstance.current?.subscribeToFeatureSelect(
      handleMapFeatureSelect,
    );
  }, [hasUnsavedChanges, handleMapFeatureSelect]);

  const mappedFloorData =
    mapData &&
    Object.keys(mapData.floors.available)
      .reverse()
      .reduce((finalFloors, floorId) => {
        const floorObj = mapData.floors.available[floorId];
        const floorName = floorObj.name.find(
          (floor) => floor.lang === defaultLanguage,
        );

        return {
          ...finalFloors,
          [floorId]: {
            ...floorObj,
            name: floorName?.text,
          },
        };
      }, {});

  const isLoading =
    updateFeatureResponse.status === "pending" ||
    revertFeatureChangesResponse.status === "pending" ||
    deleteFeatureResponse.status === "pending" ||
    unarchiveFeatureResponse.status === "pending";

  // useEffect to refresh all mapbox tiles once the data has updated & been refetched
  useEffect(() => {
    if (!isLoading) {
      const source = mapInstance?.getMapboxMap().getSource("lm");

      if (source) {
        interactionControlInstance.current?.deselectFeatures();
        // @ts-ignore: types not available for the reload call
        source.reload();
      }
    }
  }, [isLoading, mapInstance]);

  useEffect(() => {
    if (
      featureDataError &&
      "status" in featureDataError &&
      featureDataError.status === 404
    ) {
      displayToast("error", t("views.map_view.errors.not_editable"), {
        position: "top-center",
      });
    }
  }, [featureDataError]);

  // clear the search bar whenever the map changes
  useEffect(() => {
    handleClearSearch();
    if (mapInstance) {
      interactionControlInstance.current =
        mapInstance.getPluginById<InteractionPlugin>(PLUGIN_IDS.INTERACTION);
      floorControlInstance.current = mapInstance.getPluginById<FloorControl>(
        PLUGIN_IDS.FLOOR,
      );
    }
  }, [mapID, mapInstance, handleClearSearch]);

  return (
    <div className={styles.container} data-qa="features-map-view">
      <div className={styles.mapContainer}>
        <div
          className={classNames({
            [styles.searchWrapper]: true,
            [styles.fullHeight]: searchIsActive,
          })}
        >
          <Search
            dataQA="search-container"
            isFetching={isFetching}
            searchIsActive={searchIsActive}
            searchValue={searchInputValue}
            onChange={handleChange}
            onKeyDown={handleKeyDown}
            onClearClick={handleClearSearch}
            results={searchResults}
            placeholder={t("views.map_view.search_placeholder")}
            onResultClick={handleSearchFeatureSelect}
          />
        </div>
        {mapData && mappedFloorData && (
          <div style={{ height: "100%", width: "100%" }}>
            <Map
              dataQA="map-component"
              mapID={mapData.id}
              accessToken={mapData.access_token}
              bearing={mapData.bearing}
              zoom={mapData.zoom.default}
              maxZoom={mapData.zoom.maximum}
              minZoom={mapData.zoom.minimum}
              center={[mapData.center.longitude, mapData.center.latitude]}
              extent={[
                mapData.extents.bottom_left.longitude,
                mapData.extents.bottom_left.latitude,
                mapData.extents.top_right.longitude,
                mapData.extents.top_right.latitude,
              ]}
              mapStyle={
                mapData.stylesheets.available.find(
                  (sheet) => sheet.id === mapData.stylesheets.default,
                )?.url!
              }
              floors={mappedFloorData}
              floor={mapData.floors.default}
              onFeatureSelect={handleMapFeatureSelect}
              onMapReady={(map) => setMapInstance(map)}
            />
          </div>
        )}
      </div>
      {featureID && !featureDataError && (
        <EditFeatureSidebar
          isLoading={isLoading}
          icons={iconData?.data}
          allCategories={categoryData?.data}
          onSidebarCloseClick={handleSidebarClose}
          onEditFeatureSave={(data) =>
            updateFeature({ mapID, featureID, featureData: data })
          }
          onFeatureDelete={() => deleteFeature({ mapID, featureID })}
          onUnarchiveFeature={() => unarchiveFeature({ mapID, featureID })}
          onRevertAllChanges={() => revertFeatureChanges({ mapID, featureID })}
          onDataChanged={handleDataChanged}
          {...(isFetchingSingleFeature ? {} : featureData)}
        />
      )}
      <Dialog
        dataQA="unsaved-changes-dialog"
        isOpen={isUnsavedChangesModalActive}
        onClose={handleCancelUnsavedChanges}
        maxWidth={448}
      >
        <UnsavedChanges
          onCancel={handleCancelUnsavedChanges}
          onConfirm={handleConfirmUnsavedChanges}
        />
      </Dialog>
    </div>
  );
};

export default MapView;
