import { useMemo } from "react";
import { t } from "i18next";
import {
  GridReadyEvent,
  RowClassParams,
  RowStyle,
  ModelUpdatedEvent,
  ColDef,
  ColGroupDef,
} from "ag-grid-community";
import { AgGridReact } from "ag-grid-react";

import LoadingOverlay from "./components/LoadingOverlay/LoadingOverlay";
import { ProjectLinkData, STATUS } from "@redux/services/types";
import "./ag-theme-livingmap.scss";

interface Asset {
  name: string | undefined;
  reference: string | undefined;
  category: string;
  subcategory: string;
  last_modified: string;
  id: string;
}

type Shortlink = Pick<
  ProjectLinkData,
  "scan_code" | "last_modified" | "shortlink_url" | "name"
>;

interface Props {
  dataQA: string;
  data: (Asset | Shortlink)[] | undefined;
  columnDefs: (ColDef<any> | ColGroupDef<any>)[];
  onGridReady: (event: GridReadyEvent) => void;
}

const Grid: React.FC<Props> = ({ dataQA, data, columnDefs, onGridReady }) => {
  const handleGridReady = (event: GridReadyEvent) => {
    event.api.sizeColumnsToFit();
    onGridReady(event);
  };

  const getRowStyles = (params: RowClassParams): RowStyle => {
    switch (params.data.status) {
      case STATUS.DRAFT:
      case STATUS.DRAFT_ARCHIVED:
      case STATUS.DRAFT_RESTORED:
        return { background: "#f57c0020" };
      case STATUS.ARCHIVED:
        return { background: "#e5393520" };
      default:
        return {};
    }
  };

  const loadingOverlayComponent = useMemo(() => {
    return LoadingOverlay;
  }, []);

  // display the "no rows" overlay if a filter that's been applied returns no rows of data in the grid
  const handleModelUpdated = (event: ModelUpdatedEvent) => {
    const rowModel = event.api.getModel();

    if (!rowModel.isRowsToRender()) {
      event.api.showNoRowsOverlay();
    }
  };

  return (
    <div
      data-qa={dataQA}
      className="ag-theme-alpine"
      style={{ flex: "1 1 0px", width: "100%" }}
    >
      <AgGridReact
        rowData={data}
        rowSelection="single"
        onGridReady={handleGridReady}
        components={{ loadingOverlayComponent }}
        suppressRowTransform
        getRowStyle={getRowStyles}
        loadingOverlayComponent="loadingOverlayComponent"
        suppressDragLeaveHidesColumns={true}
        onModelUpdated={handleModelUpdated}
        defaultColDef={{
          sortable: true,
        }}
        columnDefs={columnDefs}
        overlayNoRowsTemplate={t("no_row_data")}
        localeText={{
          contains: t("filters.contains"),
          notContains: t("filters.not_contains"),
          equals: t("filters.equals"),
          notEqual: t("filters.not_equal"),
          startsWith: t("filters.starts_with"),
          endsWith: t("filters.ends_with"),
          blank: t("filters.blank"),
          notBlank: t("filters.not_blank"),
        }}
      />
    </div>
  );
};

export default Grid;
