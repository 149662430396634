import Info from "./Info/Info";
import Media from "./Media/Media";
import Tags from "./Tags/Tags";
import History from "./History/History";

export const Tabs = {
  Info,
  Media,
  Tags,
  History,
};
