import LivingMap from "@livingmap/core-mapping";
import { useEffect, useState } from "react";
import { Floors } from "@redux/services/mms";
import { FloorConfig } from "../../plugins/floor-control";
import styles from "./FloorSelector.module.css";

const FLOOR_CHANGED = "FLOOR_CHANGED";

interface Props {
  dataQA: string;
  floors: Floors;
  defaultFloor: string;
  onFloorSelect: (floor: string) => void;
  mapInstance: LivingMap | null;
}

const FloorSelector = ({
  dataQA,
  floors,
  defaultFloor,
  mapInstance,
  onFloorSelect,
}: Props) => {
  const [activeFloor, setActiveFloor] = useState(floors[defaultFloor].id);

  useEffect(() => {
    mapInstance?.on(FLOOR_CHANGED, (newFloor: FloorConfig) => {
      setActiveFloor(newFloor.id);
    });
  }, [mapInstance]);

  const floorListItems = Object.entries(floors).map(([id, floorObj]) => {
    const isActive = floorObj.id === activeFloor;

    return (
      <li
        key={id}
        className={`${styles.listItem} ${isActive && styles.active}`}
        onClick={() => onFloorSelect(id)}
      >
        <span>{floorObj.short_name}</span>
      </li>
    );
  });

  return (
    <ul data-qa={dataQA} className={styles.container}>
      {floorListItems}
    </ul>
  );
};

export default FloorSelector;
