import { t } from "i18next";
import { Button, Icon } from "@livingmap/core-ui-v2";

import styles from "./Modal.module.css";

interface Props {
  closeModal: () => void;
  onDelete: () => void;
}

const DeleteImage: React.FC<Props> = ({ closeModal, onDelete }) => {
  return (
    <div className={styles.container}>
      <div className={styles.heading}>
        <h5 className={styles.title}>{t("modals.delete.title")}</h5>
        <button className={styles.closeButton} onClick={closeModal}>
          <Icon dataQA="close-icon" type="CloseIcon" width={18} height={18} />
        </button>
      </div>
      <div className={styles.contentContainer}>
        <p className={styles.description}>{t("modals.delete.description")}</p>
        <div className={styles.buttonWrapper}>
          <Button dataQA="cancel-button" color="black" onClick={closeModal}>
            {t("btn_labels.cancel")}
          </Button>
          <Button dataQA="delete-button" color="red" onClick={onDelete}>
            {t("btn_labels.delete")}
          </Button>
        </div>
      </div>
    </div>
  );
};

export default DeleteImage;
