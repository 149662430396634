import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import Backend from "i18next-locize-backend";
import LanguageDetector from "i18next-browser-languagedetector";

const locizeOptions = {
  projectId: process.env.REACT_APP_LOCIZE_PROJECT_ID,
  version: process.env.REACT_APP_LOCIZE_VERSION,
  referenceLng: "en-GB",
};

i18n
  .use(Backend) // Load translations through http (e.g., from a server)
  .use(LanguageDetector) // Detect language
  .use(initReactI18next) // Passes i18n down to react-i18next
  .init({
    fallbackLng: "en-GB",
    lng: "en-GB",
    interpolation: { escapeValue: false }, // React already safe from XSS
    backend: locizeOptions,
  });

export default i18n;
