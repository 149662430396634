import { useState, useEffect } from "react";
import { t } from "i18next";
import { Spinner } from "@livingmap/core-ui-v2";

import Header from "@components/Header/Header";
import Workflow from "@components/Workflow/Workflow";
import PageTitle from "@components/PageTitle/PageTitle";
import { useAppDispatch, useAppSelector } from "@redux/hooks";
import { useGetMapsQuery } from "@redux/services/mms";
import {
  useGetProjectJobsQuery,
  useRunProjectJobMutation,
} from "@redux/services/workflows";
import { JobRun, Project } from "@redux/services/types";
import { setDefaultLanguage, setMapID } from "@redux/slices/applicationSlice";
import { getProjectNames } from "@utils";
import { useSetInitialProjectData } from "@hooks";
import styles from "./WorkflowsView.module.css";

const WorkflowsView = () => {
  const dispatch = useAppDispatch();

  const mapID = useAppSelector((state) => state.application.mapID);
  const [pollingInterval, setPollingtInterval] = useState<number | undefined>(
    5000,
  );

  const { data, isLoading } = useGetMapsQuery();

  const {
    currentData: jobsData,
    isError,
    error,
  } = useGetProjectJobsQuery(mapID, {
    pollingInterval,
    skip: !mapID,
  });

  useEffect(() => {
    if (isError && error && "status" in error && error.status === 404) {
      setPollingtInterval(undefined);
    }
  }, [isError, error]);

  useEffect(() => {
    setPollingtInterval(5000);
  }, [mapID]);

  const [runProject] = useRunProjectJobMutation();

  const projectNames = getProjectNames(data);

  const activeProject = projectNames?.find((project) => project.id === mapID);

  const handleProjectSelect = (project: Project) => {
    dispatch(setMapID(project.id));
    dispatch(setDefaultLanguage(project.defaultLanguage));
  };

  useSetInitialProjectData(projectNames);

  const getFinalRunData = (
    currentRun: JobRun | null,
    previousRun: JobRun,
  ): JobRun | null => {
    return {
      job_name:
        (currentRun && currentRun.job_name) ||
        (previousRun && previousRun.job_name),
      run_id:
        (currentRun && currentRun.run_id) ||
        (previousRun && previousRun.run_id),
      creation_time:
        (currentRun && currentRun.creation_time) ||
        (previousRun && previousRun.creation_time),
      status:
        (currentRun && currentRun.status) ||
        (previousRun && previousRun.status),
      start_time:
        (currentRun && currentRun.start_time) ||
        (previousRun && previousRun.start_time),
      end_time:
        (currentRun && currentRun.end_time) ||
        (previousRun && previousRun.end_time),
    };
  };

  const handleExecuteJob = (jobName: string) => {
    if (mapID) {
      runProject({ mapID, jobName });
    }
  };

  return isLoading || !mapID ? (
    <div className={styles.loaderContainer} data-qa="workflows-view-loading">
      <Spinner dataQA="loading-spinner" type="BeatLoader" />
    </div>
  ) : (
    <div className={styles.container} data-qa="workflows-view">
      <Header
        dataQA="workflows-view-header"
        projects={projectNames}
        activeProject={activeProject}
        onProjectSelect={handleProjectSelect}
      />
      <PageTitle
        dataQA="workflows-view-page-title"
        heading={t("views.workflows.title")}
        subheading={t("views.workflows.description")}
      />
      {isError && error && "status" in error && error.status === 404 && (
        <div className={styles.noWorkflowsContainer}>
          <h2 className={styles.noWorkflowsTitle}>
            {t("views.workflows.no_workflows.title")}
          </h2>
          <p className={styles.noWorkflowsDescription}>
            {t("views.workflows.no_workflows.description")}
          </p>
        </div>
      )}
      {!jobsData && !isError ? (
        <div className={styles.loaderContainer}>
          <Spinner dataQA="jobs-data-spinner" type="BeatLoader" />
        </div>
      ) : (
        <div className={styles.workflowContainer}>
          <div className={styles.workflowWrapper}>
            {jobsData?.data.map((job) => {
              const jobData = getFinalRunData(
                job.current_run,
                job.previous_run,
              );

              const isInProgress =
                !!jobData?.status &&
                jobData!.status !== "COMPLETED" &&
                jobData!.status !== "FAILED";

              return (
                <Workflow
                  key={job.job_name}
                  dataQA={`${job.job_name}-workflow-item`}
                  description={job.ui.summary}
                  title={job.ui.title}
                  onClick={() => handleExecuteJob(job.job_name)}
                  inProgress={isInProgress}
                  executionTime={
                    jobData?.status === "PENDING" ||
                    jobData?.status === "RUNNING"
                      ? new Date().getTime() -
                        new Date(job?.current_run?.creation_time || 0).getTime()
                      : new Date(job?.previous_run?.end_time || 0).getTime() -
                        new Date(job?.previous_run?.start_time || 0).getTime()
                  }
                  lastRan={job?.previous_run?.end_time}
                  jobStatus={jobData?.status}
                  confirmation={job.ui.confirmation}
                />
              );
            })}
          </div>
        </div>
      )}
    </div>
  );
};

export default WorkflowsView;
