import { useCallback, useEffect, useState } from "react";
import { t } from "i18next";
import { CellClickedEvent, GridApi, GridReadyEvent } from "ag-grid-community";
import { Dialog } from "@livingmap/core-ui-v2";

import Grid from "@components/Grid/Grid";
import EditFeatureSidebar from "@components/EditFeatureSidebar/EditFeatureSidebar";
import { AssetOptions } from "@components/Grid/components/OptionsButtonCellRenderer";
import UnsavedChanges from "../../Modal/UnsavedChanges";

import {
  useGetFeaturesForMapQuery,
  useGetFeatureForMapByIdQuery,
  useGetAvailableIconsForFeatureQuery,
  useGetAvailableCategoriesForFeatureQuery,
  useSaveFeatureForMapByIdMutation,
  useDeleteFeatureForMapByIdMutation,
  useRevertFeatureChangesForMapByIdMutation,
  useUnarchiveFeatureForMapByIdMutation,
} from "@redux/services/mms";
import { useAppSelector, useAppDispatch } from "@redux/hooks";
import {
  setFeatureID,
  setHasUnsavedChanges,
} from "@redux/slices/applicationSlice";

import { formatDate } from "@utils";
import styles from "./ListView.module.css";

const ListView = () => {
  const { mapID, featureID, hasUnsavedChanges } = useAppSelector(
    (state) => state.application,
  );
  const dispatch = useAppDispatch();

  const [api, setApi] = useState<GridApi | null>(null);
  const [isUnsavedChangesModalActive, setUnsavedChangesModalActive] =
    useState(false);
  const [localFeatureID, setLocalFeatureID] = useState<string | null>(null);

  useEffect(() => {
    dispatch(setFeatureID(null));
  }, [dispatch]);

  if (!mapID) throw new Error("mapID could not be found in the redux state");

  const { isError, isFetching, data } = useGetFeaturesForMapQuery(mapID, {
    skip: !mapID,
  });

  useEffect(() => {
    if (isFetching) {
      api?.showLoadingOverlay();
    } else {
      api?.hideOverlay();
      dispatch(setHasUnsavedChanges(false));
    }
  }, [isFetching, api, dispatch]);

  useEffect(() => {
    if (!featureID) {
      api?.sizeColumnsToFit();
    }
  }, [featureID, api]);

  const { data: featureData, isFetching: isFetchingSingleFeature } =
    useGetFeatureForMapByIdQuery(
      {
        mapID,
        featureID,
      },
      { skip: !featureID },
    );

  const { data: categoryData } = useGetAvailableCategoriesForFeatureQuery(
    {
      mapID,
      featureID,
    },
    { skip: !featureID },
  );

  const { data: iconData } = useGetAvailableIconsForFeatureQuery(
    {
      mapID,
      featureID,
    },
    { skip: !featureID },
  );

  const [updateFeature, updateFeatureResponse] =
    useSaveFeatureForMapByIdMutation();
  const [deleteFeature, deleteFeatureResponse] =
    useDeleteFeatureForMapByIdMutation();
  const [unarchiveFeature, unarchiveFeatureResponse] =
    useUnarchiveFeatureForMapByIdMutation();
  const [revertFeatureChanges, revertFeatureChangesResponse] =
    useRevertFeatureChangesForMapByIdMutation();

  if (isError) throw new Error("unable to retrieve map feature data");

  const mappedDataToCells = data?.data.map((feature) => {
    return {
      name: feature.label.name?.[0].text,
      reference: feature.label.reference?.[0].text,
      category: feature.categories.category.name[0].text,
      subcategory: feature.categories.subcategory.name[0].text,
      last_modified: formatDate(feature.last_modified.modified_at),
      id: feature.id,
      status: feature.status,
    };
  });

  const handleCellClicked = useCallback(
    (event: CellClickedEvent) => {
      if (hasUnsavedChanges) {
        setUnsavedChangesModalActive(true);
        setLocalFeatureID(event.data.id);
        return;
      }

      setLocalFeatureID(null);
      dispatch(setFeatureID(event.data.id));
    },
    [dispatch, hasUnsavedChanges],
  );

  const handleConfirmUnsavedChanges = () => {
    dispatch(setFeatureID(localFeatureID));
    setUnsavedChangesModalActive(false);
  };

  const handleGridReady = (event: GridReadyEvent) => {
    setApi(event.api);
  };

  const handleFeatureSidebarReady = () => {
    api?.sizeColumnsToFit();
  };

  const handleSidebarClose = () => {
    dispatch(setFeatureID(null));
    dispatch(setHasUnsavedChanges(false));
    setTimeout(() => {
      api?.sizeColumnsToFit();
    });
  };

  const handleDataChanged = (dataChanged: boolean) => {
    dispatch(setHasUnsavedChanges(dataChanged));
  };

  const isLoading =
    updateFeatureResponse.status === "pending" ||
    revertFeatureChangesResponse.status === "pending" ||
    deleteFeatureResponse.status === "pending" ||
    unarchiveFeatureResponse.status === "pending";

  return (
    <div className={styles.container} data-qa="features-list-view">
      <div className={styles.gridContainer}>
        <Grid
          dataQA="feature-list-grid"
          data={mappedDataToCells}
          onGridReady={handleGridReady}
          columnDefs={[
            {
              field: "name",
              headerName: t("views.features.grid_columns.name"),
              filter: "agTextColumnFilter",
              floatingFilter: true,
              unSortIcon: true,
              onCellClicked: handleCellClicked,
            },
            {
              field: "status",
              headerName: t("views.features.grid_columns.status"),
              filter: "agTextColumnFilter",
              floatingFilter: true,
              unSortIcon: true,
              onCellClicked: handleCellClicked,
            },
            {
              field: "category",
              headerName: t("views.features.grid_columns.category"),
              filter: "agTextColumnFilter",
              floatingFilter: true,
              unSortIcon: true,
              onCellClicked: handleCellClicked,
            },
            {
              field: "subcategory",
              headerName: t("views.features.grid_columns.subcategory"),
              filter: "agTextColumnFilter",
              floatingFilter: true,
              unSortIcon: true,
              onCellClicked: handleCellClicked,
            },
            {
              field: "last_modified",
              headerName: t("views.features.grid_columns.last_edited"),
              filter: "agTextColumnFilter",
              floatingFilter: true,
              unSortIcon: true,
              onCellClicked: handleCellClicked,
              // Custom filtering function for the dates, as the formatted dates won't be sorted correctly otherwise
              comparator: (valueA, valueB) => {
                const [dayA, monthA, yearAndTimeA] = valueA.split("/");
                const [dayB, monthB, yearAndTimeB] = valueB.split("/");

                // Days and months need to be swapped around, otherwise new Date() returns the wrong date
                const dateA = new Date(
                  `${monthA}/${dayA}/${yearAndTimeA}`,
                ).toISOString();
                const dateB = new Date(
                  `${monthB}/${dayB}/${yearAndTimeB}`,
                ).toISOString();

                return dateA.localeCompare(dateB);
              },
            },
            {
              cellRenderer: AssetOptions,
              cellClass: "cell-overflow-visible ag-react-container",
              width: 75,
              editable: false,
            },
          ]}
        />
      </div>
      {featureID && (
        <EditFeatureSidebar
          isLoading={isLoading}
          icons={iconData?.data}
          allCategories={categoryData?.data}
          onSidebarCloseClick={handleSidebarClose}
          onEditFeatureSave={(data) =>
            updateFeature({ mapID, featureID, featureData: data })
          }
          onFeatureDelete={() => deleteFeature({ mapID, featureID })}
          onUnarchiveFeature={() => unarchiveFeature({ mapID, featureID })}
          onFeatureSidebarReady={handleFeatureSidebarReady}
          onRevertAllChanges={() => revertFeatureChanges({ mapID, featureID })}
          onDataChanged={handleDataChanged}
          {...(isFetchingSingleFeature ? {} : featureData)}
        />
      )}
      <Dialog
        dataQA="unsaved-changes-dialog"
        isOpen={isUnsavedChangesModalActive}
        onClose={() => setUnsavedChangesModalActive(false)}
        maxWidth={448}
      >
        <UnsavedChanges
          onCancel={() => setUnsavedChangesModalActive(false)}
          onConfirm={handleConfirmUnsavedChanges}
        />
      </Dialog>
    </div>
  );
};

export default ListView;
