import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import classNames from "classnames";
import { Icon, IconType } from "@livingmap/core-ui-v2";

import Sidebar from "@components/Sidebar/Sidebar";
import Popover from "@components/Popover/Popover";
import ProfileButton from "./ProfileButton/ProfileButton";
import ProfileMenu from "./ProfileMenu/ProfileMenu";
import styles from "./ExpandingSidebar.module.css";

export interface MenuItem {
  dataQA: string;
  icon: {
    type: IconType;
    width?: number;
    height?: number;
  };
  path: string;
  label: string;
}

interface Props {
  menuItems: MenuItem[];
  username: string;
  company?: string;
  handleSignout: () => void;
  dataQA: string;
}

const ExpandingSidebar: React.FC<Props> = ({
  menuItems,
  username,
  company,
  handleSignout,
  dataQA,
}) => {
  const [isExpanded, setIsExpanded] = useState(false);

  return (
    <Sidebar dataQA={dataQA} isOpen={true} position="relative">
      <div className={styles.container}>
        <div
          className={classNames(styles.header, {
            [styles.expanded]: isExpanded,
          })}
          data-qa="header"
        >
          <button
            className={styles.hamburger}
            onClick={() => setIsExpanded(!isExpanded)}
            data-qa="menu-button"
          >
            <Icon dataQA="menu-icon" type="MenuIcon" width={19} height={19} />
          </button>
          {isExpanded && (
            <Icon
              dataQA="lm-logo-icon"
              type="LmLogoMMSIcon"
              width={161}
              height={24}
              className={styles.headerLogo}
            />
          )}
        </div>
        <div
          className={styles.menuItemsContainer}
          data-qa="menu-items-container"
        >
          {menuItems.map((item) => (
            <NavLink
              key={`${item.icon}:${item.path}:${item.label}`}
              data-qa={item.dataQA}
              to={item.path}
              className={({ isActive }) =>
                isActive
                  ? `${styles.menuItem} ${styles.activeItem}`
                  : styles.menuItem
              }
              onClick={() => setIsExpanded(false)}
            >
              <Icon dataQA="nav-icon" {...item.icon} />
              {isExpanded && <span>{item.label}</span>}
            </NavLink>
          ))}
        </div>
        <div
          className={classNames(styles.popoverContainer, {
            [styles.expanded]: isExpanded,
          })}
          data-qa="popover-container"
        >
          <Popover
            placement="top-start"
            toggleComponent={
              <ProfileButton
                dataQA="profile-button"
                isExpanded={isExpanded}
                username={username}
                company={company}
              />
            }
          >
            <ProfileMenu dataQA="profile-menu" handleSignout={handleSignout} />
          </Popover>
        </div>
      </div>
    </Sidebar>
  );
};

export default ExpandingSidebar;
