import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface ApplicationState {
  mapID: string | null;
  featureID: string | null;
  linkScanCode: string | null;
  defaultLanguage: string;
  hasUnsavedChanges: boolean;
}

const initialState: ApplicationState = {
  mapID: null,
  featureID: null,
  linkScanCode: null,
  defaultLanguage: "en-GB",
  hasUnsavedChanges: false,
};

export const applicationSlice = createSlice({
  name: "application",
  initialState,
  reducers: {
    setMapID: (state, action: PayloadAction<string | null>) => {
      state.mapID = action.payload;
      if (action.payload) localStorage.setItem("currentMapId", action.payload);
    },
    setFeatureID: (state, action: PayloadAction<string | null>) => {
      state.featureID = action.payload;
    },
    setLinkScanCode: (state, action: PayloadAction<string | null>) => {
      state.linkScanCode = action.payload;
    },
    setDefaultLanguage: (state, action: PayloadAction<string>) => {
      state.defaultLanguage = action.payload;
    },
    setHasUnsavedChanges: (state, action: PayloadAction<boolean>) => {
      state.hasUnsavedChanges = action.payload;
    },
  },
});

export const {
  setMapID,
  setFeatureID,
  setLinkScanCode,
  setDefaultLanguage,
  setHasUnsavedChanges,
} = applicationSlice.actions;

export default applicationSlice.reducer;
