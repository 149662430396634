import LivingMap, { LMFeature } from "@livingmap/core-mapping";
import { useEffect, useRef, useState } from "react";
import { Spinner } from "@livingmap/core-ui-v2";

import FloorControl, { FloorConfig } from "./plugins/floor-control";
import { Floors } from "@redux/services/mms";

import FloorSelector from "./components/FloorSelector/FloorSelector";
import ZoomControl from "./components/ZoomControl/ZoomControl";
import ClusteredPinPlugin from "./plugins/clustered-pin-control";
import InteractionPlugin from "./plugins/interaction-control";
import { PLUGIN_IDS } from "./plugins/types/index";
import "mapbox-gl/dist/mapbox-gl.css";
import styles from "./Map.module.css";

interface Props {
  dataQA: string;
  mapID: string;
  zoom: number;
  maxZoom: number;
  minZoom: number;
  center: [number, number];
  extent: [number, number, number, number];
  bearing: number;
  mapStyle: string;
  accessToken: string;
  floor: string;
  floors: Floors;
  pins?: LMFeature[];
  onFeatureSelect?: (feature: LMFeature) => void;
  onMapReady?: (map: LivingMap) => void;
}

const Map: React.FC<Props> = ({
  dataQA,
  mapID,
  bearing,
  center,
  extent,
  maxZoom,
  minZoom,
  zoom,
  mapStyle,
  accessToken,
  floor,
  floors,
  pins,
  onFeatureSelect,
  onMapReady,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const mapContainer = useRef<HTMLDivElement | null>(null);
  const floorControlInstance = useRef<FloorControl | null>(null);
  const interactionControlInstance = useRef<InteractionPlugin | null>(null);
  const [mapInstance, setMapInstance] = useState<LivingMap | null>(null);

  const setActiveFloor = (floor: string) => {
    const floorToRender = floors[floor];
    floorControlInstance.current?.setActiveFloor(floorToRender);
  };

  useEffect(() => {
    setIsLoading(true);

    const mapConfig = {
      accessToken,
      zoom,
      maxZoom,
      minZoom,
      center,
      extent,
      bearing,
      style: mapStyle,
      hash: false,
    };

    const map = new LivingMap(mapContainer.current!, mapConfig);

    floorControlInstance.current = new FloorControl(PLUGIN_IDS.FLOOR, map);
    const clusteredPinPlugin = new ClusteredPinPlugin(
      PLUGIN_IDS.CLUSTERED_PIN,
      map,
      floorControlInstance.current
    );

    interactionControlInstance.current = new InteractionPlugin(
      PLUGIN_IDS.INTERACTION,
      map,
      clusteredPinPlugin
    );

    const floorToRender: FloorConfig = floors[floor];

    map.addPlugin(floorControlInstance.current);
    map.addPlugin(clusteredPinPlugin);
    map.addPlugin(interactionControlInstance.current);

    map.create();

    map.on("style.load", () => {
      floorControlInstance.current?.activate();
      floorControlInstance.current?.setGroundFloor(floors);
      floorControlInstance.current?.setActiveFloor(floorToRender);

      interactionControlInstance.current?.activate();

      if (onFeatureSelect) {
        interactionControlInstance.current?.subscribeToFeatureSelect(
          onFeatureSelect
        );
      }

      pins && pins.length && clusteredPinPlugin.updateClusteredPins(pins);
    });

    map.on("render", function () {
      map?.getMapboxMap().resize();
    });

    map.on("load", () => {
      setIsLoading(false);
      onMapReady && onMapReady(map);
    });

    setMapInstance(map);
    // eslint-disable-next-line
  }, [mapID]); // this is the only required dependency

  const handleMapZoomIn = () => mapInstance?.zoomOutOneLevel();
  const handleMapZoomOut = () => mapInstance?.zoomInOneLevel();

  return (
    <div data-qa={dataQA} className={styles.container}>
      {isLoading && (
        <div className={styles.loaderContainer}>
          <Spinner dataQA="map-loading-spinner" type="BeatLoader" />
        </div>
      )}
      <div ref={mapContainer} className={styles.map} />
      <div className={styles.floorSelector}>
        <FloorSelector
          dataQA="floor-selector"
          floors={floors}
          defaultFloor={floor}
          mapInstance={mapInstance}
          onFloorSelect={setActiveFloor}
        />
      </div>
      <div className={styles.zoomControl}>
        <ZoomControl
          dataQA="zoom-control"
          onZoomInClick={handleMapZoomIn}
          onZoomOutClick={handleMapZoomOut}
        />
      </div>
    </div>
  );
};

export default Map;
