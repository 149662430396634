import { Spinner } from "@livingmap/core-ui-v2";

import Sidebar from "@components/Sidebar/Sidebar";
import SearchInput from "./SearchInput/SearchInput";
import SearchResults from "./SearchResults/SearchResults";
import { SearchedFeatureData } from "@redux/services/types";
import styles from "./Search.module.css";

interface Props {
  dataQA: string;
  searchIsActive: boolean;
  searchValue: string;
  placeholder: string;
  isFetching: boolean;
  results?: SearchedFeatureData[];
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onKeyDown: (e: React.KeyboardEvent<HTMLInputElement>) => void;
  onClearClick: () => void;
  onResultClick: (result: SearchedFeatureData) => void;
}

const Search: React.FC<Props> = ({
  dataQA,
  results,
  onResultClick,
  searchIsActive,
  isFetching,
  ...props
}) => {
  return (
    <div data-qa={dataQA} className={styles.container}>
      <SearchInput
        dataQA="search-input"
        searchIsActive={searchIsActive}
        {...props}
      />
      {searchIsActive && (
        <div className={styles.sidebarWrapper}>
          <Sidebar
            dataQA="search-results-sidebar"
            isOpen={true}
            position="absolute"
            layout="left"
          >
            {isFetching ? (
              <div className={styles.loaderWrapper}>
                <Spinner dataQA="search-fetching-spinner" type="BeatLoader" />
              </div>
            ) : (
              <SearchResults
                dataQA="search-results"
                results={results}
                onResultClick={onResultClick}
              />
            )}
          </Sidebar>
        </div>
      )}
    </div>
  );
};

export default Search;
