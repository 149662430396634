import React from "react";
import { Icon } from "@livingmap/core-ui-v2";

import { CheckAndRadioInterface } from "../FormControl";
import styles from "./Checkbox.module.css";

type Props = Omit<CheckAndRadioInterface, "type">;

const Checkbox: React.FC<Props> = ({
  displayCheckboxBubble,
  dataQA,
  ...props
}) => {
  return (
    <div data-qa={dataQA} className={styles.container}>
      <input
        type="checkbox"
        className={styles.input}
        {...props}
        value={props.value || ""}
      />
      <label htmlFor={props.id} className={styles.label}>
        <span
          className={`${styles.iconWrapper} ${
            displayCheckboxBubble ? styles.bubble : ""
          }`}
        >
          <Icon
            dataQA="checkbox-outline-icon"
            type="CheckBoxOutlineIcon"
            width={15}
            height={15}
            className={styles.unchecked}
          />
          <Icon
            dataQA="checkbox-icon"
            type="CheckBoxIcon"
            width={15}
            height={15}
            className={styles.checked}
          />
        </span>
        {props.label && <span className={styles.labelText}>{props.label}</span>}
      </label>
    </div>
  );
};

export default Checkbox;
