import React from "react";

import { Icon } from "@livingmap/core-ui-v2";
import styles from "./Tag.module.css";

interface Props {
  dataQA: string;
  text: string;
  onClick: () => void;
}

const Tag: React.FC<Props> = ({ dataQA, text, onClick }) => {
  return (
    <div data-qa={dataQA} className={styles.container}>
      <span className={styles.text}>{text}</span>
      <button className={styles.button} onClick={onClick}>
        <Icon dataQA="clear-icon" type="ClearIcon" width={16} height={16} />
      </button>
    </div>
  );
};

export default Tag;
